/* 6FE4BD */
/* 1a2128 */

.nav {
  height: 80px;
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: top 0.5s;
  transition: box-shadow 0.2s;
  z-index: 10;
}

.nav.blur {
  backdrop-filter: blur(6px);
}

.nav.shadow {
  backdrop-filter: unset;
  -webkit-box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.2);
}

.active_nav {
  color: var(--primary);
}

.nav-logo {
  width: 40px;
}

.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
}

.nav-link {
  margin: 0 30px;
  text-decoration: none;
  color: #fff;
}
.nav-link a {
  color: var(--secondary);
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.nav-link a:hover {
  color: var(--primary);
}

/*========== HAMBURGER MENU BUTTON ==========*/

.hamburger-container {
  position: absolute;
  top: 1vw;
  right: 1vw;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  width: 60px;
  height: 60px;
  display: none;
}
.hamburger {
  width: 35px;
  height: 5px;
  background: var(--primary);
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgb(54, 41, 40); */
  transition: all 0.5s ease-in-out;
  position: relative;
}

.hamburger::after,
.hamburger::before {
  content: "";
  position: absolute;
  left: 0;
  width: 35px;
  height: 5px;
  background: var(--primary);
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgb(54, 41, 40); */
  transition: all 0.5s ease-in-out;
}

.hamburger::before {
  transform: translateY(-11px);
}

.hamburger::after {
  transform: translateY(11px);
}

.hamburger-container.open .hamburger {
  transform: translateX(-15px);
  background: transparent;
  box-shadow: none;
}

.hamburger-container.open .hamburger::before {
  transform: rotate(45deg) translate(5px, -5px);
}

.hamburger-container.open .hamburger::after {
  transform: rotate(-45deg) translate(5px, 5px);
}

.resume_btn {
  border: 1px solid var(--primary);
  padding: 10px;
  border-radius: 5px;
}

.resume_btn:hover {
  background-color: var(--primary-opaque);
}

.theme_nav {
  font-size: 20px;
  cursor: pointer;
  background: #ffc0c0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
  padding: 7px;
}

.theme_nav.dark:hover,
.theme_nav.light:hover {
  border: 1px solid var(--primary);
}

.theme_nav.dark {
  border: 1px solid rgb(31, 39, 56);
  background: rgb(31, 39, 56);
  color: white;
}

.theme_nav.light {
  border: 1px solid rgb(73, 73, 73);
  background: none;
  color: black;
}

/*========== Media Queries ==========*/

@media screen and (max-width: 850px) {
  .nav-menu {
    position: fixed;
    top: 60px;
    background: var(--mobile-nav);
    backdrop-filter: blur(6px);
    height: 100vh;
    width: 100%;
    display: block;
    padding: 0;
    right: -200vw;
    transition: all 0.5s;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-link {
    margin: 40px 0;
  }

  .nav-link a {
    font-size: 20px;
  }

  .hamburger-container {
    display: flex;
  }

  .nav {
    padding: 0 20px;
  }
}
