.socials_container{
    left: 40px;
}

.side_float a{
    padding: 10px 0;
    text-decoration: none;
    color: var(--secondary);
}

.side_float a:hover{
    color: var(--primary)
}

.side_float::after{
    content: "";
    display: block;
    width: 1px;
    height: 100px;
    margin: 0px auto;
    background-color: var(--secondary);
}

.side_float{
    display:flex;
    font-size: 30px;
    position: fixed;
    bottom: 0px;
    flex-direction: column;
}


.side_email_container{
    right: 40px;
}

.side_email_container a{
    writing-mode: vertical-rl;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 3px;

}
@media (max-width: 800px){
    .socials_container{
        display: flex;
        position: static;
        flex-direction: row;
        justify-content: center;
        gap: 50px;
        font-size: 24px;
    }
    .side_email_container{
        display: none;
    }

    .socials_container::after{
        content: none;
    }
}

@media (max-width: 1000px){
    .socials_container{
        left: 20px;
    }
    
    .side_email_container{
        right: 20px;
    }

}
