



.form_submit{
    margin-top: 20px;
    padding: 20px 20px;
    border-radius:5px;
    /* background-color: var(--primary); */
    background-color:unset;
    border:none;
    border: 2px solid var(--primary);
    cursor: pointer;
    color: rgb(255, 255, 255);
    color: var(--primary);
    font-weight: 600;
    transition: all 0.3 ease-in;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.form_submit:hover{
    background-color:var(--primary-opaque);
}

.form{
    padding: 0 0 200px 0;
}

.form-label-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.form-label-container h1{
    color: var(--primary);
    margin: 0;
    font-weight: 500;
}

.form-label-container p{
    color: var(--secondary);
    margin: 10px 0;
    font-size: 15px;
    max-width: 600px;
    margin-bottom: 50px;
}

.form_container{
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 3rem;
}


.contact_information{
    display: flex;
    color: white;
    flex-direction: row;
    text-align: left;
    font-weight: 300;
    margin-bottom: 20px;
}

.contact_information i{
    font-size: 28px;
    margin-right: 20px;
    width: 28px;
    text-align: center;
    align-items: center;
    color: var(--primary);
}

.contact_title{
    margin: 0;
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--secondary);
}

.contact_subtitle{
    font-size: 1rem;
    color:var(--secondary);
}

.form_content{
    display: grid;
    gap: 1.5rem;
}



.form_input{
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
    padding: 0.75rem 1rem 0.25rem;
    background-color: var(--input-color);
    border-radius: 5px;
}

.form_input label{
    color: var(--default);
    font-size: 13px;
}

.form_input input,
.form_input textarea{
    font-size: 16px;
    padding: .25rem .5rem .5rem 0;
    color: var(--secondary);
    background-color: var(--input-color);
    outline: none;
    border: none;
    font-family: 'montserrat';
}

.form_input input:focus ~ label,
.form_input textarea:focus ~ label{
    color: var(--primary);
}

.message_form{
    display: grid;
    gap: 1.5rem;
}


@media (min-width: 850px){
    .form_content.double{
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width: 600px){
    .form_container{
        display: block;
    }

    .form{
        width: 100%;
    }
}