.stars_container {
  position: fixed;
  height: 100vh;
  opacity: 0.45;
}

.stars {
  z-index: 10;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 187px 494px var(--stars-color), 1844px 599px var(--stars-color), 421px 1855px var(--stars-color),
    2280px 2043px var(--stars-color), 1582px 1244px var(--stars-color), 1479px 1868px var(--stars-color),
    1354px 418px var(--stars-color), 2502px 871px var(--stars-color), 1995px 972px var(--stars-color), 475px 1156px var(--stars-color),
    737px 2220px var(--stars-color), 1516px 261px var(--stars-color), 341px 237px var(--stars-color), 2179px 1899px var(--stars-color),
    2032px 2350px var(--stars-color), 995px 1120px var(--stars-color), 1765px 2123px var(--stars-color),
    2237px 2552px var(--stars-color), 1359px 1752px var(--stars-color), 1027px 300px var(--stars-color), 858px 1907px var(--stars-color),
    199px 1151px var(--stars-color), 656px 360px var(--stars-color), 2532px 1522px var(--stars-color), 734px 1815px var(--stars-color),
    1692px 2374px var(--stars-color), 985px 12px var(--stars-color), 1188px 2172px var(--stars-color), 1699px 402px var(--stars-color),
    2237px 1139px var(--stars-color), 1144px 861px var(--stars-color), 1070px 1698px var(--stars-color), 699px 1504px var(--stars-color),
    1989px 269px var(--stars-color), 337px 1967px var(--stars-color), 2245px 15px var(--stars-color), 755px 2330px var(--stars-color),
    1040px 1511px var(--stars-color), 591px 1186px var(--stars-color), 2406px 123px var(--stars-color), 616px 94px var(--stars-color),
    2037px 734px var(--stars-color), 1436px 1022px var(--stars-color), 1075px 570px var(--stars-color), 976px 2089px var(--stars-color),
    1761px 1429px var(--stars-color), 107px 735px var(--stars-color), 2051px 3px var(--stars-color), 2024px 1310px var(--stars-color),
    669px 2226px var(--stars-color), 1166px 166px var(--stars-color), 946px 1855px var(--stars-color), 1350px 2443px var(--stars-color),
    761px 1435px var(--stars-color), 1537px 930px var(--stars-color), 1347px 1079px var(--stars-color), 1070px 1844px var(--stars-color),
    1242px 609px var(--stars-color), 1428px 1689px var(--stars-color), 1853px 1072px var(--stars-color), 291px 985px var(--stars-color),
    1390px 352px var(--stars-color), 2353px 1185px var(--stars-color), 103px 1973px var(--stars-color), 1517px 1742px var(--stars-color),
    2270px 2070px var(--stars-color), 1324px 230px var(--stars-color), 1269px 286px var(--stars-color), 2494px 701px var(--stars-color),
    2497px 1766px var(--stars-color), 955px 683px var(--stars-color), 1906px 2324px var(--stars-color), 1664px 589px var(--stars-color),
    1329px 1222px var(--stars-color), 2559px 94px var(--stars-color), 2458px 1673px var(--stars-color), 1940px 2177px var(--stars-color),
    1171px 1028px var(--stars-color), 462px 687px var(--stars-color), 1939px 1992px var(--stars-color), 815px 1723px var(--stars-color),
    2329px 1139px var(--stars-color), 1248px 888px var(--stars-color), 1608px 2087px var(--stars-color), 1253px 922px var(--stars-color),
    1867px 1104px var(--stars-color), 140px 2071px var(--stars-color), 607px 1102px var(--stars-color), 917px 693px var(--stars-color),
    169px 1239px var(--stars-color), 2484px 814px var(--stars-color), 1444px 2428px var(--stars-color), 2513px 856px var(--stars-color),
    1915px 445px var(--stars-color), 2557px 2055px var(--stars-color), 2395px 979px var(--stars-color), 496px 805px var(--stars-color),
    358px 852px var(--stars-color), 1987px 1240px var(--stars-color), 2379px 1192px var(--stars-color), 561px 2451px var(--stars-color),
    290px 1540px var(--stars-color), 354px 668px var(--stars-color), 214px 1241px var(--stars-color), 371px 16px var(--stars-color),
    333px 777px var(--stars-color), 1976px 489px var(--stars-color), 1351px 229px var(--stars-color), 1186px 2492px var(--stars-color),
    1171px 3px var(--stars-color), 2227px 1142px var(--stars-color), 1531px 875px var(--stars-color), 2547px 249px var(--stars-color),
    873px 1386px var(--stars-color), 6px 268px var(--stars-color), 1091px 2101px var(--stars-color), 1928px 1529px var(--stars-color),
    1471px 43px var(--stars-color), 1937px 2522px var(--stars-color), 290px 2386px var(--stars-color), 2304px 468px var(--stars-color),
    1995px 2245px var(--stars-color), 450px 1608px var(--stars-color), 564px 459px var(--stars-color), 849px 1061px var(--stars-color),
    563px 2006px var(--stars-color), 1111px 2492px var(--stars-color), 1876px 1852px var(--stars-color), 822px 871px var(--stars-color),
    779px 1208px var(--stars-color), 1934px 1713px var(--stars-color), 2224px 66px var(--stars-color), 33px 731px var(--stars-color),
    1904px 1129px var(--stars-color), 1228px 64px var(--stars-color), 457px 2145px var(--stars-color), 610px 2408px var(--stars-color),
    25px 1603px var(--stars-color), 1217px 1562px var(--stars-color), 482px 2368px var(--stars-color), 839px 1968px var(--stars-color),
    1347px 1440px var(--stars-color), 835px 1467px var(--stars-color), 1069px 1402px var(--stars-color),
    1703px 2436px var(--stars-color), 1887px 618px var(--stars-color), 2483px 1133px var(--stars-color), 536px 27px var(--stars-color),
    686px 2210px var(--stars-color), 87px 1357px var(--stars-color), 471px 328px var(--stars-color), 1872px 1249px var(--stars-color),
    799px 2451px var(--stars-color), 599px 765px var(--stars-color), 2114px 159px var(--stars-color), 2511px 709px var(--stars-color),
    871px 1760px var(--stars-color), 1620px 1222px var(--stars-color), 633px 383px var(--stars-color), 316px 1218px var(--stars-color),
    1152px 1336px var(--stars-color), 1652px 508px var(--stars-color), 1586px 1075px var(--stars-color), 1117px 128px var(--stars-color),
    327px 393px var(--stars-color), 457px 1170px var(--stars-color), 642px 770px var(--stars-color), 303px 1582px var(--stars-color),
    2340px 2415px var(--stars-color), 1793px 1301px var(--stars-color), 1173px 199px var(--stars-color), 1971px 317px var(--stars-color),
    941px 2016px var(--stars-color), 552px 2288px var(--stars-color), 140px 2198px var(--stars-color), 2247px 2230px var(--stars-color),
    579px 1132px var(--stars-color), 27px 1596px var(--stars-color), 1476px 141px var(--stars-color), 573px 2251px var(--stars-color),
    418px 436px var(--stars-color), 828px 1075px var(--stars-color), 470px 1965px var(--stars-color), 1004px 868px var(--stars-color),
    1823px 2222px var(--stars-color), 271px 1839px var(--stars-color), 84px 595px var(--stars-color), 1855px 2070px var(--stars-color),
    2433px 2516px var(--stars-color), 1319px 1603px var(--stars-color), 2044px 2529px var(--stars-color), 1378px 24px var(--stars-color),
    817px 192px var(--stars-color), 845px 325px var(--stars-color), 1048px 1318px var(--stars-color), 1292px 1750px var(--stars-color),
    1197px 1812px var(--stars-color), 1492px 206px var(--stars-color), 1374px 2072px var(--stars-color),
    2419px 2038px var(--stars-color), 2103px 2500px var(--stars-color), 2075px 2232px var(--stars-color),
    2525px 1930px var(--stars-color), 1554px 620px var(--stars-color), 208px 493px var(--stars-color), 454px 447px var(--stars-color),
    1279px 1580px var(--stars-color), 1000px 491px var(--stars-color), 1258px 469px var(--stars-color), 1487px 615px var(--stars-color),
    1052px 861px var(--stars-color), 320px 2051px var(--stars-color), 2371px 1501px var(--stars-color), 2409px 1838px var(--stars-color),
    864px 505px var(--stars-color), 1819px 2453px var(--stars-color), 1704px 2174px var(--stars-color), 261px 667px var(--stars-color),
    1442px 595px var(--stars-color), 1794px 1623px var(--stars-color), 1173px 1376px var(--stars-color),
    1192px 1707px var(--stars-color), 1605px 1456px var(--stars-color), 531px 825px var(--stars-color), 478px 396px var(--stars-color),
    843px 1999px var(--stars-color), 170px 2548px var(--stars-color), 1280px 629px var(--stars-color), 2547px 2034px var(--stars-color),
    968px 2323px var(--stars-color), 1513px 926px var(--stars-color), 29px 1537px var(--stars-color), 767px 1722px var(--stars-color),
    1511px 1862px var(--stars-color), 165px 2070px var(--stars-color), 262px 915px var(--stars-color), 1825px 764px var(--stars-color),
    1686px 130px var(--stars-color), 2502px 398px var(--stars-color), 1037px 141px var(--stars-color), 1640px 977px var(--stars-color),
    1100px 318px var(--stars-color), 489px 1837px var(--stars-color), 815px 981px var(--stars-color), 2117px 1741px var(--stars-color),
    1364px 2221px var(--stars-color), 294px 1982px var(--stars-color), 111px 1983px var(--stars-color), 419px 1440px var(--stars-color),
    754px 1088px var(--stars-color), 1734px 1291px var(--stars-color), 947px 550px var(--stars-color), 2530px 993px var(--stars-color),
    1317px 1934px var(--stars-color), 1744px 2290px var(--stars-color), 2415px 2430px var(--stars-color), 861px 661px var(--stars-color),
    2051px 2426px var(--stars-color), 2521px 1957px var(--stars-color), 1325px 325px var(--stars-color),
    1367px 2553px var(--stars-color), 491px 2216px var(--stars-color), 1437px 1474px var(--stars-color),
    2271px 2430px var(--stars-color), 548px 1641px var(--stars-color), 2362px 477px var(--stars-color), 2221px 2314px var(--stars-color),
    577px 576px var(--stars-color), 1911px 1482px var(--stars-color), 503px 1547px var(--stars-color), 1139px 251px var(--stars-color),
    649px 1462px var(--stars-color), 2547px 32px var(--stars-color), 1612px 567px var(--stars-color), 2422px 1057px var(--stars-color),
    434px 705px var(--stars-color), 2200px 1230px var(--stars-color), 2458px 2435px var(--stars-color), 1916px 435px var(--stars-color),
    2233px 128px var(--stars-color), 1873px 1981px var(--stars-color), 487px 1840px var(--stars-color), 1680px 511px var(--stars-color),
    605px 2340px var(--stars-color), 1105px 2144px var(--stars-color), 1852px 1048px var(--stars-color), 1148px 986px var(--stars-color),
    935px 1768px var(--stars-color), 359px 807px var(--stars-color), 2213px 1831px var(--stars-color), 1683px 967px var(--stars-color),
    2240px 643px var(--stars-color), 1818px 980px var(--stars-color), 570px 1746px var(--stars-color), 1856px 953px var(--stars-color),
    703px 1310px var(--stars-color), 787px 506px var(--stars-color), 1461px 939px var(--stars-color), 1006px 1461px var(--stars-color),
    1303px 1093px var(--stars-color), 2172px 1830px var(--stars-color), 1541px 2038px var(--stars-color), 1163px 41px var(--stars-color),
    1318px 2086px var(--stars-color), 365px 768px var(--stars-color), 1082px 469px var(--stars-color), 1198px 457px var(--stars-color),
    1945px 2402px var(--stars-color), 537px 1790px var(--stars-color), 2476px 2510px var(--stars-color), 811px 1470px var(--stars-color),
    1441px 2200px var(--stars-color), 310px 1908px var(--stars-color), 1361px 377px var(--stars-color), 1335px 768px var(--stars-color),
    58px 319px var(--stars-color), 871px 647px var(--stars-color), 777px 20px var(--stars-color), 379px 1685px var(--stars-color),
    791px 117px var(--stars-color), 1704px 2479px var(--stars-color), 998px 1416px var(--stars-color), 2486px 963px var(--stars-color),
    1315px 1889px var(--stars-color), 812px 1356px var(--stars-color), 1872px 1571px var(--stars-color),
    1284px 2094px var(--stars-color), 2512px 1968px var(--stars-color), 2183px 2500px var(--stars-color),
    2148px 449px var(--stars-color), 2467px 512px var(--stars-color), 1030px 2428px var(--stars-color), 2194px 860px var(--stars-color),
    602px 2043px var(--stars-color), 555px 2479px var(--stars-color), 1873px 1122px var(--stars-color), 757px 1622px var(--stars-color),
    1359px 203px var(--stars-color), 1628px 2017px var(--stars-color), 1944px 729px var(--stars-color), 1808px 786px var(--stars-color),
    1333px 2222px var(--stars-color), 1175px 1650px var(--stars-color), 2468px 505px var(--stars-color), 2335px 672px var(--stars-color),
    77px 2371px var(--stars-color), 214px 163px var(--stars-color), 789px 1791px var(--stars-color), 930px 1736px var(--stars-color),
    2065px 1332px var(--stars-color), 456px 918px var(--stars-color), 803px 2464px var(--stars-color), 959px 1310px var(--stars-color),
    1841px 773px var(--stars-color), 1504px 219px var(--stars-color), 1376px 2058px var(--stars-color), 2560px 1156px var(--stars-color),
    1224px 1853px var(--stars-color), 2313px 1263px var(--stars-color), 2210px 29px var(--stars-color), 104px 867px var(--stars-color),
    704px 322px var(--stars-color), 1412px 1806px var(--stars-color), 2421px 2433px var(--stars-color), 1268px 690px var(--stars-color),
    874px 48px var(--stars-color), 1118px 1741px var(--stars-color), 168px 2216px var(--stars-color), 1268px 1479px var(--stars-color),
    1613px 1203px var(--stars-color), 2502px 1279px var(--stars-color), 54px 947px var(--stars-color), 2469px 236px var(--stars-color),
    220px 1573px var(--stars-color), 2297px 646px var(--stars-color), 2414px 2309px var(--stars-color), 1127px 172px var(--stars-color),
    577px 1806px var(--stars-color), 2073px 791px var(--stars-color), 1939px 817px var(--stars-color), 1837px 2302px var(--stars-color),
    1610px 400px var(--stars-color), 1796px 815px var(--stars-color), 195px 515px var(--stars-color), 1172px 1096px var(--stars-color),
    2020px 937px var(--stars-color), 2335px 726px var(--stars-color), 127px 427px var(--stars-color), 1656px 529px var(--stars-color),
    1510px 1116px var(--stars-color), 1843px 1822px var(--stars-color), 319px 1708px var(--stars-color),
    2150px 1522px var(--stars-color), 39px 2145px var(--stars-color), 1936px 893px var(--stars-color), 903px 2049px var(--stars-color),
    973px 1024px var(--stars-color), 665px 253px var(--stars-color), 151px 1734px var(--stars-color), 226px 1045px var(--stars-color),
    2172px 1614px var(--stars-color), 1836px 1983px var(--stars-color), 560px 1037px var(--stars-color), 163px 21px var(--stars-color),
    612px 1781px var(--stars-color), 1569px 1470px var(--stars-color), 1645px 262px var(--stars-color), 2146px 519px var(--stars-color),
    579px 822px var(--stars-color), 2260px 1780px var(--stars-color), 350px 1620px var(--stars-color), 2176px 1328px var(--stars-color),
    886px 1510px var(--stars-color), 606px 1368px var(--stars-color), 2405px 1548px var(--stars-color), 1230px 1744px var(--stars-color),
    942px 116px var(--stars-color), 181px 652px var(--stars-color), 1241px 1567px var(--stars-color), 2510px 594px var(--stars-color),
    1941px 1114px var(--stars-color), 1525px 764px var(--stars-color), 1596px 22px var(--stars-color), 192px 2024px var(--stars-color),
    430px 292px var(--stars-color), 1528px 1910px var(--stars-color), 2431px 1630px var(--stars-color), 2127px 522px var(--stars-color),
    979px 495px var(--stars-color), 581px 746px var(--stars-color), 1212px 2084px var(--stars-color), 1441px 1945px var(--stars-color),
    2529px 737px var(--stars-color), 1987px 2551px var(--stars-color), 1789px 1307px var(--stars-color),
    1598px 1959px var(--stars-color), 1482px 1401px var(--stars-color), 1545px 792px var(--stars-color),
    1083px 1260px var(--stars-color), 1625px 1415px var(--stars-color), 2179px 1042px var(--stars-color),
    1108px 655px var(--stars-color), 2046px 1692px var(--stars-color), 706px 1699px var(--stars-color), 369px 1591px var(--stars-color),
    2011px 742px var(--stars-color), 1466px 662px var(--stars-color), 1519px 874px var(--stars-color), 1907px 1808px var(--stars-color),
    499px 457px var(--stars-color), 876px 2150px var(--stars-color), 513px 2167px var(--stars-color), 2136px 1673px var(--stars-color),
    847px 2239px var(--stars-color), 1917px 383px var(--stars-color), 1605px 825px var(--stars-color), 2485px 2423px var(--stars-color),
    1140px 1360px var(--stars-color), 918px 776px var(--stars-color), 246px 1558px var(--stars-color), 112px 806px var(--stars-color),
    248px 2041px var(--stars-color), 1340px 1438px var(--stars-color), 1467px 1930px var(--stars-color), 1883px 176px var(--stars-color),
    2496px 2350px var(--stars-color), 2415px 182px var(--stars-color), 1877px 2300px var(--stars-color), 1927px 904px var(--stars-color),
    717px 2384px var(--stars-color), 1686px 327px var(--stars-color), 926px 1637px var(--stars-color), 1556px 1077px var(--stars-color),
    1523px 273px var(--stars-color), 1507px 1059px var(--stars-color), 466px 2213px var(--stars-color), 2450px 1461px var(--stars-color),
    1334px 166px var(--stars-color), 34px 1216px var(--stars-color), 69px 2531px var(--stars-color), 1242px 1977px var(--stars-color),
    1897px 944px var(--stars-color), 1130px 417px var(--stars-color), 115px 2371px var(--stars-color), 16px 1475px var(--stars-color),
    232px 1705px var(--stars-color), 1091px 1245px var(--stars-color), 2347px 44px var(--stars-color), 2339px 322px var(--stars-color),
    69px 1303px var(--stars-color), 888px 122px var(--stars-color), 563px 61px var(--stars-color), 610px 1395px var(--stars-color),
    1739px 1016px var(--stars-color), 1883px 831px var(--stars-color), 1029px 2144px var(--stars-color), 1241px 701px var(--stars-color),
    1641px 4px var(--stars-color), 1093px 1140px var(--stars-color), 1415px 645px var(--stars-color), 1834px 1706px var(--stars-color),
    839px 765px var(--stars-color), 953px 1084px var(--stars-color), 48px 467px var(--stars-color), 1133px 1527px var(--stars-color),
    1721px 2326px var(--stars-color), 1020px 244px var(--stars-color), 2211px 1376px var(--stars-color),
    1548px 1096px var(--stars-color), 202px 1098px var(--stars-color), 950px 1356px var(--stars-color), 2486px 1998px var(--stars-color),
    1717px 789px var(--stars-color), 1739px 1764px var(--stars-color), 538px 640px var(--stars-color), 2274px 547px var(--stars-color),
    1924px 2097px var(--stars-color), 654px 1321px var(--stars-color), 2523px 322px var(--stars-color), 2337px 861px var(--stars-color),
    1289px 2149px var(--stars-color), 219px 1777px var(--stars-color), 2545px 1719px var(--stars-color), 241px 1668px var(--stars-color),
    1791px 1854px var(--stars-color), 2318px 2521px var(--stars-color), 1207px 1980px var(--stars-color), 238px 919px var(--stars-color),
    1401px 777px var(--stars-color), 1719px 518px var(--stars-color), 644px 2144px var(--stars-color), 2014px 1870px var(--stars-color),
    954px 1886px var(--stars-color), 2406px 1721px var(--stars-color), 135px 611px var(--stars-color), 466px 2086px var(--stars-color),
    1857px 1414px var(--stars-color), 147px 1392px var(--stars-color), 817px 2468px var(--stars-color), 284px 2161px var(--stars-color),
    2172px 442px var(--stars-color), 829px 1281px var(--stars-color), 1910px 271px var(--stars-color), 2178px 1349px var(--stars-color),
    1486px 1710px var(--stars-color), 78px 1563px var(--stars-color), 2183px 1489px var(--stars-color), 1536px 470px var(--stars-color),
    57px 2428px var(--stars-color), 2449px 534px var(--stars-color), 2521px 612px var(--stars-color), 1805px 526px var(--stars-color),
    2102px 2321px var(--stars-color), 623px 2380px var(--stars-color), 823px 1575px var(--stars-color), 104px 288px var(--stars-color),
    2347px 2457px var(--stars-color), 99px 761px var(--stars-color), 2369px 2543px var(--stars-color), 1533px 435px var(--stars-color),
    2393px 957px var(--stars-color), 1602px 462px var(--stars-color), 1526px 1px var(--stars-color), 1681px 111px var(--stars-color),
    2260px 1684px var(--stars-color), 1705px 2442px var(--stars-color), 1816px 136px var(--stars-color), 2311px 453px var(--stars-color),
    1364px 1322px var(--stars-color), 1731px 1628px var(--stars-color), 1659px 2188px var(--stars-color),
    2078px 1669px var(--stars-color), 2102px 2263px var(--stars-color), 29px 626px var(--stars-color), 699px 2300px var(--stars-color),
    2308px 2168px var(--stars-color), 672px 748px var(--stars-color), 2069px 195px var(--stars-color), 146px 767px var(--stars-color),
    272px 1646px var(--stars-color), 1109px 1610px var(--stars-color), 649px 999px var(--stars-color), 2282px 591px var(--stars-color),
    1996px 1806px var(--stars-color), 219px 1699px var(--stars-color), 2158px 1659px var(--stars-color),
    2414px 1430px var(--stars-color), 257px 214px var(--stars-color), 381px 1598px var(--stars-color), 824px 112px var(--stars-color),
    2447px 243px var(--stars-color), 758px 776px var(--stars-color), 2385px 910px var(--stars-color), 1830px 2558px var(--stars-color),
    1425px 1565px var(--stars-color), 636px 688px var(--stars-color), 1993px 52px var(--stars-color), 1369px 1855px var(--stars-color),
    1102px 2460px var(--stars-color), 1001px 2423px var(--stars-color), 1524px 114px var(--stars-color),
    1225px 2354px var(--stars-color), 2107px 1408px var(--stars-color), 2548px 70px var(--stars-color), 884px 1993px var(--stars-color),
    125px 1545px var(--stars-color), 81px 2313px var(--stars-color), 1663px 2366px var(--stars-color), 1937px 1636px var(--stars-color),
    2077px 1747px var(--stars-color), 798px 14px var(--stars-color), 2351px 594px var(--stars-color), 1465px 2488px var(--stars-color),
    1084px 182px var(--stars-color), 138px 2198px var(--stars-color), 2075px 1390px var(--stars-color), 2267px 917px var(--stars-color),
    2138px 2170px var(--stars-color), 1266px 1152px var(--stars-color), 1290px 2295px var(--stars-color),
    1377px 281px var(--stars-color), 1928px 2263px var(--stars-color), 2516px 101px var(--stars-color), 2492px 1010px var(--stars-color),
    295px 1419px var(--stars-color), 1903px 332px var(--stars-color), 115px 2367px var(--stars-color), 1079px 2485px var(--stars-color),
    2433px 1682px var(--stars-color), 839px 1855px var(--stars-color), 944px 673px var(--stars-color), 272px 256px var(--stars-color),
    2512px 29px var(--stars-color), 1322px 1598px var(--stars-color), 157px 584px var(--stars-color), 1968px 2283px var(--stars-color),
    430px 1312px var(--stars-color), 2274px 1208px var(--stars-color), 1962px 1766px var(--stars-color),
    2445px 2448px var(--stars-color), 208px 44px var(--stars-color), 208px 1058px var(--stars-color), 1005px 1396px var(--stars-color),
    1992px 1622px var(--stars-color), 213px 1758px var(--stars-color), 563px 144px var(--stars-color), 1798px 1987px var(--stars-color),
    1074px 1947px var(--stars-color), 184px 1641px var(--stars-color), 1786px 992px var(--stars-color), 1054px 735px var(--stars-color),
    784px 2240px var(--stars-color), 1063px 2542px var(--stars-color), 705px 2411px var(--stars-color), 2118px 428px var(--stars-color),
    455px 2178px var(--stars-color), 318px 2111px var(--stars-color), 1259px 579px var(--stars-color), 2073px 1783px var(--stars-color),
    728px 721px var(--stars-color), 639px 601px var(--stars-color), 1433px 602px var(--stars-color), 1386px 2294px var(--stars-color),
    218px 631px var(--stars-color), 212px 1550px var(--stars-color), 2158px 253px var(--stars-color), 2363px 833px var(--stars-color),
    47px 911px var(--stars-color), 1462px 2421px var(--stars-color), 1010px 824px var(--stars-color), 717px 206px var(--stars-color),
    2521px 1153px var(--stars-color), 1537px 2191px var(--stars-color), 610px 1547px var(--stars-color), 2299px 690px var(--stars-color),
    2089px 1664px var(--stars-color), 615px 276px var(--stars-color), 329px 1194px var(--stars-color), 1075px 1789px var(--stars-color),
    1117px 1849px var(--stars-color), 1413px 450px var(--stars-color), 2441px 1449px var(--stars-color),
    1275px 1395px var(--stars-color), 1256px 1288px var(--stars-color), 44px 1432px var(--stars-color), 1536px 722px var(--stars-color),
    983px 2078px var(--stars-color), 994px 201px var(--stars-color), 1086px 2321px var(--stars-color), 277px 634px var(--stars-color),
    355px 193px var(--stars-color), 670px 2109px var(--stars-color), 1992px 399px var(--stars-color), 1763px 858px var(--stars-color),
    818px 1696px var(--stars-color), 154px 817px var(--stars-color), 2292px 1742px var(--stars-color), 308px 733px var(--stars-color),
    346px 514px var(--stars-color), 309px 695px var(--stars-color), 415px 2089px var(--stars-color), 862px 1360px var(--stars-color),
    1931px 2269px var(--stars-color), 563px 841px var(--stars-color), 1245px 2087px var(--stars-color), 2548px 2077px var(--stars-color),
    356px 448px var(--stars-color), 1269px 2228px var(--stars-color), 998px 494px var(--stars-color), 715px 1996px var(--stars-color),
    806px 867px var(--stars-color), 946px 525px var(--stars-color), 2078px 101px var(--stars-color), 2258px 1144px var(--stars-color),
    1110px 717px var(--stars-color), 2322px 313px var(--stars-color), 192px 92px var(--stars-color), 1251px 852px var(--stars-color),
    2116px 1386px var(--stars-color), 2479px 2201px var(--stars-color), 103px 1674px var(--stars-color), 764px 192px var(--stars-color),
    1406px 1079px var(--stars-color), 94px 46px var(--stars-color), 1254px 877px var(--stars-color), 1229px 314px var(--stars-color),
    929px 1472px var(--stars-color), 1753px 1887px var(--stars-color), 80px 1940px var(--stars-color), 649px 1552px var(--stars-color),
    506px 1259px var(--stars-color), 2543px 226px var(--stars-color), 1663px 444px var(--stars-color), 2174px 562px var(--stars-color),
    2344px 2159px var(--stars-color), 272px 1256px var(--stars-color), 667px 543px var(--stars-color), 2180px 642px var(--stars-color),
    1041px 1240px var(--stars-color), 1343px 1495px var(--stars-color), 376px 1803px var(--stars-color),
    1237px 1976px var(--stars-color), 2046px 1517px var(--stars-color), 1699px 2269px var(--stars-color),
    2338px 2049px var(--stars-color), 826px 1467px var(--stars-color), 1527px 2449px var(--stars-color), 740px 632px var(--stars-color),
    2302px 710px var(--stars-color), 2025px 2289px var(--stars-color), 2360px 1502px var(--stars-color), 1523px 552px var(--stars-color),
    2538px 336px var(--stars-color), 1106px 885px var(--stars-color), 2294px 1392px var(--stars-color), 1678px 1414px var(--stars-color),
    380px 1471px var(--stars-color), 2530px 710px var(--stars-color), 1668px 1491px var(--stars-color), 2235px 238px var(--stars-color),
    1814px 2227px var(--stars-color), 1935px 383px var(--stars-color), 794px 487px var(--stars-color), 1384px 2247px var(--stars-color),
    194px 949px var(--stars-color), 1084px 1485px var(--stars-color), 1923px 2176px var(--stars-color), 2349px 1009px var(--stars-color),
    573px 291px var(--stars-color), 1321px 1464px var(--stars-color), 2295px 903px var(--stars-color), 51px 2404px var(--stars-color),
    2050px 772px var(--stars-color), 539px 1789px var(--stars-color), 1555px 1487px var(--stars-color), 977px 110px var(--stars-color),
    2195px 506px var(--stars-color), 251px 1918px var(--stars-color), 1694px 1656px var(--stars-color), 2px 1774px var(--stars-color),
    2130px 129px var(--stars-color), 2559px 1106px var(--stars-color), 320px 499px var(--stars-color), 583px 252px var(--stars-color),
    2004px 1694px var(--stars-color), 738px 44px var(--stars-color), 675px 1144px var(--stars-color), 2051px 58px var(--stars-color),
    2187px 1302px var(--stars-color), 252px 1066px var(--stars-color), 1398px 2264px var(--stars-color),
    1195px 2307px var(--stars-color), 1477px 1058px var(--stars-color), 229px 1618px var(--stars-color),
    2222px 1607px var(--stars-color), 224px 2482px var(--stars-color), 1472px 1985px var(--stars-color), 2455px 399px var(--stars-color),
    965px 2019px var(--stars-color), 883px 529px var(--stars-color), 1830px 1226px var(--stars-color), 1055px 134px var(--stars-color),
    291px 36px var(--stars-color), 2051px 992px var(--stars-color), 1205px 1476px var(--stars-color), 1412px 2383px var(--stars-color),
    1073px 137px var(--stars-color), 1634px 17px var(--stars-color), 2234px 2535px var(--stars-color), 927px 1683px var(--stars-color),
    520px 2035px var(--stars-color), 183px 1174px var(--stars-color), 2526px 2539px var(--stars-color), 1810px 578px var(--stars-color),
    307px 784px var(--stars-color), 1574px 2239px var(--stars-color), 259px 451px var(--stars-color), 1270px 1921px var(--stars-color),
    1459px 1549px var(--stars-color), 683px 2457px var(--stars-color), 204px 393px var(--stars-color), 1444px 598px var(--stars-color),
    112px 1056px var(--stars-color), 514px 374px var(--stars-color), 2473px 594px var(--stars-color), 2366px 619px var(--stars-color),
    2287px 87px var(--stars-color), 605px 442px var(--stars-color), 583px 361px var(--stars-color), 1016px 892px var(--stars-color),
    1551px 44px var(--stars-color), 1782px 377px var(--stars-color), 309px 1413px var(--stars-color), 100px 55px var(--stars-color),
    900px 1305px var(--stars-color), 2473px 2096px var(--stars-color), 2395px 1904px var(--stars-color), 774px 2221px var(--stars-color),
    1533px 1725px var(--stars-color), 1864px 2194px var(--stars-color), 1275px 5px var(--stars-color), 2540px 1144px var(--stars-color),
    1078px 1073px var(--stars-color), 2494px 2219px var(--stars-color), 1424px 1931px var(--stars-color),
    1278px 1244px var(--stars-color), 2115px 527px var(--stars-color), 841px 1066px var(--stars-color), 721px 664px var(--stars-color),
    551px 1707px var(--stars-color), 1119px 2449px var(--stars-color), 121px 1325px var(--stars-color), 1115px 2275px var(--stars-color),
    148px 2400px var(--stars-color), 1865px 11px var(--stars-color), 1095px 124px var(--stars-color), 989px 775px var(--stars-color),
    1862px 1227px var(--stars-color), 519px 2406px var(--stars-color), 1447px 658px var(--stars-color), 1940px 1319px var(--stars-color),
    1124px 1391px var(--stars-color), 808px 2343px var(--stars-color), 1313px 565px var(--stars-color), 121px 1934px var(--stars-color),
    2524px 350px var(--stars-color), 613px 394px var(--stars-color), 1733px 1183px var(--stars-color), 1883px 1096px var(--stars-color),
    2019px 871px var(--stars-color), 1328px 1711px var(--stars-color), 799px 745px var(--stars-color), 191px 2086px var(--stars-color),
    1927px 114px var(--stars-color), 1387px 2317px var(--stars-color), 1708px 1657px var(--stars-color), 123px 199px var(--stars-color),
    828px 1466px var(--stars-color), 444px 2356px var(--stars-color), 1477px 2356px var(--stars-color), 590px 1043px var(--stars-color),
    1625px 890px var(--stars-color), 1966px 1070px var(--stars-color), 593px 705px var(--stars-color), 2032px 1112px var(--stars-color),
    72px 964px var(--stars-color), 1655px 2276px var(--stars-color), 994px 2215px var(--stars-color), 2455px 546px var(--stars-color),
    304px 288px var(--stars-color), 2544px 2409px var(--stars-color), 1916px 1084px var(--stars-color), 2344px 301px var(--stars-color),
    2425px 642px var(--stars-color), 1853px 1805px var(--stars-color), 2169px 488px var(--stars-color), 419px 2051px var(--stars-color),
    1460px 2001px var(--stars-color), 2267px 1586px var(--stars-color), 2302px 1339px var(--stars-color),
    1935px 1663px var(--stars-color), 1740px 533px var(--stars-color), 4px 48px var(--stars-color), 2278px 1015px var(--stars-color),
    169px 2173px var(--stars-color), 348px 2064px var(--stars-color), 105px 774px var(--stars-color), 633px 2034px var(--stars-color),
    1124px 459px var(--stars-color), 601px 1222px var(--stars-color), 349px 543px var(--stars-color), 1915px 368px var(--stars-color),
    1435px 311px var(--stars-color), 2425px 1895px var(--stars-color), 371px 1828px var(--stars-color), 1969px 1572px var(--stars-color),
    1030px 1476px var(--stars-color), 1981px 813px var(--stars-color), 1609px 895px var(--stars-color), 1134px 1590px var(--stars-color),
    2296px 1960px var(--stars-color), 1801px 2404px var(--stars-color), 646px 658px var(--stars-color), 556px 1997px var(--stars-color),
    538px 1886px var(--stars-color), 1579px 1653px var(--stars-color), 336px 1973px var(--stars-color), 806px 2063px var(--stars-color),
    486px 574px var(--stars-color), 72px 1337px var(--stars-color), 2163px 2314px var(--stars-color), 1283px 1931px var(--stars-color),
    636px 793px var(--stars-color), 2403px 1491px var(--stars-color), 2060px 1027px var(--stars-color), 2118px 427px var(--stars-color),
    509px 1058px var(--stars-color), 2047px 424px var(--stars-color), 2030px 1953px var(--stars-color), 2532px 985px var(--stars-color),
    935px 1161px var(--stars-color), 1951px 1491px var(--stars-color), 1397px 2010px var(--stars-color),
    1931px 2107px var(--stars-color), 416px 191px var(--stars-color), 1090px 1995px var(--stars-color), 1502px 582px var(--stars-color),
    2433px 2301px var(--stars-color), 798px 1317px var(--stars-color), 2114px 822px var(--stars-color), 1274px 885px var(--stars-color),
    1825px 1018px var(--stars-color), 1723px 1771px var(--stars-color), 2527px 604px var(--stars-color), 471px 5px var(--stars-color),
    2364px 1147px var(--stars-color), 1638px 854px var(--stars-color), 1662px 360px var(--stars-color), 2120px 2519px var(--stars-color),
    2383px 1513px var(--stars-color), 1979px 387px var(--stars-color), 539px 1002px var(--stars-color), 847px 775px var(--stars-color),
    2008px 2204px var(--stars-color), 1494px 1010px var(--stars-color), 1607px 1915px var(--stars-color),
    1504px 789px var(--stars-color), 1339px 798px var(--stars-color), 2029px 2287px var(--stars-color), 658px 1847px var(--stars-color),
    1954px 2307px var(--stars-color), 1869px 780px var(--stars-color), 1242px 586px var(--stars-color), 238px 509px var(--stars-color),
    1154px 448px var(--stars-color), 450px 482px var(--stars-color), 1338px 1442px var(--stars-color), 107px 1372px var(--stars-color),
    13px 84px var(--stars-color), 399px 1357px var(--stars-color), 1631px 1372px var(--stars-color), 2260px 666px var(--stars-color),
    2554px 1049px var(--stars-color), 1647px 714px var(--stars-color), 1064px 1529px var(--stars-color), 695px 600px var(--stars-color),
    519px 2069px var(--stars-color), 1281px 665px var(--stars-color), 847px 219px var(--stars-color), 1351px 1133px var(--stars-color),
    1807px 2277px var(--stars-color), 1858px 776px var(--stars-color), 1258px 771px var(--stars-color), 454px 984px var(--stars-color),
    2532px 2243px var(--stars-color), 376px 1331px var(--stars-color), 1845px 186px var(--stars-color), 1315px 1429px var(--stars-color),
    2345px 63px var(--stars-color), 971px 1516px var(--stars-color), 1052px 252px var(--stars-color), 870px 1631px var(--stars-color),
    1613px 2440px var(--stars-color), 608px 78px var(--stars-color), 483px 1781px var(--stars-color), 2320px 640px var(--stars-color),
    1585px 1753px var(--stars-color), 929px 1971px var(--stars-color), 200px 1727px var(--stars-color), 624px 2005px var(--stars-color),
    1647px 640px var(--stars-color), 422px 1362px var(--stars-color), 1922px 1057px var(--stars-color), 1200px 1930px var(--stars-color),
    882px 2030px var(--stars-color), 2471px 449px var(--stars-color), 2020px 267px var(--stars-color), 1808px 2209px var(--stars-color),
    943px 469px var(--stars-color), 2061px 2319px var(--stars-color), 2207px 2279px var(--stars-color), 1666px 276px var(--stars-color),
    1276px 301px var(--stars-color), 2147px 1405px var(--stars-color), 546px 1816px var(--stars-color), 456px 739px var(--stars-color),
    1397px 863px var(--stars-color), 731px 1513px var(--stars-color), 2555px 304px var(--stars-color), 1689px 918px var(--stars-color),
    616px 1098px var(--stars-color), 492px 1105px var(--stars-color), 1630px 1000px var(--stars-color), 852px 1027px var(--stars-color),
    89px 59px var(--stars-color), 998px 1465px var(--stars-color), 378px 978px var(--stars-color), 1885px 335px var(--stars-color),
    2112px 1026px var(--stars-color), 1650px 225px var(--stars-color), 1078px 253px var(--stars-color), 1091px 1782px var(--stars-color),
    2451px 1616px var(--stars-color), 1093px 1648px var(--stars-color), 1242px 961px var(--stars-color),
    1891px 1289px var(--stars-color), 625px 1100px var(--stars-color), 1283px 441px var(--stars-color), 137px 584px var(--stars-color),
    1174px 1941px var(--stars-color), 1350px 35px var(--stars-color), 2301px 1770px var(--stars-color), 409px 1666px var(--stars-color),
    267px 1660px var(--stars-color), 2261px 2488px var(--stars-color), 294px 700px var(--stars-color), 2494px 1557px var(--stars-color),
    1815px 2345px var(--stars-color), 2218px 1544px var(--stars-color), 2436px 2173px var(--stars-color),
    1586px 629px var(--stars-color), 2242px 2017px var(--stars-color), 2340px 2329px var(--stars-color),
    2095px 2281px var(--stars-color), 590px 2381px var(--stars-color), 525px 1531px var(--stars-color), 1923px 229px var(--stars-color),
    1821px 2092px var(--stars-color), 2011px 1093px var(--stars-color), 2104px 218px var(--stars-color),
    1562px 2485px var(--stars-color), 233px 337px var(--stars-color), 2485px 2073px var(--stars-color), 1925px 1222px var(--stars-color),
    366px 508px var(--stars-color), 788px 1709px var(--stars-color), 804px 1104px var(--stars-color), 1453px 2381px var(--stars-color),
    875px 172px var(--stars-color), 1212px 1101px var(--stars-color), 166px 1036px var(--stars-color), 369px 808px var(--stars-color),
    1111px 1253px var(--stars-color), 830px 2097px var(--stars-color), 1357px 967px var(--stars-color), 377px 1809px var(--stars-color),
    1473px 2144px var(--stars-color), 2018px 506px var(--stars-color), 920px 1780px var(--stars-color), 1320px 1503px var(--stars-color),
    1551px 1183px var(--stars-color), 1458px 967px var(--stars-color), 189px 1170px var(--stars-color), 721px 454px var(--stars-color),
    2029px 1297px var(--stars-color), 1579px 748px var(--stars-color), 943px 998px var(--stars-color), 2084px 745px var(--stars-color),
    1370px 1257px var(--stars-color), 2061px 1246px var(--stars-color), 2233px 333px var(--stars-color), 129px 195px var(--stars-color),
    465px 314px var(--stars-color), 2361px 1612px var(--stars-color), 1223px 384px var(--stars-color), 1299px 791px var(--stars-color),
    79px 677px var(--stars-color), 748px 1179px var(--stars-color), 1375px 2341px var(--stars-color), 1403px 493px var(--stars-color),
    671px 2201px var(--stars-color), 2050px 432px var(--stars-color), 779px 1437px var(--stars-color), 321px 2533px var(--stars-color),
    536px 337px var(--stars-color), 981px 944px var(--stars-color), 772px 1045px var(--stars-color), 324px 2108px var(--stars-color),
    295px 876px var(--stars-color), 441px 764px var(--stars-color), 1486px 2524px var(--stars-color), 351px 1819px var(--stars-color),
    2514px 1803px var(--stars-color), 1980px 2384px var(--stars-color), 413px 1437px var(--stars-color), 817px 1190px var(--stars-color),
    296px 477px var(--stars-color), 1389px 1848px var(--stars-color), 64px 1112px var(--stars-color), 1219px 1158px var(--stars-color),
    807px 1396px var(--stars-color), 1079px 1397px var(--stars-color), 2245px 618px var(--stars-color), 1760px 2427px var(--stars-color),
    756px 1589px var(--stars-color), 266px 1711px var(--stars-color), 1046px 1640px var(--stars-color), 521px 91px var(--stars-color),
    2418px 2389px var(--stars-color), 796px 1132px var(--stars-color), 585px 589px var(--stars-color), 2556px 1281px var(--stars-color),
    2484px 1409px var(--stars-color), 2188px 1230px var(--stars-color), 57px 2316px var(--stars-color), 2300px 1028px var(--stars-color),
    52px 128px var(--stars-color), 262px 2043px var(--stars-color), 1195px 1226px var(--stars-color), 290px 1312px var(--stars-color),
    2330px 927px var(--stars-color), 816px 1219px var(--stars-color), 1352px 1714px var(--stars-color), 336px 2406px var(--stars-color),
    86px 694px var(--stars-color), 1525px 283px var(--stars-color), 616px 2411px var(--stars-color), 681px 822px var(--stars-color),
    914px 2426px var(--stars-color), 968px 942px var(--stars-color), 1459px 577px var(--stars-color), 2248px 21px var(--stars-color),
    2097px 459px var(--stars-color), 924px 54px var(--stars-color), 1118px 1230px var(--stars-color), 1573px 336px var(--stars-color),
    1615px 1543px var(--stars-color), 1330px 580px var(--stars-color), 1817px 2234px var(--stars-color), 1119px 259px var(--stars-color),
    382px 498px var(--stars-color), 2095px 954px var(--stars-color), 682px 681px var(--stars-color), 1797px 138px var(--stars-color),
    1734px 38px var(--stars-color), 501px 157px var(--stars-color), 2036px 1181px var(--stars-color), 2181px 2504px var(--stars-color),
    1495px 724px var(--stars-color), 1776px 2057px var(--stars-color), 1387px 1248px var(--stars-color),
    1876px 1454px var(--stars-color), 1262px 1576px var(--stars-color), 1017px 1413px var(--stars-color),
    2248px 1467px var(--stars-color), 360px 2546px var(--stars-color), 1736px 2547px var(--stars-color), 247px 966px var(--stars-color),
    1784px 549px var(--stars-color), 1163px 1853px var(--stars-color), 1252px 2055px var(--stars-color), 987px 902px var(--stars-color),
    1187px 924px var(--stars-color), 2008px 1217px var(--stars-color), 689px 2310px var(--stars-color), 2303px 1361px var(--stars-color),
    1079px 2249px var(--stars-color), 1224px 2119px var(--stars-color), 1731px 301px var(--stars-color), 379px 2105px var(--stars-color),
    940px 298px var(--stars-color), 460px 149px var(--stars-color), 1141px 362px var(--stars-color), 2093px 1696px var(--stars-color),
    1831px 828px var(--stars-color), 1069px 2317px var(--stars-color), 2521px 1872px var(--stars-color), 966px 530px var(--stars-color),
    2143px 1641px var(--stars-color), 126px 425px var(--stars-color), 1222px 1300px var(--stars-color), 467px 751px var(--stars-color),
    2074px 2451px var(--stars-color), 1182px 648px var(--stars-color), 1675px 1561px var(--stars-color),
    2511px 2046px var(--stars-color), 1552px 2208px var(--stars-color), 2358px 2202px var(--stars-color),
    2020px 171px var(--stars-color), 545px 1555px var(--stars-color), 2484px 1107px var(--stars-color), 1770px 1725px var(--stars-color),
    2412px 926px var(--stars-color), 1076px 643px var(--stars-color), 1915px 412px var(--stars-color), 544px 2257px var(--stars-color),
    1014px 854px var(--stars-color), 572px 1641px var(--stars-color), 574px 927px var(--stars-color), 2536px 420px var(--stars-color),
    1326px 637px var(--stars-color), 549px 851px var(--stars-color), 2528px 1415px var(--stars-color), 26px 703px var(--stars-color),
    1104px 2116px var(--stars-color), 336px 475px var(--stars-color), 2107px 412px var(--stars-color), 494px 1121px var(--stars-color),
    2277px 274px var(--stars-color), 877px 1439px var(--stars-color), 2393px 2033px var(--stars-color), 108px 443px var(--stars-color),
    4px 1454px var(--stars-color), 1261px 1287px var(--stars-color), 591px 878px var(--stars-color), 1927px 2095px var(--stars-color),
    1929px 1602px var(--stars-color), 1328px 586px var(--stars-color), 797px 746px var(--stars-color), 1242px 1640px var(--stars-color),
    515px 69px var(--stars-color), 773px 1034px var(--stars-color), 1635px 671px var(--stars-color), 1823px 1457px var(--stars-color),
    2380px 923px var(--stars-color), 2440px 1430px var(--stars-color), 1894px 558px var(--stars-color), 1632px 609px var(--stars-color),
    224px 471px var(--stars-color), 2079px 1465px var(--stars-color), 1507px 2463px var(--stars-color), 2385px 1345px var(--stars-color),
    1674px 204px var(--stars-color), 382px 638px var(--stars-color), 2329px 2346px var(--stars-color), 1938px 362px var(--stars-color),
    1002px 582px var(--stars-color), 1174px 1048px var(--stars-color), 2499px 226px var(--stars-color), 1257px 738px var(--stars-color),
    1883px 1608px var(--stars-color), 740px 873px var(--stars-color), 1580px 976px var(--stars-color), 2552px 207px var(--stars-color),
    2471px 710px var(--stars-color), 216px 933px var(--stars-color), 1890px 735px var(--stars-color), 738px 1926px var(--stars-color),
    1313px 2068px var(--stars-color), 64px 920px var(--stars-color), 2486px 2557px var(--stars-color), 1744px 2432px var(--stars-color),
    1674px 1458px var(--stars-color), 455px 723px var(--stars-color), 1235px 25px var(--stars-color), 1230px 416px var(--stars-color),
    804px 2007px var(--stars-color), 380px 2275px var(--stars-color), 476px 1032px var(--stars-color), 1803px 2157px var(--stars-color),
    800px 960px var(--stars-color), 2522px 1448px var(--stars-color), 2037px 374px var(--stars-color), 2014px 1258px var(--stars-color),
    2289px 13px var(--stars-color), 1716px 1998px var(--stars-color), 1865px 2210px var(--stars-color), 1723px 576px var(--stars-color),
    261px 97px var(--stars-color), 699px 932px var(--stars-color), 1294px 696px var(--stars-color), 557px 1236px var(--stars-color),
    25px 1364px var(--stars-color), 999px 1210px var(--stars-color), 319px 1765px var(--stars-color), 1228px 713px var(--stars-color),
    1159px 1585px var(--stars-color), 2369px 1110px var(--stars-color), 2295px 397px var(--stars-color), 960px 2069px var(--stars-color),
    933px 1189px var(--stars-color), 648px 2143px var(--stars-color), 2351px 1225px var(--stars-color), 241px 852px var(--stars-color),
    383px 1735px var(--stars-color), 2376px 1959px var(--stars-color), 218px 827px var(--stars-color), 8px 289px var(--stars-color),
    2089px 1003px var(--stars-color), 1603px 1333px var(--stars-color), 1430px 685px var(--stars-color),
    2130px 2095px var(--stars-color), 2027px 1227px var(--stars-color), 1470px 1685px var(--stars-color),
    2465px 471px var(--stars-color), 766px 1251px var(--stars-color), 1512px 1688px var(--stars-color), 23px 220px var(--stars-color),
    71px 698px var(--stars-color), 1321px 1953px var(--stars-color), 307px 630px var(--stars-color), 1258px 386px var(--stars-color),
    354px 1880px var(--stars-color), 2290px 1484px var(--stars-color), 504px 955px var(--stars-color), 369px 849px var(--stars-color),
    1224px 1360px var(--stars-color), 712px 1577px var(--stars-color), 605px 1782px var(--stars-color), 2370px 920px var(--stars-color),
    1408px 336px var(--stars-color), 383px 1004px var(--stars-color), 1026px 1730px var(--stars-color), 1490px 525px var(--stars-color),
    1024px 197px var(--stars-color), 851px 2447px var(--stars-color), 1211px 711px var(--stars-color), 2501px 179px var(--stars-color),
    76px 2506px var(--stars-color), 1315px 1963px var(--stars-color), 2400px 881px var(--stars-color), 2450px 2390px var(--stars-color),
    174px 896px var(--stars-color), 1670px 1189px var(--stars-color), 1649px 2198px var(--stars-color), 1719px 141px var(--stars-color),
    141px 573px var(--stars-color), 1900px 2002px var(--stars-color), 2433px 1716px var(--stars-color), 216px 1268px var(--stars-color),
    1638px 341px var(--stars-color), 2164px 845px var(--stars-color), 707px 585px var(--stars-color), 1086px 195px var(--stars-color),
    836px 1126px var(--stars-color), 1043px 647px var(--stars-color), 2030px 2293px var(--stars-color), 1431px 2px var(--stars-color),
    2159px 1910px var(--stars-color), 701px 510px var(--stars-color), 277px 364px var(--stars-color), 2157px 2013px var(--stars-color),
    623px 858px var(--stars-color), 313px 712px var(--stars-color), 1084px 1516px var(--stars-color), 400px 1871px var(--stars-color),
    613px 70px var(--stars-color), 1139px 1796px var(--stars-color), 1725px 1770px var(--stars-color), 1625px 214px var(--stars-color),
    1243px 2315px var(--stars-color), 947px 2439px var(--stars-color), 991px 1597px var(--stars-color), 2019px 1044px var(--stars-color),
    411px 1981px var(--stars-color), 293px 232px var(--stars-color), 1317px 1002px var(--stars-color), 2355px 2014px var(--stars-color),
    340px 1958px var(--stars-color), 2006px 98px var(--stars-color), 223px 2105px var(--stars-color), 144px 1383px var(--stars-color),
    1775px 2420px var(--stars-color), 2528px 2314px var(--stars-color), 50px 229px var(--stars-color), 2405px 228px var(--stars-color),
    292px 220px var(--stars-color), 1094px 1210px var(--stars-color), 1059px 1943px var(--stars-color), 1426px 1369px var(--stars-color),
    1202px 67px var(--stars-color), 1292px 68px var(--stars-color), 2419px 1190px var(--stars-color), 1224px 1601px var(--stars-color),
    2321px 464px var(--stars-color), 500px 2501px var(--stars-color), 216px 1878px var(--stars-color), 629px 1875px var(--stars-color),
    902px 1041px var(--stars-color), 1733px 893px var(--stars-color), 2190px 1517px var(--stars-color), 532px 1027px var(--stars-color),
    653px 1269px var(--stars-color), 1904px 485px var(--stars-color), 1547px 509px var(--stars-color), 789px 938px var(--stars-color),
    1471px 663px var(--stars-color), 1984px 2047px var(--stars-color), 806px 597px var(--stars-color), 150px 1494px var(--stars-color),
    851px 2479px var(--stars-color), 596px 486px var(--stars-color), 578px 1329px var(--stars-color), 271px 146px var(--stars-color),
    1697px 2459px var(--stars-color), 1250px 1445px var(--stars-color), 1633px 2500px var(--stars-color), 971px 748px var(--stars-color),
    2378px 2495px var(--stars-color), 1179px 2px var(--stars-color), 607px 456px var(--stars-color), 1778px 2456px var(--stars-color),
    118px 1172px var(--stars-color), 440px 544px var(--stars-color), 488px 154px var(--stars-color), 532px 2084px var(--stars-color),
    1788px 2289px var(--stars-color), 886px 2286px var(--stars-color), 158px 765px var(--stars-color), 224px 391px var(--stars-color),
    1012px 2271px var(--stars-color), 1979px 1932px var(--stars-color), 24px 1799px var(--stars-color), 1591px 1458px var(--stars-color),
    2505px 1610px var(--stars-color), 1731px 1911px var(--stars-color), 1232px 261px var(--stars-color),
    2072px 1989px var(--stars-color), 1532px 1866px var(--stars-color), 1924px 1038px var(--stars-color),
    961px 2051px var(--stars-color), 1522px 745px var(--stars-color), 1664px 58px var(--stars-color), 980px 311px var(--stars-color),
    1050px 1721px var(--stars-color), 534px 1141px var(--stars-color), 1368px 1990px var(--stars-color), 1322px 993px var(--stars-color),
    1866px 1420px var(--stars-color), 2337px 2355px var(--stars-color), 500px 250px var(--stars-color), 1059px 1735px var(--stars-color),
    2468px 758px var(--stars-color), 1967px 431px var(--stars-color), 2110px 1985px var(--stars-color), 64px 1881px var(--stars-color),
    2363px 1199px var(--stars-color), 1179px 1043px var(--stars-color), 488px 1196px var(--stars-color),
    2002px 2268px var(--stars-color), 140px 563px var(--stars-color), 1948px 1162px var(--stars-color), 1244px 180px var(--stars-color),
    1151px 93px var(--stars-color), 397px 2396px var(--stars-color), 2232px 1012px var(--stars-color), 1008px 731px var(--stars-color),
    40px 175px var(--stars-color), 53px 943px var(--stars-color), 2044px 2151px var(--stars-color), 766px 1092px var(--stars-color),
    855px 2415px var(--stars-color), 300px 640px var(--stars-color), 2102px 424px var(--stars-color), 1270px 1993px var(--stars-color),
    1639px 1599px var(--stars-color), 2112px 1077px var(--stars-color), 1627px 2114px var(--stars-color),
    1885px 1373px var(--stars-color), 340px 1322px var(--stars-color), 878px 486px var(--stars-color), 2101px 2459px var(--stars-color),
    749px 1292px var(--stars-color), 1400px 2451px var(--stars-color), 2486px 2354px var(--stars-color),
    1964px 2380px var(--stars-color), 355px 1124px var(--stars-color), 719px 1948px var(--stars-color), 828px 1285px var(--stars-color),
    881px 2339px var(--stars-color), 1964px 1441px var(--stars-color), 1470px 1388px var(--stars-color),
    2199px 1188px var(--stars-color), 351px 94px var(--stars-color), 986px 1069px var(--stars-color), 509px 2233px var(--stars-color),
    1764px 1011px var(--stars-color), 2143px 1071px var(--stars-color), 2477px 117px var(--stars-color), 887px 2033px var(--stars-color),
    1237px 1950px var(--stars-color), 2076px 650px var(--stars-color), 1404px 1136px var(--stars-color), 1973px 867px var(--stars-color),
    1952px 1778px var(--stars-color), 1386px 2477px var(--stars-color), 1959px 1455px var(--stars-color),
    2195px 840px var(--stars-color), 2064px 304px var(--stars-color), 2348px 2032px var(--stars-color), 2526px 149px var(--stars-color),
    2143px 1654px var(--stars-color), 2274px 1665px var(--stars-color), 1137px 2282px var(--stars-color),
    626px 1196px var(--stars-color), 1310px 2092px var(--stars-color), 88px 2295px var(--stars-color), 1347px 274px var(--stars-color),
    1695px 1376px var(--stars-color), 2056px 1268px var(--stars-color), 2176px 599px var(--stars-color), 432px 1355px var(--stars-color),
    1633px 2296px var(--stars-color), 12px 2179px var(--stars-color), 513px 630px var(--stars-color), 2162px 1512px var(--stars-color),
    909px 1444px var(--stars-color), 1662px 142px var(--stars-color), 1772px 1316px var(--stars-color), 1677px 1324px var(--stars-color),
    159px 2471px var(--stars-color), 2184px 2109px var(--stars-color), 659px 2286px var(--stars-color), 1261px 410px var(--stars-color),
    1193px 1484px var(--stars-color), 609px 1846px var(--stars-color), 379px 958px var(--stars-color), 552px 2483px var(--stars-color),
    232px 683px var(--stars-color), 767px 230px var(--stars-color), 1493px 1768px var(--stars-color), 1253px 92px var(--stars-color),
    767px 2256px var(--stars-color), 1706px 803px var(--stars-color), 953px 2547px var(--stars-color), 77px 2347px var(--stars-color),
    1223px 1724px var(--stars-color), 1667px 568px var(--stars-color), 1137px 2419px var(--stars-color),
    2135px 2297px var(--stars-color), 294px 2099px var(--stars-color), 2207px 944px var(--stars-color), 1342px 1446px var(--stars-color),
    1279px 543px var(--stars-color), 1966px 883px var(--stars-color), 2352px 1173px var(--stars-color), 158px 2305px var(--stars-color),
    2288px 1458px var(--stars-color), 604px 1122px var(--stars-color), 364px 356px var(--stars-color), 2332px 1018px var(--stars-color),
    599px 140px var(--stars-color), 957px 1289px var(--stars-color), 926px 2062px var(--stars-color), 1990px 1938px var(--stars-color),
    2190px 2331px var(--stars-color), 628px 445px var(--stars-color), 2247px 556px var(--stars-color), 1830px 1936px var(--stars-color),
    2130px 1298px var(--stars-color), 621px 1231px var(--stars-color), 1685px 650px var(--stars-color), 226px 2181px var(--stars-color),
    2236px 2309px var(--stars-color), 1632px 1147px var(--stars-color), 1015px 904px var(--stars-color), 440px 969px var(--stars-color),
    2368px 675px var(--stars-color), 200px 1278px var(--stars-color), 475px 2512px var(--stars-color), 1980px 2037px var(--stars-color),
    896px 902px var(--stars-color), 1963px 2180px var(--stars-color), 303px 1670px var(--stars-color), 43px 516px var(--stars-color),
    2012px 610px var(--stars-color), 221px 1251px var(--stars-color), 1847px 2237px var(--stars-color), 1067px 1248px var(--stars-color),
    376px 1986px var(--stars-color), 1327px 2481px var(--stars-color), 786px 2498px var(--stars-color), 217px 1092px var(--stars-color),
    1941px 826px var(--stars-color), 922px 2000px var(--stars-color), 2523px 2479px var(--stars-color), 1869px 1648px var(--stars-color),
    942px 2106px var(--stars-color), 786px 592px var(--stars-color), 1801px 2397px var(--stars-color), 2085px 242px var(--stars-color),
    2009px 1222px var(--stars-color), 87px 1471px var(--stars-color), 295px 2106px var(--stars-color), 1334px 1694px var(--stars-color),
    1514px 1052px var(--stars-color), 62px 2494px var(--stars-color), 2314px 1662px var(--stars-color), 1794px 1131px var(--stars-color),
    2146px 1754px var(--stars-color), 2379px 679px var(--stars-color), 86px 857px var(--stars-color), 4px 544px var(--stars-color),
    1150px 2402px var(--stars-color), 1502px 177px var(--stars-color), 2231px 2489px var(--stars-color), 2118px 120px var(--stars-color),
    1654px 2559px var(--stars-color), 754px 1266px var(--stars-color), 2203px 347px var(--stars-color), 1823px 2522px var(--stars-color),
    1861px 1144px var(--stars-color), 2427px 1688px var(--stars-color), 174px 1750px var(--stars-color), 979px 1136px var(--stars-color),
    1180px 1148px var(--stars-color), 120px 184px var(--stars-color), 2029px 1424px var(--stars-color), 262px 2383px var(--stars-color),
    103px 1237px var(--stars-color), 279px 1536px var(--stars-color), 1462px 593px var(--stars-color), 1083px 2288px var(--stars-color),
    201px 410px var(--stars-color), 1044px 2324px var(--stars-color), 786px 504px var(--stars-color), 2539px 1541px var(--stars-color),
    2038px 509px var(--stars-color), 68px 1528px var(--stars-color), 360px 235px var(--stars-color), 1597px 139px var(--stars-color),
    2449px 2306px var(--stars-color), 1824px 1635px var(--stars-color), 1585px 1020px var(--stars-color),
    1533px 1558px var(--stars-color), 22px 1660px var(--stars-color), 2203px 1640px var(--stars-color), 2059px 1661px var(--stars-color),
    2486px 321px var(--stars-color), 2484px 1389px var(--stars-color), 222px 1807px var(--stars-color), 510px 2167px var(--stars-color),
    1298px 35px var(--stars-color), 1809px 190px var(--stars-color), 1365px 1275px var(--stars-color), 2195px 2560px var(--stars-color),
    1793px 293px var(--stars-color), 2449px 1520px var(--stars-color), 2510px 1286px var(--stars-color), 990px 1943px var(--stars-color),
    1078px 511px var(--stars-color), 1986px 20px var(--stars-color), 771px 489px var(--stars-color), 1595px 2248px var(--stars-color),
    663px 1450px var(--stars-color), 2291px 2463px var(--stars-color), 343px 2340px var(--stars-color), 476px 581px var(--stars-color),
    743px 659px var(--stars-color), 608px 471px var(--stars-color), 2068px 1748px var(--stars-color), 2133px 431px var(--stars-color),
    1595px 1604px var(--stars-color), 1550px 2247px var(--stars-color), 1247px 508px var(--stars-color), 548px 799px var(--stars-color),
    761px 975px var(--stars-color), 665px 247px var(--stars-color), 1166px 723px var(--stars-color), 399px 1934px var(--stars-color),
    1904px 1547px var(--stars-color), 194px 1277px var(--stars-color), 1349px 1285px var(--stars-color), 1409px 875px var(--stars-color),
    1181px 1027px var(--stars-color), 71px 806px var(--stars-color), 2135px 1015px var(--stars-color), 465px 1003px var(--stars-color),
    1921px 2426px var(--stars-color), 1463px 1342px var(--stars-color);
  animation: animStar 100s linear infinite;
}
.stars:after {
  content: " ";
  top: -600px;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow: 2144px 937px var(--stars-color), 789px 1572px var(--stars-color), 1912px 663px var(--stars-color),
    2088px 1161px var(--stars-color), 2222px 1063px var(--stars-color), 1009px 1009px var(--stars-color),
    1228px 2485px var(--stars-color), 285px 1257px var(--stars-color), 286px 1846px var(--stars-color), 538px 1073px var(--stars-color),
    1866px 2253px var(--stars-color), 2143px 1872px var(--stars-color), 232px 1150px var(--stars-color),
    2560px 2147px var(--stars-color), 973px 531px var(--stars-color), 918px 424px var(--stars-color), 1621px 2179px var(--stars-color),
    2060px 1330px var(--stars-color), 1157px 1986px var(--stars-color), 2178px 913px var(--stars-color), 915px 1458px var(--stars-color),
    2365px 542px var(--stars-color), 1432px 978px var(--stars-color), 1487px 185px var(--stars-color), 2365px 1378px var(--stars-color),
    1754px 2067px var(--stars-color), 946px 17px var(--stars-color), 668px 875px var(--stars-color), 1571px 90px var(--stars-color),
    1747px 2100px var(--stars-color), 691px 1824px var(--stars-color), 1989px 539px var(--stars-color), 1915px 565px var(--stars-color),
    1844px 1324px var(--stars-color), 1244px 1068px var(--stars-color), 754px 38px var(--stars-color), 2331px 1138px var(--stars-color),
    190px 637px var(--stars-color), 94px 404px var(--stars-color), 2300px 1216px var(--stars-color), 487px 1952px var(--stars-color),
    157px 2487px var(--stars-color), 1301px 368px var(--stars-color), 91px 1855px var(--stars-color), 1077px 325px var(--stars-color),
    178px 1773px var(--stars-color), 1021px 534px var(--stars-color), 697px 2029px var(--stars-color), 2372px 1206px var(--stars-color),
    868px 1184px var(--stars-color), 2263px 1714px var(--stars-color), 35px 214px var(--stars-color), 1360px 189px var(--stars-color),
    1801px 942px var(--stars-color), 983px 1015px var(--stars-color), 2494px 591px var(--stars-color), 2187px 2230px var(--stars-color),
    1716px 1108px var(--stars-color), 2281px 804px var(--stars-color), 206px 1673px var(--stars-color), 2116px 268px var(--stars-color),
    2556px 127px var(--stars-color), 671px 564px var(--stars-color), 2449px 684px var(--stars-color), 1711px 96px var(--stars-color),
    1250px 1295px var(--stars-color), 764px 2552px var(--stars-color), 628px 345px var(--stars-color), 855px 618px var(--stars-color),
    668px 1605px var(--stars-color), 131px 609px var(--stars-color), 2207px 1560px var(--stars-color), 1166px 1085px var(--stars-color),
    2346px 1037px var(--stars-color), 639px 1552px var(--stars-color), 1496px 330px var(--stars-color), 146px 61px var(--stars-color),
    1104px 25px var(--stars-color), 501px 1393px var(--stars-color), 1102px 550px var(--stars-color), 610px 1318px var(--stars-color),
    2062px 996px var(--stars-color), 374px 1674px var(--stars-color), 2347px 1981px var(--stars-color), 1355px 896px var(--stars-color),
    2282px 2314px var(--stars-color), 455px 2058px var(--stars-color), 157px 820px var(--stars-color), 496px 1791px var(--stars-color),
    1288px 2029px var(--stars-color), 1116px 2185px var(--stars-color), 527px 1216px var(--stars-color), 612px 1228px var(--stars-color),
    879px 2420px var(--stars-color), 1434px 1174px var(--stars-color), 1597px 2264px var(--stars-color), 812px 273px var(--stars-color),
    2238px 614px var(--stars-color), 149px 232px var(--stars-color), 57px 2327px var(--stars-color), 1995px 713px var(--stars-color),
    386px 2092px var(--stars-color), 909px 1411px var(--stars-color), 460px 1358px var(--stars-color), 294px 1736px var(--stars-color),
    1470px 2338px var(--stars-color), 2379px 2104px var(--stars-color), 898px 2231px var(--stars-color),
    1421px 2017px var(--stars-color), 2485px 960px var(--stars-color), 2492px 1352px var(--stars-color), 294px 1034px var(--stars-color),
    2228px 432px var(--stars-color), 427px 1489px var(--stars-color), 139px 1715px var(--stars-color), 851px 2317px var(--stars-color),
    1631px 1545px var(--stars-color), 2349px 752px var(--stars-color), 1016px 720px var(--stars-color), 628px 1836px var(--stars-color),
    2547px 879px var(--stars-color), 1044px 2147px var(--stars-color), 1416px 1242px var(--stars-color),
    2103px 1684px var(--stars-color), 821px 272px var(--stars-color), 1318px 431px var(--stars-color), 2338px 258px var(--stars-color),
    1587px 1504px var(--stars-color), 1436px 1133px var(--stars-color), 692px 1393px var(--stars-color), 372px 1171px var(--stars-color),
    981px 1198px var(--stars-color), 1287px 2196px var(--stars-color), 1564px 2368px var(--stars-color),
    1219px 1113px var(--stars-color), 66px 1016px var(--stars-color), 524px 486px var(--stars-color), 1778px 2391px var(--stars-color),
    789px 885px var(--stars-color), 2308px 1689px var(--stars-color), 167px 1616px var(--stars-color), 1744px 1336px var(--stars-color),
    920px 179px var(--stars-color), 2346px 1394px var(--stars-color), 636px 187px var(--stars-color), 2469px 1549px var(--stars-color),
    587px 467px var(--stars-color), 56px 221px var(--stars-color), 1468px 1794px var(--stars-color), 1958px 1218px var(--stars-color),
    546px 594px var(--stars-color), 699px 2545px var(--stars-color), 2518px 401px var(--stars-color), 1777px 1250px var(--stars-color),
    1704px 754px var(--stars-color), 2039px 765px var(--stars-color), 517px 543px var(--stars-color), 2342px 2390px var(--stars-color),
    1205px 1001px var(--stars-color), 700px 14px var(--stars-color), 2514px 1036px var(--stars-color), 2317px 1508px var(--stars-color),
    2317px 2023px var(--stars-color), 766px 1291px var(--stars-color), 2186px 1708px var(--stars-color),
    2511px 1644px var(--stars-color), 1156px 906px var(--stars-color), 1999px 180px var(--stars-color), 1455px 530px var(--stars-color),
    2457px 981px var(--stars-color), 783px 1504px var(--stars-color), 1767px 1888px var(--stars-color), 1925px 1845px var(--stars-color),
    1651px 1563px var(--stars-color), 2439px 252px var(--stars-color), 387px 1139px var(--stars-color), 841px 701px var(--stars-color),
    332px 1697px var(--stars-color), 2250px 867px var(--stars-color), 1110px 2463px var(--stars-color), 1867px 2481px var(--stars-color),
    2315px 2433px var(--stars-color), 1940px 140px var(--stars-color), 2453px 2083px var(--stars-color),
    2156px 1480px var(--stars-color), 839px 2307px var(--stars-color), 232px 992px var(--stars-color), 572px 1361px var(--stars-color),
    1154px 1860px var(--stars-color), 1807px 2351px var(--stars-color), 1779px 1430px var(--stars-color),
    1841px 626px var(--stars-color), 764px 1243px var(--stars-color), 272px 833px var(--stars-color), 1439px 1693px var(--stars-color),
    606px 256px var(--stars-color), 364px 2035px var(--stars-color), 2145px 60px var(--stars-color), 1689px 1762px var(--stars-color),
    333px 2139px var(--stars-color), 1463px 1087px var(--stars-color), 1934px 760px var(--stars-color), 1205px 288px var(--stars-color),
    2082px 1772px var(--stars-color), 146px 2252px var(--stars-color), 2411px 2080px var(--stars-color), 417px 1404px var(--stars-color),
    1153px 1998px var(--stars-color), 1781px 1274px var(--stars-color), 610px 1660px var(--stars-color), 1153px 590px var(--stars-color),
    310px 1250px var(--stars-color), 59px 2239px var(--stars-color), 1487px 1751px var(--stars-color), 1307px 2460px var(--stars-color),
    983px 2488px var(--stars-color), 1918px 1852px var(--stars-color), 2282px 1263px var(--stars-color), 410px 2192px var(--stars-color),
    2251px 106px var(--stars-color), 130px 831px var(--stars-color), 2222px 1639px var(--stars-color), 1110px 119px var(--stars-color),
    454px 833px var(--stars-color), 491px 2238px var(--stars-color), 2128px 1763px var(--stars-color), 1792px 1580px var(--stars-color),
    1570px 1323px var(--stars-color), 1314px 400px var(--stars-color), 2501px 512px var(--stars-color), 738px 2444px var(--stars-color),
    1877px 1593px var(--stars-color), 1318px 1021px var(--stars-color), 1609px 1516px var(--stars-color),
    1988px 1889px var(--stars-color), 957px 72px var(--stars-color), 2061px 1569px var(--stars-color), 47px 1037px var(--stars-color),
    769px 1980px var(--stars-color), 24px 1014px var(--stars-color), 657px 2221px var(--stars-color), 2162px 1383px var(--stars-color),
    1836px 147px var(--stars-color), 784px 1252px var(--stars-color), 591px 1253px var(--stars-color), 648px 2531px var(--stars-color),
    944px 1481px var(--stars-color), 2248px 823px var(--stars-color), 434px 344px var(--stars-color), 825px 1721px var(--stars-color),
    302px 2260px var(--stars-color), 906px 378px var(--stars-color), 2423px 265px var(--stars-color), 2113px 1545px var(--stars-color),
    196px 1401px var(--stars-color), 2002px 449px var(--stars-color), 714px 1951px var(--stars-color), 426px 917px var(--stars-color),
    637px 2508px var(--stars-color), 317px 2043px var(--stars-color), 2169px 1440px var(--stars-color), 1901px 571px var(--stars-color),
    1105px 1338px var(--stars-color), 1749px 1660px var(--stars-color), 2281px 2344px var(--stars-color),
    1099px 894px var(--stars-color), 980px 333px var(--stars-color), 461px 2516px var(--stars-color), 466px 2082px var(--stars-color),
    2489px 1955px var(--stars-color), 1325px 239px var(--stars-color), 1590px 2526px var(--stars-color), 2449px 823px var(--stars-color),
    2271px 337px var(--stars-color), 429px 953px var(--stars-color), 2081px 2474px var(--stars-color), 2334px 2284px var(--stars-color),
    233px 1552px var(--stars-color), 694px 190px var(--stars-color), 1766px 1963px var(--stars-color), 1353px 1118px var(--stars-color),
    1387px 1222px var(--stars-color), 1032px 1090px var(--stars-color), 279px 365px var(--stars-color), 2019px 2480px var(--stars-color),
    2318px 770px var(--stars-color), 1936px 243px var(--stars-color), 1152px 1366px var(--stars-color), 2430px 1347px var(--stars-color),
    2413px 486px var(--stars-color), 924px 963px var(--stars-color), 2449px 1592px var(--stars-color), 2055px 362px var(--stars-color),
    389px 641px var(--stars-color), 2317px 7px var(--stars-color), 1414px 1625px var(--stars-color), 2094px 359px var(--stars-color),
    1479px 104px var(--stars-color), 101px 1940px var(--stars-color), 1100px 723px var(--stars-color), 1407px 1273px var(--stars-color),
    2182px 1577px var(--stars-color), 65px 2326px var(--stars-color), 2060px 128px var(--stars-color), 1337px 920px var(--stars-color),
    171px 1897px var(--stars-color), 1235px 2036px var(--stars-color), 1835px 2026px var(--stars-color), 56px 655px var(--stars-color),
    2306px 2447px var(--stars-color), 1196px 1627px var(--stars-color), 842px 2228px var(--stars-color), 103px 517px var(--stars-color),
    2108px 1201px var(--stars-color), 1691px 4px var(--stars-color), 841px 58px var(--stars-color), 456px 653px var(--stars-color),
    2499px 1044px var(--stars-color), 184px 1836px var(--stars-color), 2024px 902px var(--stars-color), 168px 697px var(--stars-color),
    2095px 1580px var(--stars-color), 2519px 2138px var(--stars-color), 2092px 2364px var(--stars-color),
    1073px 836px var(--stars-color), 88px 236px var(--stars-color), 197px 2480px var(--stars-color), 912px 70px var(--stars-color),
    2299px 665px var(--stars-color), 360px 408px var(--stars-color), 1883px 1934px var(--stars-color), 2088px 2119px var(--stars-color),
    247px 418px var(--stars-color), 2185px 84px var(--stars-color), 1108px 1300px var(--stars-color), 1431px 1008px var(--stars-color),
    2244px 1716px var(--stars-color), 1096px 1984px var(--stars-color), 1602px 2219px var(--stars-color),
    763px 1045px var(--stars-color), 739px 1081px var(--stars-color), 2352px 957px var(--stars-color), 1428px 2097px var(--stars-color),
    752px 1895px var(--stars-color), 902px 732px var(--stars-color), 425px 20px var(--stars-color), 2188px 257px var(--stars-color),
    1240px 2393px var(--stars-color), 168px 177px var(--stars-color), 537px 1313px var(--stars-color), 260px 290px var(--stars-color),
    1333px 1648px var(--stars-color), 1696px 1956px var(--stars-color), 1864px 1627px var(--stars-color),
    1388px 1367px var(--stars-color), 2372px 2548px var(--stars-color), 2225px 981px var(--stars-color),
    1482px 1479px var(--stars-color), 1264px 714px var(--stars-color), 1216px 873px var(--stars-color), 880px 2097px var(--stars-color),
    1540px 1644px var(--stars-color), 1711px 155px var(--stars-color), 2029px 1504px var(--stars-color),
    2383px 1575px var(--stars-color), 1122px 150px var(--stars-color), 2233px 1153px var(--stars-color), 40px 396px var(--stars-color),
    1016px 1036px var(--stars-color), 782px 1170px var(--stars-color), 2101px 1090px var(--stars-color), 2280px 941px var(--stars-color),
    1575px 339px var(--stars-color), 2440px 411px var(--stars-color), 1214px 2555px var(--stars-color), 2148px 829px var(--stars-color),
    1113px 2560px var(--stars-color), 625px 389px var(--stars-color), 233px 904px var(--stars-color), 1265px 1402px var(--stars-color),
    871px 2498px var(--stars-color), 1504px 430px var(--stars-color), 2010px 337px var(--stars-color), 135px 1285px var(--stars-color),
    688px 607px var(--stars-color), 1298px 1854px var(--stars-color), 75px 2047px var(--stars-color), 552px 32px var(--stars-color),
    1485px 322px var(--stars-color), 824px 794px var(--stars-color), 986px 58px var(--stars-color), 1191px 2508px var(--stars-color),
    1073px 393px var(--stars-color), 1515px 1310px var(--stars-color), 2031px 350px var(--stars-color), 234px 2027px var(--stars-color),
    341px 1422px var(--stars-color), 729px 2273px var(--stars-color), 984px 2052px var(--stars-color), 1183px 1052px var(--stars-color),
    1157px 760px var(--stars-color), 1924px 664px var(--stars-color), 1485px 341px var(--stars-color), 2026px 1540px var(--stars-color),
    1810px 1337px var(--stars-color), 1331px 2103px var(--stars-color), 1386px 1864px var(--stars-color),
    1111px 2275px var(--stars-color), 1471px 892px var(--stars-color), 2104px 2518px var(--stars-color), 89px 1567px var(--stars-color),
    168px 189px var(--stars-color), 823px 1393px var(--stars-color), 1322px 607px var(--stars-color), 1859px 1267px var(--stars-color),
    2026px 169px var(--stars-color), 789px 1962px var(--stars-color), 2010px 2298px var(--stars-color), 2115px 1210px var(--stars-color),
    1793px 335px var(--stars-color), 2048px 2549px var(--stars-color), 1491px 1253px var(--stars-color), 249px 2033px var(--stars-color),
    835px 497px var(--stars-color), 1852px 1666px var(--stars-color), 2189px 68px var(--stars-color), 1566px 1918px var(--stars-color),
    418px 786px var(--stars-color), 839px 463px var(--stars-color), 1749px 2332px var(--stars-color), 441px 1507px var(--stars-color),
    2449px 1264px var(--stars-color), 625px 1426px var(--stars-color), 1449px 1282px var(--stars-color),
    1253px 2077px var(--stars-color), 458px 1235px var(--stars-color), 674px 1235px var(--stars-color), 2307px 2177px var(--stars-color),
    2421px 1128px var(--stars-color), 2126px 406px var(--stars-color), 2406px 1385px var(--stars-color), 903px 1112px var(--stars-color),
    1640px 828px var(--stars-color), 1484px 2128px var(--stars-color), 1935px 1646px var(--stars-color), 2218px 6px var(--stars-color),
    1348px 2150px var(--stars-color), 1979px 520px var(--stars-color), 2123px 2140px var(--stars-color),
    1872px 2429px var(--stars-color), 2006px 1228px var(--stars-color), 578px 1961px var(--stars-color), 200px 719px var(--stars-color),
    1075px 1164px var(--stars-color), 1749px 2104px var(--stars-color), 770px 1052px var(--stars-color), 1392px 502px var(--stars-color),
    2318px 2386px var(--stars-color), 1902px 679px var(--stars-color), 1146px 549px var(--stars-color), 175px 1620px var(--stars-color),
    698px 939px var(--stars-color), 2401px 2444px var(--stars-color), 1538px 567px var(--stars-color), 1136px 1920px var(--stars-color),
    881px 447px var(--stars-color), 2344px 210px var(--stars-color), 1410px 786px var(--stars-color), 353px 1951px var(--stars-color),
    2119px 578px var(--stars-color), 2544px 106px var(--stars-color), 2085px 2107px var(--stars-color), 2081px 1275px var(--stars-color),
    98px 960px var(--stars-color), 1084px 1335px var(--stars-color), 2152px 471px var(--stars-color), 158px 1728px var(--stars-color),
    454px 2458px var(--stars-color), 1873px 1828px var(--stars-color), 2450px 556px var(--stars-color), 817px 1032px var(--stars-color),
    555px 1269px var(--stars-color), 763px 276px var(--stars-color), 494px 857px var(--stars-color), 2103px 2488px var(--stars-color),
    452px 1297px var(--stars-color), 2161px 2516px var(--stars-color), 264px 478px var(--stars-color), 242px 1382px var(--stars-color),
    2294px 1904px var(--stars-color), 130px 2462px var(--stars-color), 302px 1378px var(--stars-color), 1637px 1391px var(--stars-color),
    1363px 1384px var(--stars-color), 1209px 1543px var(--stars-color), 1905px 2207px var(--stars-color),
    976px 2399px var(--stars-color), 2015px 1811px var(--stars-color), 1814px 2393px var(--stars-color), 952px 1846px var(--stars-color),
    109px 171px var(--stars-color), 121px 364px var(--stars-color), 1828px 577px var(--stars-color), 1306px 460px var(--stars-color),
    678px 304px var(--stars-color), 191px 1931px var(--stars-color), 521px 566px var(--stars-color), 1634px 2268px var(--stars-color),
    1672px 204px var(--stars-color), 2114px 2401px var(--stars-color), 301px 1156px var(--stars-color), 709px 538px var(--stars-color),
    1029px 2364px var(--stars-color), 583px 2005px var(--stars-color), 1396px 2169px var(--stars-color),
    1690px 1061px var(--stars-color), 2216px 2069px var(--stars-color), 268px 1283px var(--stars-color),
    1137px 2476px var(--stars-color), 1606px 2467px var(--stars-color), 2232px 2262px var(--stars-color),
    1638px 355px var(--stars-color), 869px 2249px var(--stars-color), 2202px 904px var(--stars-color), 1592px 726px var(--stars-color),
    1599px 741px var(--stars-color), 368px 1775px var(--stars-color), 1097px 1594px var(--stars-color), 1634px 2494px var(--stars-color),
    1668px 58px var(--stars-color), 1874px 1717px var(--stars-color), 615px 1196px var(--stars-color), 1196px 335px var(--stars-color),
    2295px 750px var(--stars-color), 2492px 2217px var(--stars-color), 648px 1792px var(--stars-color), 850px 434px var(--stars-color),
    1662px 621px var(--stars-color), 2025px 466px var(--stars-color), 1288px 660px var(--stars-color), 659px 435px var(--stars-color),
    1825px 1677px var(--stars-color), 49px 923px var(--stars-color), 4px 1679px var(--stars-color), 2104px 1698px var(--stars-color),
    1566px 27px var(--stars-color), 1659px 2321px var(--stars-color), 209px 2126px var(--stars-color), 105px 254px var(--stars-color),
    844px 855px var(--stars-color), 1857px 2051px var(--stars-color), 2051px 2114px var(--stars-color), 931px 249px var(--stars-color),
    158px 106px var(--stars-color), 2147px 885px var(--stars-color), 1975px 921px var(--stars-color), 1692px 258px var(--stars-color),
    2124px 2552px var(--stars-color), 519px 2240px var(--stars-color), 1130px 892px var(--stars-color), 1377px 1117px var(--stars-color),
    601px 485px var(--stars-color), 1180px 1224px var(--stars-color), 1838px 2456px var(--stars-color), 915px 1801px var(--stars-color),
    1466px 850px var(--stars-color), 1503px 2389px var(--stars-color), 2285px 2122px var(--stars-color),
    1062px 2030px var(--stars-color), 1759px 2539px var(--stars-color), 1533px 259px var(--stars-color), 800px 2204px var(--stars-color),
    1711px 705px var(--stars-color), 242px 121px var(--stars-color), 1437px 314px var(--stars-color), 1908px 1751px var(--stars-color),
    2455px 2236px var(--stars-color), 680px 1200px var(--stars-color), 1584px 1731px var(--stars-color),
    2176px 2197px var(--stars-color), 2477px 1560px var(--stars-color), 2033px 1851px var(--stars-color), 391px 120px var(--stars-color),
    2456px 1258px var(--stars-color), 1724px 722px var(--stars-color), 1351px 1593px var(--stars-color), 1896px 521px var(--stars-color),
    492px 155px var(--stars-color), 57px 1589px var(--stars-color), 1258px 2289px var(--stars-color), 1547px 789px var(--stars-color),
    140px 1133px var(--stars-color), 1193px 1943px var(--stars-color), 385px 1632px var(--stars-color), 95px 882px var(--stars-color),
    1995px 1075px var(--stars-color), 1886px 1037px var(--stars-color), 298px 1637px var(--stars-color), 631px 300px var(--stars-color),
    1964px 2545px var(--stars-color), 312px 308px var(--stars-color), 2420px 473px var(--stars-color), 475px 1765px var(--stars-color),
    1701px 208px var(--stars-color), 2151px 1810px var(--stars-color), 241px 937px var(--stars-color), 262px 908px var(--stars-color),
    2307px 2298px var(--stars-color), 1174px 1858px var(--stars-color), 651px 2123px var(--stars-color),
    1809px 1257px var(--stars-color), 293px 774px var(--stars-color), 543px 241px var(--stars-color), 2409px 1082px var(--stars-color),
    2273px 2234px var(--stars-color), 1013px 1143px var(--stars-color), 1854px 2332px var(--stars-color), 85px 123px var(--stars-color),
    139px 2084px var(--stars-color), 32px 297px var(--stars-color), 816px 2060px var(--stars-color), 1504px 312px var(--stars-color),
    2065px 928px var(--stars-color), 1682px 2350px var(--stars-color), 1323px 391px var(--stars-color), 2241px 305px var(--stars-color),
    1490px 215px var(--stars-color), 2385px 1729px var(--stars-color), 1876px 1405px var(--stars-color), 409px 627px var(--stars-color),
    1197px 2235px var(--stars-color), 1840px 748px var(--stars-color), 405px 2075px var(--stars-color), 225px 2348px var(--stars-color),
    692px 617px var(--stars-color), 445px 1697px var(--stars-color), 1278px 2558px var(--stars-color), 1835px 1253px var(--stars-color),
    78px 114px var(--stars-color), 244px 668px var(--stars-color), 984px 263px var(--stars-color), 554px 1899px var(--stars-color),
    1228px 891px var(--stars-color), 869px 2474px var(--stars-color), 1209px 380px var(--stars-color), 2420px 421px var(--stars-color),
    2557px 243px var(--stars-color), 1191px 1261px var(--stars-color), 317px 2169px var(--stars-color), 1808px 2502px var(--stars-color),
    1509px 14px var(--stars-color), 1823px 1959px var(--stars-color), 805px 791px var(--stars-color), 799px 198px var(--stars-color),
    2364px 2481px var(--stars-color), 2543px 2311px var(--stars-color), 2414px 634px var(--stars-color), 889px 154px var(--stars-color),
    1296px 456px var(--stars-color), 510px 46px var(--stars-color), 1475px 48px var(--stars-color), 1314px 550px var(--stars-color),
    810px 2541px var(--stars-color), 468px 912px var(--stars-color), 1498px 1463px var(--stars-color), 2473px 2247px var(--stars-color),
    204px 2111px var(--stars-color), 2008px 898px var(--stars-color), 2176px 1006px var(--stars-color), 262px 1485px var(--stars-color),
    1792px 1129px var(--stars-color), 774px 2514px var(--stars-color), 2461px 2192px var(--stars-color),
    1642px 2468px var(--stars-color), 1651px 1718px var(--stars-color), 702px 1197px var(--stars-color), 1364px 801px var(--stars-color),
    1128px 193px var(--stars-color), 1926px 2004px var(--stars-color), 995px 97px var(--stars-color), 1189px 1525px var(--stars-color),
    1559px 643px var(--stars-color), 2521px 905px var(--stars-color), 2400px 380px var(--stars-color), 780px 1948px var(--stars-color),
    1777px 1925px var(--stars-color), 787px 1385px var(--stars-color), 265px 2348px var(--stars-color), 453px 2269px var(--stars-color),
    288px 318px var(--stars-color), 864px 1041px var(--stars-color), 627px 301px var(--stars-color), 1896px 361px var(--stars-color),
    1851px 361px var(--stars-color), 525px 1599px var(--stars-color), 771px 310px var(--stars-color), 66px 1807px var(--stars-color),
    1562px 401px var(--stars-color), 2460px 1368px var(--stars-color), 1160px 852px var(--stars-color), 1077px 1428px var(--stars-color),
    385px 1149px var(--stars-color), 324px 986px var(--stars-color), 1865px 1317px var(--stars-color), 2520px 2270px var(--stars-color),
    1772px 2332px var(--stars-color), 1292px 821px var(--stars-color), 2555px 1789px var(--stars-color), 1332px 954px var(--stars-color),
    1649px 1711px var(--stars-color), 1829px 1145px var(--stars-color), 1249px 2024px var(--stars-color),
    2401px 2039px var(--stars-color), 1720px 495px var(--stars-color), 1048px 347px var(--stars-color), 538px 1109px var(--stars-color),
    2291px 1818px var(--stars-color), 1347px 347px var(--stars-color), 301px 1278px var(--stars-color), 132px 1129px var(--stars-color),
    1155px 2429px var(--stars-color), 2117px 121px var(--stars-color), 431px 2409px var(--stars-color), 2226px 1393px var(--stars-color),
    1952px 1861px var(--stars-color), 743px 1397px var(--stars-color), 844px 2302px var(--stars-color), 165px 407px var(--stars-color),
    1319px 1166px var(--stars-color), 1721px 1683px var(--stars-color), 2312px 889px var(--stars-color), 705px 222px var(--stars-color),
    799px 981px var(--stars-color), 2334px 1932px var(--stars-color), 1710px 508px var(--stars-color), 579px 538px var(--stars-color),
    1177px 1123px var(--stars-color), 2417px 1481px var(--stars-color), 793px 1240px var(--stars-color), 418px 1828px var(--stars-color),
    819px 2312px var(--stars-color), 922px 573px var(--stars-color), 1432px 2088px var(--stars-color), 159px 1331px var(--stars-color),
    1040px 2355px var(--stars-color), 1210px 86px var(--stars-color), 1326px 2126px var(--stars-color), 1042px 1504px var(--stars-color),
    1635px 180px var(--stars-color), 1918px 1221px var(--stars-color), 1776px 524px var(--stars-color), 1133px 1677px var(--stars-color),
    2472px 996px var(--stars-color), 107px 45px var(--stars-color), 314px 2550px var(--stars-color), 185px 677px var(--stars-color),
    2342px 2339px var(--stars-color), 1604px 801px var(--stars-color), 1691px 1454px var(--stars-color), 980px 485px var(--stars-color),
    1872px 1223px var(--stars-color), 1070px 1122px var(--stars-color), 790px 2078px var(--stars-color), 85px 57px var(--stars-color),
    1726px 1894px var(--stars-color), 1144px 974px var(--stars-color), 1897px 725px var(--stars-color), 544px 628px var(--stars-color),
    140px 348px var(--stars-color), 1810px 134px var(--stars-color), 2374px 589px var(--stars-color), 1718px 955px var(--stars-color),
    1177px 776px var(--stars-color), 1268px 2198px var(--stars-color), 2130px 898px var(--stars-color), 802px 76px var(--stars-color),
    118px 1864px var(--stars-color), 651px 1491px var(--stars-color), 1425px 2095px var(--stars-color), 2169px 891px var(--stars-color),
    2208px 106px var(--stars-color), 655px 1060px var(--stars-color), 1132px 287px var(--stars-color), 7px 2551px var(--stars-color),
    1961px 889px var(--stars-color), 2551px 49px var(--stars-color), 1643px 386px var(--stars-color), 1943px 2496px var(--stars-color),
    2091px 2365px var(--stars-color), 2075px 644px var(--stars-color), 473px 769px var(--stars-color), 851px 1758px var(--stars-color),
    1890px 2262px var(--stars-color), 510px 1251px var(--stars-color), 1056px 782px var(--stars-color), 1532px 305px var(--stars-color),
    2352px 1470px var(--stars-color), 1523px 791px var(--stars-color), 650px 82px var(--stars-color), 1243px 697px var(--stars-color),
    1232px 2210px var(--stars-color), 1461px 413px var(--stars-color), 1090px 288px var(--stars-color), 1139px 2325px var(--stars-color),
    1213px 1289px var(--stars-color), 2535px 2307px var(--stars-color), 1587px 2248px var(--stars-color),
    904px 2163px var(--stars-color), 1117px 2059px var(--stars-color), 2074px 1594px var(--stars-color), 23px 2355px var(--stars-color),
    1963px 1297px var(--stars-color), 2102px 1695px var(--stars-color), 1736px 1171px var(--stars-color),
    253px 1891px var(--stars-color), 1060px 890px var(--stars-color), 525px 2394px var(--stars-color), 753px 1327px var(--stars-color),
    2460px 1916px var(--stars-color), 1447px 507px var(--stars-color), 2288px 501px var(--stars-color), 1255px 493px var(--stars-color),
    1600px 1128px var(--stars-color), 581px 1392px var(--stars-color), 1356px 781px var(--stars-color), 793px 647px var(--stars-color),
    286px 320px var(--stars-color), 1952px 1077px var(--stars-color), 194px 1756px var(--stars-color), 1339px 381px var(--stars-color),
    1711px 2535px var(--stars-color), 595px 2090px var(--stars-color), 1515px 584px var(--stars-color), 1216px 1032px var(--stars-color),
    1128px 794px var(--stars-color), 570px 1246px var(--stars-color), 1519px 646px var(--stars-color), 1441px 1514px var(--stars-color),
    499px 154px var(--stars-color), 322px 1799px var(--stars-color), 38px 1376px var(--stars-color), 576px 382px var(--stars-color),
    873px 1141px var(--stars-color), 1726px 839px var(--stars-color), 1306px 975px var(--stars-color), 1566px 2237px var(--stars-color),
    1637px 1803px var(--stars-color), 1454px 1005px var(--stars-color), 315px 2026px var(--stars-color), 432px 1337px var(--stars-color),
    741px 2452px var(--stars-color), 1509px 293px var(--stars-color), 2219px 26px var(--stars-color), 2473px 1068px var(--stars-color),
    116px 475px var(--stars-color), 344px 467px var(--stars-color), 2268px 401px var(--stars-color), 1759px 2310px var(--stars-color),
    2216px 2554px var(--stars-color), 1900px 2538px var(--stars-color), 1767px 1745px var(--stars-color),
    2129px 1638px var(--stars-color), 1130px 1067px var(--stars-color), 540px 2334px var(--stars-color),
    1118px 1291px var(--stars-color), 2273px 1213px var(--stars-color), 1869px 66px var(--stars-color), 1341px 727px var(--stars-color),
    1285px 2200px var(--stars-color), 2088px 893px var(--stars-color), 2466px 1440px var(--stars-color),
    2170px 2449px var(--stars-color), 122px 8px var(--stars-color), 1152px 1170px var(--stars-color), 184px 1943px var(--stars-color),
    1608px 1479px var(--stars-color), 285px 2503px var(--stars-color), 2488px 220px var(--stars-color), 811px 888px var(--stars-color),
    1364px 992px var(--stars-color), 1034px 413px var(--stars-color), 1949px 1066px var(--stars-color), 2229px 1288px var(--stars-color),
    488px 683px var(--stars-color), 535px 467px var(--stars-color), 2303px 1211px var(--stars-color), 1845px 224px var(--stars-color),
    1186px 1939px var(--stars-color), 1373px 219px var(--stars-color), 1514px 1191px var(--stars-color), 2297px 380px var(--stars-color),
    203px 2423px var(--stars-color), 1519px 1633px var(--stars-color), 582px 830px var(--stars-color), 2322px 853px var(--stars-color),
    1409px 1669px var(--stars-color), 1425px 2402px var(--stars-color), 116px 2122px var(--stars-color),
    1154px 2457px var(--stars-color), 600px 1943px var(--stars-color), 296px 2513px var(--stars-color), 264px 453px var(--stars-color),
    2186px 2175px var(--stars-color), 1423px 419px var(--stars-color), 1322px 459px var(--stars-color), 415px 2002px var(--stars-color),
    598px 2293px var(--stars-color), 628px 1610px var(--stars-color), 547px 921px var(--stars-color), 160px 1581px var(--stars-color),
    2035px 1626px var(--stars-color), 755px 1734px var(--stars-color), 739px 2024px var(--stars-color), 87px 245px var(--stars-color),
    1116px 947px var(--stars-color), 1956px 2048px var(--stars-color), 1327px 990px var(--stars-color), 323px 744px var(--stars-color),
    1437px 164px var(--stars-color), 2437px 695px var(--stars-color), 1242px 2410px var(--stars-color), 541px 2376px var(--stars-color),
    669px 1178px var(--stars-color), 710px 1798px var(--stars-color), 1600px 97px var(--stars-color), 1353px 32px var(--stars-color),
    2306px 1343px var(--stars-color), 920px 848px var(--stars-color), 1822px 578px var(--stars-color), 476px 2095px var(--stars-color),
    1711px 1165px var(--stars-color), 762px 554px var(--stars-color), 1950px 512px var(--stars-color), 1116px 653px var(--stars-color),
    1544px 2519px var(--stars-color), 1190px 812px var(--stars-color), 2516px 1065px var(--stars-color), 2056px 569px var(--stars-color),
    2029px 289px var(--stars-color), 1315px 706px var(--stars-color), 2088px 1998px var(--stars-color), 279px 1565px var(--stars-color),
    1775px 562px var(--stars-color), 367px 1348px var(--stars-color), 786px 2261px var(--stars-color), 1012px 2254px var(--stars-color),
    1460px 977px var(--stars-color), 1001px 1845px var(--stars-color), 382px 1791px var(--stars-color), 1547px 997px var(--stars-color),
    573px 611px var(--stars-color), 2076px 2219px var(--stars-color), 2395px 449px var(--stars-color), 367px 48px var(--stars-color),
    2234px 449px var(--stars-color), 1627px 873px var(--stars-color), 2226px 1710px var(--stars-color), 1616px 912px var(--stars-color),
    1311px 378px var(--stars-color), 942px 2558px var(--stars-color), 373px 145px var(--stars-color), 200px 2311px var(--stars-color),
    2422px 2114px var(--stars-color), 232px 1011px var(--stars-color), 1865px 582px var(--stars-color), 1230px 844px var(--stars-color),
    1302px 1347px var(--stars-color), 68px 2507px var(--stars-color), 2259px 2507px var(--stars-color), 2220px 1586px var(--stars-color),
    666px 1729px var(--stars-color), 1442px 1204px var(--stars-color), 1539px 1343px var(--stars-color),
    1920px 2209px var(--stars-color), 1767px 65px var(--stars-color), 1620px 1234px var(--stars-color), 1499px 2130px var(--stars-color),
    2512px 409px var(--stars-color), 687px 1565px var(--stars-color), 2132px 1981px var(--stars-color), 273px 1569px var(--stars-color),
    1252px 2068px var(--stars-color), 203px 1367px var(--stars-color), 265px 1580px var(--stars-color), 1898px 1340px var(--stars-color),
    2402px 2102px var(--stars-color), 1327px 1097px var(--stars-color), 753px 1954px var(--stars-color), 1819px 110px var(--stars-color),
    1229px 750px var(--stars-color), 980px 264px var(--stars-color), 1964px 2362px var(--stars-color), 1661px 1070px var(--stars-color),
    1073px 1649px var(--stars-color), 2533px 1881px var(--stars-color), 1180px 1362px var(--stars-color),
    1542px 1522px var(--stars-color), 366px 2452px var(--stars-color), 24px 1147px var(--stars-color), 1887px 1905px var(--stars-color),
    2299px 1546px var(--stars-color), 1797px 1603px var(--stars-color), 1793px 318px var(--stars-color), 408px 570px var(--stars-color),
    2536px 231px var(--stars-color), 1576px 1127px var(--stars-color), 1417px 600px var(--stars-color), 1595px 774px var(--stars-color),
    2514px 2354px var(--stars-color), 44px 2324px var(--stars-color), 100px 1409px var(--stars-color), 864px 392px var(--stars-color),
    2290px 537px var(--stars-color), 2158px 2210px var(--stars-color), 1548px 1408px var(--stars-color), 92px 546px var(--stars-color),
    1328px 907px var(--stars-color), 994px 2366px var(--stars-color), 2396px 1761px var(--stars-color), 249px 1072px var(--stars-color),
    2111px 458px var(--stars-color), 1374px 2285px var(--stars-color), 1981px 350px var(--stars-color), 1041px 624px var(--stars-color),
    517px 1698px var(--stars-color), 767px 1070px var(--stars-color), 2124px 2547px var(--stars-color), 2178px 2484px var(--stars-color),
    1428px 70px var(--stars-color), 2283px 1266px var(--stars-color), 1058px 464px var(--stars-color), 429px 1921px var(--stars-color),
    1170px 1159px var(--stars-color), 187px 127px var(--stars-color), 922px 1512px var(--stars-color), 1926px 223px var(--stars-color),
    2556px 913px var(--stars-color), 1014px 829px var(--stars-color), 1921px 1357px var(--stars-color), 734px 253px var(--stars-color),
    1621px 594px var(--stars-color), 2117px 545px var(--stars-color), 41px 23px var(--stars-color), 1021px 1195px var(--stars-color),
    2008px 2423px var(--stars-color), 431px 1951px var(--stars-color), 813px 2467px var(--stars-color), 852px 646px var(--stars-color),
    1648px 643px var(--stars-color), 784px 2441px var(--stars-color), 242px 1977px var(--stars-color), 1978px 1113px var(--stars-color),
    1875px 1261px var(--stars-color), 382px 711px var(--stars-color), 2496px 597px var(--stars-color), 168px 1205px var(--stars-color),
    1675px 1886px var(--stars-color), 871px 996px var(--stars-color), 2295px 1878px var(--stars-color), 1041px 118px var(--stars-color),
    2px 2395px var(--stars-color), 254px 935px var(--stars-color), 2432px 2123px var(--stars-color), 1904px 879px var(--stars-color),
    221px 2332px var(--stars-color), 1975px 493px var(--stars-color), 2263px 186px var(--stars-color), 1850px 1527px var(--stars-color),
    1093px 748px var(--stars-color), 2066px 2390px var(--stars-color), 663px 696px var(--stars-color), 1404px 38px var(--stars-color),
    1127px 478px var(--stars-color), 901px 54px var(--stars-color), 776px 2169px var(--stars-color), 667px 902px var(--stars-color),
    1551px 34px var(--stars-color), 1686px 1533px var(--stars-color), 1441px 1093px var(--stars-color), 777px 327px var(--stars-color),
    1236px 1300px var(--stars-color), 1875px 1799px var(--stars-color), 2024px 115px var(--stars-color), 1942px 233px var(--stars-color),
    1774px 23px var(--stars-color), 1841px 188px var(--stars-color), 2294px 930px var(--stars-color), 1915px 1937px var(--stars-color),
    875px 2145px var(--stars-color), 766px 1101px var(--stars-color), 642px 1418px var(--stars-color), 2365px 1678px var(--stars-color),
    1318px 2401px var(--stars-color), 230px 1639px var(--stars-color), 193px 2476px var(--stars-color), 689px 1423px var(--stars-color),
    2105px 2134px var(--stars-color), 646px 1989px var(--stars-color), 1405px 2344px var(--stars-color), 773px 1803px var(--stars-color),
    668px 159px var(--stars-color), 704px 1703px var(--stars-color), 282px 227px var(--stars-color), 664px 966px var(--stars-color),
    172px 577px var(--stars-color), 200px 2110px var(--stars-color), 1363px 712px var(--stars-color), 903px 22px var(--stars-color),
    1797px 1836px var(--stars-color), 2331px 1751px var(--stars-color), 1459px 141px var(--stars-color), 658px 1535px var(--stars-color),
    1254px 3px var(--stars-color), 1608px 1888px var(--stars-color), 526px 2279px var(--stars-color), 348px 2115px var(--stars-color),
    662px 1221px var(--stars-color), 1592px 662px var(--stars-color), 311px 2192px var(--stars-color), 1110px 812px var(--stars-color),
    429px 1998px var(--stars-color), 1958px 793px var(--stars-color), 1477px 8px var(--stars-color), 1324px 1622px var(--stars-color),
    2488px 175px var(--stars-color), 1998px 2057px var(--stars-color), 2094px 90px var(--stars-color), 572px 406px var(--stars-color),
    1041px 1991px var(--stars-color), 1686px 184px var(--stars-color), 1626px 1581px var(--stars-color), 1421px 431px var(--stars-color),
    514px 2028px var(--stars-color), 1611px 1679px var(--stars-color), 1767px 2068px var(--stars-color),
    2016px 2156px var(--stars-color), 198px 2409px var(--stars-color), 2257px 559px var(--stars-color), 1635px 1601px var(--stars-color),
    613px 1366px var(--stars-color), 1229px 2224px var(--stars-color), 479px 383px var(--stars-color), 413px 803px var(--stars-color),
    10px 1560px var(--stars-color), 1874px 2333px var(--stars-color), 2038px 1399px var(--stars-color), 2284px 1150px var(--stars-color),
    2236px 334px var(--stars-color), 2388px 1027px var(--stars-color), 793px 2338px var(--stars-color), 1432px 1945px var(--stars-color),
    1401px 1204px var(--stars-color), 906px 1092px var(--stars-color), 30px 1710px var(--stars-color), 2268px 1706px var(--stars-color),
    724px 1844px var(--stars-color), 662px 386px var(--stars-color), 2008px 141px var(--stars-color), 1539px 1043px var(--stars-color),
    554px 1457px var(--stars-color), 929px 786px var(--stars-color), 2201px 1291px var(--stars-color), 1104px 2273px var(--stars-color),
    1259px 2222px var(--stars-color), 611px 431px var(--stars-color), 61px 218px var(--stars-color), 953px 1933px var(--stars-color),
    2464px 423px var(--stars-color), 418px 1846px var(--stars-color), 1566px 1278px var(--stars-color), 778px 257px var(--stars-color),
    1195px 685px var(--stars-color), 1554px 742px var(--stars-color), 2300px 2555px var(--stars-color), 1426px 2384px var(--stars-color),
    2173px 1121px var(--stars-color), 587px 1726px var(--stars-color), 361px 378px var(--stars-color), 1440px 930px var(--stars-color),
    334px 1871px var(--stars-color), 678px 1764px var(--stars-color), 2208px 783px var(--stars-color), 1992px 1063px var(--stars-color),
    1783px 2356px var(--stars-color), 1957px 1406px var(--stars-color), 2287px 1708px var(--stars-color),
    404px 1623px var(--stars-color), 19px 1033px var(--stars-color), 315px 722px var(--stars-color), 1264px 497px var(--stars-color),
    1005px 922px var(--stars-color), 203px 2529px var(--stars-color), 1256px 2014px var(--stars-color), 1277px 35px var(--stars-color),
    916px 868px var(--stars-color), 68px 266px var(--stars-color), 1179px 1139px var(--stars-color), 2508px 641px var(--stars-color),
    314px 2195px var(--stars-color), 1811px 854px var(--stars-color), 1063px 1097px var(--stars-color), 970px 1161px var(--stars-color),
    1307px 765px var(--stars-color), 2299px 1132px var(--stars-color), 285px 1183px var(--stars-color), 847px 1727px var(--stars-color),
    1981px 194px var(--stars-color), 1317px 2486px var(--stars-color), 1447px 10px var(--stars-color), 1460px 1350px var(--stars-color),
    583px 1417px var(--stars-color), 1255px 2102px var(--stars-color), 708px 1602px var(--stars-color), 873px 1577px var(--stars-color),
    116px 2413px var(--stars-color), 1591px 166px var(--stars-color), 318px 28px var(--stars-color), 1299px 1226px var(--stars-color),
    2284px 575px var(--stars-color), 1686px 2052px var(--stars-color), 1114px 1451px var(--stars-color), 2239px 953px var(--stars-color),
    347px 1276px var(--stars-color), 352px 2315px var(--stars-color), 602px 1476px var(--stars-color), 1126px 307px var(--stars-color),
    2538px 1184px var(--stars-color), 2351px 2489px var(--stars-color), 2271px 46px var(--stars-color), 852px 1826px var(--stars-color),
    207px 768px var(--stars-color), 2432px 1638px var(--stars-color), 277px 86px var(--stars-color), 2102px 603px var(--stars-color),
    1895px 1741px var(--stars-color), 302px 2251px var(--stars-color), 1473px 2435px var(--stars-color), 896px 950px var(--stars-color),
    1707px 306px var(--stars-color), 556px 2145px var(--stars-color), 1123px 2411px var(--stars-color), 1072px 885px var(--stars-color),
    1082px 391px var(--stars-color), 900px 2437px var(--stars-color), 1970px 178px var(--stars-color), 614px 441px var(--stars-color),
    1250px 2464px var(--stars-color), 452px 1019px var(--stars-color), 2479px 2139px var(--stars-color), 959px 2285px var(--stars-color),
    1808px 1329px var(--stars-color), 1315px 1887px var(--stars-color), 1582px 918px var(--stars-color),
    1286px 1437px var(--stars-color), 772px 626px var(--stars-color), 950px 1198px var(--stars-color), 44px 2079px var(--stars-color),
    74px 710px var(--stars-color), 2422px 302px var(--stars-color), 2258px 1019px var(--stars-color), 374px 1774px var(--stars-color),
    1716px 628px var(--stars-color), 495px 303px var(--stars-color), 2077px 1145px var(--stars-color), 827px 1199px var(--stars-color),
    2409px 269px var(--stars-color), 1634px 546px var(--stars-color), 797px 2008px var(--stars-color), 1020px 213px var(--stars-color),
    587px 591px var(--stars-color), 978px 1674px var(--stars-color), 273px 2500px var(--stars-color), 567px 978px var(--stars-color),
    549px 448px var(--stars-color), 375px 324px var(--stars-color), 588px 1932px var(--stars-color), 1892px 1044px var(--stars-color),
    1712px 1632px var(--stars-color), 1400px 1995px var(--stars-color), 107px 1402px var(--stars-color), 1792px 118px var(--stars-color),
    666px 2143px var(--stars-color), 1604px 2496px var(--stars-color), 1568px 847px var(--stars-color), 1px 1090px var(--stars-color),
    2136px 2545px var(--stars-color), 1422px 636px var(--stars-color), 475px 696px var(--stars-color), 999px 2498px var(--stars-color),
    176px 597px var(--stars-color), 1564px 955px var(--stars-color), 1524px 1492px var(--stars-color), 1060px 501px var(--stars-color),
    209px 1066px var(--stars-color), 774px 2130px var(--stars-color), 2085px 1341px var(--stars-color), 149px 2286px var(--stars-color),
    1612px 1305px var(--stars-color), 806px 1291px var(--stars-color), 1649px 2466px var(--stars-color),
    1320px 2494px var(--stars-color), 792px 1090px var(--stars-color), 1924px 1517px var(--stars-color), 1370px 870px var(--stars-color),
    244px 2105px var(--stars-color), 2422px 1905px var(--stars-color), 1034px 2454px var(--stars-color),
    2084px 1472px var(--stars-color), 1693px 657px var(--stars-color), 1018px 16px var(--stars-color), 1472px 104px var(--stars-color),
    2002px 2273px var(--stars-color), 2039px 1484px var(--stars-color), 215px 1032px var(--stars-color), 572px 1398px var(--stars-color),
    259px 1740px var(--stars-color), 1567px 1689px var(--stars-color), 183px 242px var(--stars-color), 1901px 738px var(--stars-color),
    2246px 1640px var(--stars-color), 372px 2001px var(--stars-color), 2397px 2026px var(--stars-color),
    1609px 1623px var(--stars-color), 61px 1343px var(--stars-color), 1066px 1517px var(--stars-color), 1846px 2443px var(--stars-color),
    53px 103px var(--stars-color), 448px 1400px var(--stars-color), 1095px 654px var(--stars-color), 1943px 1494px var(--stars-color),
    506px 2431px var(--stars-color), 1664px 1885px var(--stars-color), 706px 764px var(--stars-color), 249px 711px var(--stars-color),
    1395px 25px var(--stars-color), 1697px 880px var(--stars-color), 840px 560px var(--stars-color), 124px 11px var(--stars-color),
    638px 659px var(--stars-color), 865px 425px var(--stars-color), 968px 1921px var(--stars-color), 2483px 1421px var(--stars-color),
    1468px 1785px var(--stars-color), 2030px 1111px var(--stars-color), 1565px 1907px var(--stars-color),
    831px 1357px var(--stars-color), 480px 1757px var(--stars-color), 1580px 2191px var(--stars-color), 1852px 2451px var(--stars-color),
    1674px 1508px var(--stars-color), 2211px 2322px var(--stars-color), 1219px 2188px var(--stars-color), 729px 945px var(--stars-color),
    605px 430px var(--stars-color), 1240px 901px var(--stars-color), 949px 655px var(--stars-color), 1349px 1372px var(--stars-color),
    2445px 964px var(--stars-color), 1077px 1533px var(--stars-color), 970px 1977px var(--stars-color), 1252px 184px var(--stars-color),
    307px 358px var(--stars-color), 1027px 2056px var(--stars-color), 1308px 763px var(--stars-color), 793px 1508px var(--stars-color),
    1416px 1335px var(--stars-color), 315px 986px var(--stars-color), 2353px 1821px var(--stars-color), 704px 704px var(--stars-color),
    1753px 2444px var(--stars-color), 798px 1027px var(--stars-color), 2445px 1740px var(--stars-color), 878px 2045px var(--stars-color),
    2066px 674px var(--stars-color), 1926px 2062px var(--stars-color), 81px 896px var(--stars-color), 2053px 618px var(--stars-color),
    537px 2233px var(--stars-color), 634px 644px var(--stars-color), 2194px 71px var(--stars-color), 269px 777px var(--stars-color),
    269px 1036px var(--stars-color), 421px 2298px var(--stars-color), 732px 179px var(--stars-color), 2037px 1405px var(--stars-color),
    1404px 2051px var(--stars-color), 1880px 85px var(--stars-color), 1105px 1268px var(--stars-color), 2062px 1928px var(--stars-color),
    2294px 655px var(--stars-color), 802px 622px var(--stars-color), 1230px 1888px var(--stars-color), 782px 1479px var(--stars-color),
    2327px 491px var(--stars-color), 2552px 1798px var(--stars-color), 2387px 1074px var(--stars-color),
    2329px 2291px var(--stars-color), 2339px 386px var(--stars-color), 1062px 337px var(--stars-color), 301px 885px var(--stars-color),
    1078px 1614px var(--stars-color), 1889px 1595px var(--stars-color), 579px 832px var(--stars-color), 1024px 2445px var(--stars-color),
    2048px 532px var(--stars-color), 2286px 388px var(--stars-color), 1813px 1822px var(--stars-color), 195px 2497px var(--stars-color),
    791px 267px var(--stars-color), 1738px 593px var(--stars-color), 1572px 2488px var(--stars-color), 822px 642px var(--stars-color),
    79px 1758px var(--stars-color), 1742px 317px var(--stars-color), 2148px 493px var(--stars-color), 2360px 1552px var(--stars-color),
    206px 1389px var(--stars-color), 991px 1172px var(--stars-color), 535px 1309px var(--stars-color), 1989px 1799px var(--stars-color),
    2213px 1297px var(--stars-color), 1517px 1384px var(--stars-color), 1765px 1032px var(--stars-color),
    1866px 2210px var(--stars-color), 1476px 608px var(--stars-color), 678px 2154px var(--stars-color), 738px 8px var(--stars-color),
    2149px 398px var(--stars-color), 1971px 1850px var(--stars-color), 217px 1881px var(--stars-color), 889px 808px var(--stars-color),
    680px 1305px var(--stars-color), 1888px 2190px var(--stars-color), 1145px 1439px var(--stars-color), 330px 1934px var(--stars-color),
    677px 13px var(--stars-color), 682px 807px var(--stars-color), 2px 1494px var(--stars-color), 1744px 2224px var(--stars-color),
    849px 148px var(--stars-color), 1604px 1366px var(--stars-color), 1240px 1367px var(--stars-color), 1549px 469px var(--stars-color),
    385px 2508px var(--stars-color), 1496px 804px var(--stars-color), 858px 683px var(--stars-color), 188px 2349px var(--stars-color),
    335px 2407px var(--stars-color), 129px 729px var(--stars-color), 1685px 1643px var(--stars-color), 185px 2221px var(--stars-color),
    1865px 1351px var(--stars-color), 2437px 2464px var(--stars-color), 295px 1387px var(--stars-color),
    1730px 1425px var(--stars-color), 557px 345px var(--stars-color), 2496px 1646px var(--stars-color), 8px 25px var(--stars-color),
    2355px 713px var(--stars-color), 1683px 851px var(--stars-color), 813px 763px var(--stars-color), 411px 698px var(--stars-color),
    1852px 1433px var(--stars-color), 867px 973px var(--stars-color), 1505px 1656px var(--stars-color), 1712px 1129px var(--stars-color),
    2529px 723px var(--stars-color), 2555px 945px var(--stars-color), 526px 1817px var(--stars-color), 801px 429px var(--stars-color),
    106px 100px var(--stars-color), 812px 796px var(--stars-color), 1430px 44px var(--stars-color), 2027px 1267px var(--stars-color),
    1849px 1772px var(--stars-color), 2301px 268px var(--stars-color), 905px 2532px var(--stars-color), 1003px 539px var(--stars-color),
    866px 2284px var(--stars-color), 1379px 1202px var(--stars-color), 509px 1211px var(--stars-color), 567px 269px var(--stars-color),
    617px 2345px var(--stars-color), 405px 1431px var(--stars-color), 61px 341px var(--stars-color), 2173px 2299px var(--stars-color),
    134px 1303px var(--stars-color), 1131px 2517px var(--stars-color), 2279px 46px var(--stars-color), 789px 77px var(--stars-color),
    2399px 561px var(--stars-color), 136px 2350px var(--stars-color), 41px 158px var(--stars-color), 2247px 337px var(--stars-color),
    469px 86px var(--stars-color), 1165px 2184px var(--stars-color), 2107px 817px var(--stars-color), 83px 189px var(--stars-color),
    2345px 36px var(--stars-color), 253px 392px var(--stars-color), 862px 1302px var(--stars-color), 932px 182px var(--stars-color),
    2419px 1837px var(--stars-color), 2409px 1707px var(--stars-color), 1046px 1922px var(--stars-color),
    1011px 666px var(--stars-color), 2030px 352px var(--stars-color), 750px 1942px var(--stars-color), 1410px 986px var(--stars-color),
    1845px 1827px var(--stars-color), 642px 1505px var(--stars-color), 1094px 1031px var(--stars-color), 38px 184px var(--stars-color),
    1706px 2008px var(--stars-color), 1730px 1191px var(--stars-color), 2213px 2255px var(--stars-color),
    1501px 2456px var(--stars-color), 2167px 562px var(--stars-color), 2231px 1764px var(--stars-color), 274px 2327px var(--stars-color),
    1152px 2151px var(--stars-color), 1979px 2017px var(--stars-color), 1647px 1487px var(--stars-color),
    1021px 2371px var(--stars-color), 405px 1587px var(--stars-color), 110px 1387px var(--stars-color), 752px 1310px var(--stars-color),
    1884px 1801px var(--stars-color), 2102px 1709px var(--stars-color), 2276px 1126px var(--stars-color),
    1718px 2516px var(--stars-color), 2215px 1231px var(--stars-color), 1502px 655px var(--stars-color),
    1894px 2492px var(--stars-color), 763px 224px var(--stars-color), 1565px 1334px var(--stars-color), 231px 1647px var(--stars-color),
    592px 1386px var(--stars-color), 1981px 74px var(--stars-color), 415px 1109px var(--stars-color), 1636px 2520px var(--stars-color),
    268px 70px var(--stars-color), 2453px 930px var(--stars-color), 1615px 2124px var(--stars-color), 657px 1843px var(--stars-color),
    2237px 1305px var(--stars-color), 1992px 2092px var(--stars-color), 1757px 1543px var(--stars-color),
    1451px 1198px var(--stars-color), 1359px 1817px var(--stars-color), 1078px 2244px var(--stars-color), 70px 1738px var(--stars-color),
    425px 1343px var(--stars-color), 330px 2235px var(--stars-color), 1249px 2520px var(--stars-color), 2060px 280px var(--stars-color),
    1685px 1485px var(--stars-color), 1193px 472px var(--stars-color), 66px 2309px var(--stars-color), 2237px 567px var(--stars-color),
    613px 1334px var(--stars-color), 2084px 650px var(--stars-color), 109px 905px var(--stars-color), 1635px 1818px var(--stars-color),
    1710px 2335px var(--stars-color), 1764px 850px var(--stars-color), 1606px 1441px var(--stars-color), 2140px 787px var(--stars-color),
    1541px 185px var(--stars-color), 824px 1860px var(--stars-color), 1345px 1907px var(--stars-color), 1770px 2213px var(--stars-color),
    1809px 505px var(--stars-color), 872px 1857px var(--stars-color), 360px 1704px var(--stars-color), 2316px 2004px var(--stars-color),
    453px 1908px var(--stars-color), 209px 512px var(--stars-color), 145px 725px var(--stars-color), 2242px 1702px var(--stars-color),
    131px 2513px var(--stars-color), 752px 1331px var(--stars-color), 1819px 1392px var(--stars-color), 2512px 1773px var(--stars-color),
    2421px 2464px var(--stars-color), 165px 637px var(--stars-color), 316px 816px var(--stars-color), 615px 1413px var(--stars-color),
    2217px 2506px var(--stars-color), 45px 1279px var(--stars-color), 1016px 2433px var(--stars-color), 1440px 2373px var(--stars-color),
    215px 2542px var(--stars-color), 1550px 930px var(--stars-color), 2528px 2546px var(--stars-color), 453px 1104px var(--stars-color),
    1554px 1684px var(--stars-color), 693px 1612px var(--stars-color), 1146px 140px var(--stars-color), 891px 1117px var(--stars-color),
    1005px 1264px var(--stars-color), 1591px 543px var(--stars-color), 735px 457px var(--stars-color), 1088px 417px var(--stars-color),
    192px 1166px var(--stars-color), 331px 625px var(--stars-color), 1864px 574px var(--stars-color), 185px 707px var(--stars-color),
    8px 1429px var(--stars-color), 1322px 2364px var(--stars-color), 842px 1311px var(--stars-color), 384px 633px var(--stars-color),
    1186px 57px var(--stars-color), 509px 2507px var(--stars-color), 1704px 2184px var(--stars-color), 183px 1405px var(--stars-color),
    720px 1878px var(--stars-color), 2350px 2303px var(--stars-color), 77px 1027px var(--stars-color), 1761px 996px var(--stars-color),
    531px 794px var(--stars-color), 221px 932px var(--stars-color), 2116px 2544px var(--stars-color), 141px 506px var(--stars-color),
    628px 164px var(--stars-color), 1271px 2522px var(--stars-color), 1690px 1451px var(--stars-color), 350px 596px var(--stars-color),
    440px 390px var(--stars-color), 754px 148px var(--stars-color), 871px 1726px var(--stars-color), 795px 2109px var(--stars-color),
    854px 1763px var(--stars-color), 699px 218px var(--stars-color), 46px 1102px var(--stars-color), 124px 625px var(--stars-color),
    2553px 1782px var(--stars-color), 1903px 597px var(--stars-color), 181px 2308px var(--stars-color), 2522px 15px var(--stars-color),
    825px 445px var(--stars-color), 593px 1627px var(--stars-color), 525px 868px var(--stars-color), 1212px 845px var(--stars-color),
    874px 1802px var(--stars-color), 494px 2252px var(--stars-color), 813px 808px var(--stars-color), 1892px 1855px var(--stars-color),
    850px 477px var(--stars-color), 1069px 683px var(--stars-color), 1257px 1509px var(--stars-color), 1519px 1718px var(--stars-color),
    1018px 2542px var(--stars-color), 655px 856px var(--stars-color), 5px 935px var(--stars-color), 2395px 315px var(--stars-color),
    1617px 471px var(--stars-color), 722px 560px var(--stars-color), 146px 2154px var(--stars-color), 602px 1697px var(--stars-color),
    233px 222px var(--stars-color), 2328px 803px var(--stars-color), 2015px 1741px var(--stars-color), 1794px 686px var(--stars-color),
    218px 1205px var(--stars-color), 665px 2146px var(--stars-color), 1223px 272px var(--stars-color), 1442px 267px var(--stars-color),
    1509px 1943px var(--stars-color), 1226px 91px var(--stars-color), 1290px 893px var(--stars-color), 2473px 180px var(--stars-color),
    238px 2446px var(--stars-color), 710px 1310px var(--stars-color), 66px 658px var(--stars-color), 1826px 1275px var(--stars-color),
    805px 1914px var(--stars-color), 368px 1085px var(--stars-color), 2059px 298px var(--stars-color), 627px 1746px var(--stars-color),
    972px 1840px var(--stars-color), 1169px 1146px var(--stars-color), 894px 768px var(--stars-color), 1811px 1584px var(--stars-color),
    2303px 279px var(--stars-color), 771px 2107px var(--stars-color), 266px 2421px var(--stars-color), 1540px 97px var(--stars-color),
    1373px 732px var(--stars-color), 756px 1134px var(--stars-color), 2389px 1653px var(--stars-color), 2052px 497px var(--stars-color),
    1717px 2530px var(--stars-color), 2007px 588px var(--stars-color), 304px 2496px var(--stars-color), 2438px 810px var(--stars-color),
    1597px 240px var(--stars-color), 2414px 621px var(--stars-color), 1408px 2308px var(--stars-color), 138px 390px var(--stars-color);
}

.stars1 {
  z-index: 10;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 711px 994px var(--stars-color), 2434px 1166px var(--stars-color), 1277px 688px var(--stars-color),
    86px 2165px var(--stars-color), 1458px 30px var(--stars-color), 1525px 2328px var(--stars-color), 1001px 2358px var(--stars-color),
    2086px 1627px var(--stars-color), 592px 228px var(--stars-color), 1932px 644px var(--stars-color), 2397px 1127px var(--stars-color),
    247px 1717px var(--stars-color), 1029px 284px var(--stars-color), 978px 2182px var(--stars-color), 24px 1494px var(--stars-color),
    2210px 1208px var(--stars-color), 281px 448px var(--stars-color), 1696px 1528px var(--stars-color), 1493px 16px var(--stars-color),
    468px 2439px var(--stars-color), 191px 220px var(--stars-color), 328px 478px var(--stars-color), 183px 1642px var(--stars-color),
    1371px 1199px var(--stars-color), 1810px 2158px var(--stars-color), 1663px 2091px var(--stars-color),
    2284px 2343px var(--stars-color), 1854px 1631px var(--stars-color), 1265px 699px var(--stars-color),
    1178px 1755px var(--stars-color), 1979px 1560px var(--stars-color), 328px 1826px var(--stars-color), 1934px 795px var(--stars-color),
    183px 325px var(--stars-color), 248px 1042px var(--stars-color), 308px 1142px var(--stars-color), 2252px 1900px var(--stars-color),
    374px 1308px var(--stars-color), 1888px 2345px var(--stars-color), 2401px 1854px var(--stars-color),
    1763px 2253px var(--stars-color), 969px 1146px var(--stars-color), 173px 2172px var(--stars-color), 672px 721px var(--stars-color),
    1241px 623px var(--stars-color), 631px 1128px var(--stars-color), 1987px 1198px var(--stars-color), 1310px 1455px var(--stars-color),
    897px 21px var(--stars-color), 2431px 1402px var(--stars-color), 1265px 1542px var(--stars-color), 301px 1892px var(--stars-color),
    365px 1145px var(--stars-color), 1961px 1027px var(--stars-color), 191px 2352px var(--stars-color), 168px 623px var(--stars-color),
    1959px 194px var(--stars-color), 54px 1868px var(--stars-color), 2039px 1295px var(--stars-color), 625px 764px var(--stars-color),
    873px 779px var(--stars-color), 2236px 1969px var(--stars-color), 2088px 1802px var(--stars-color), 2088px 649px var(--stars-color),
    2499px 1868px var(--stars-color), 2431px 1933px var(--stars-color), 2521px 1318px var(--stars-color),
    2025px 1321px var(--stars-color), 691px 924px var(--stars-color), 866px 435px var(--stars-color), 1608px 2517px var(--stars-color),
    1195px 1814px var(--stars-color), 1320px 2151px var(--stars-color), 922px 1045px var(--stars-color),
    1373px 2264px var(--stars-color), 130px 613px var(--stars-color), 1100px 1078px var(--stars-color), 2511px 2116px var(--stars-color),
    857px 369px var(--stars-color), 296px 1801px var(--stars-color), 71px 282px var(--stars-color), 1793px 1426px var(--stars-color),
    1826px 492px var(--stars-color), 123px 2299px var(--stars-color), 814px 1048px var(--stars-color), 1348px 1354px var(--stars-color),
    1427px 597px var(--stars-color), 2290px 1996px var(--stars-color), 2300px 1251px var(--stars-color), 903px 1005px var(--stars-color),
    532px 964px var(--stars-color), 1890px 2207px var(--stars-color), 712px 2215px var(--stars-color), 1736px 584px var(--stars-color),
    1194px 1831px var(--stars-color), 2137px 1551px var(--stars-color), 1965px 1315px var(--stars-color),
    2177px 845px var(--stars-color), 2295px 682px var(--stars-color), 366px 118px var(--stars-color), 1479px 674px var(--stars-color),
    1559px 60px var(--stars-color), 173px 2121px var(--stars-color), 863px 1039px var(--stars-color), 437px 163px var(--stars-color),
    100px 2156px var(--stars-color), 566px 2351px var(--stars-color), 857px 437px var(--stars-color), 1173px 2306px var(--stars-color),
    98px 1923px var(--stars-color), 1084px 335px var(--stars-color), 2054px 1972px var(--stars-color), 444px 1649px var(--stars-color),
    2554px 580px var(--stars-color), 2383px 2162px var(--stars-color), 1111px 919px var(--stars-color), 2261px 1445px var(--stars-color),
    1146px 1524px var(--stars-color), 133px 1311px var(--stars-color), 866px 1780px var(--stars-color), 1530px 251px var(--stars-color),
    1112px 172px var(--stars-color), 1139px 2061px var(--stars-color), 1877px 1195px var(--stars-color), 1470px 339px var(--stars-color),
    950px 582px var(--stars-color), 190px 2098px var(--stars-color), 453px 593px var(--stars-color), 1639px 357px var(--stars-color),
    891px 1125px var(--stars-color), 71px 31px var(--stars-color), 518px 2338px var(--stars-color), 1836px 100px var(--stars-color),
    1043px 1517px var(--stars-color), 1225px 647px var(--stars-color), 573px 1316px var(--stars-color), 829px 45px var(--stars-color),
    862px 70px var(--stars-color), 1221px 1167px var(--stars-color), 174px 1421px var(--stars-color), 591px 2264px var(--stars-color),
    1332px 1561px var(--stars-color), 536px 489px var(--stars-color), 1826px 1935px var(--stars-color), 780px 874px var(--stars-color),
    2056px 1911px var(--stars-color), 17px 446px var(--stars-color), 429px 712px var(--stars-color), 2157px 1527px var(--stars-color),
    1879px 950px var(--stars-color), 2400px 1763px var(--stars-color), 2188px 183px var(--stars-color), 1984px 1181px var(--stars-color),
    1927px 430px var(--stars-color), 1840px 311px var(--stars-color), 1643px 1728px var(--stars-color), 1533px 1927px var(--stars-color),
    1994px 2249px var(--stars-color), 422px 400px var(--stars-color), 1766px 1439px var(--stars-color), 2347px 1151px var(--stars-color),
    1949px 1494px var(--stars-color), 2225px 972px var(--stars-color), 697px 2027px var(--stars-color), 852px 956px var(--stars-color),
    493px 558px var(--stars-color), 2372px 828px var(--stars-color), 373px 1738px var(--stars-color), 616px 1112px var(--stars-color),
    1845px 2497px var(--stars-color), 2318px 2427px var(--stars-color), 2031px 1934px var(--stars-color),
    1027px 2293px var(--stars-color), 151px 879px var(--stars-color), 1855px 802px var(--stars-color), 1237px 1904px var(--stars-color),
    1749px 2174px var(--stars-color), 292px 2537px var(--stars-color), 480px 1732px var(--stars-color), 781px 1906px var(--stars-color),
    976px 6px var(--stars-color), 224px 506px var(--stars-color), 1254px 1766px var(--stars-color), 23px 1171px var(--stars-color),
    312px 282px var(--stars-color), 572px 153px var(--stars-color), 2139px 1911px var(--stars-color), 1078px 2137px var(--stars-color),
    971px 968px var(--stars-color), 1900px 1730px var(--stars-color), 1853px 1166px var(--stars-color), 2263px 663px var(--stars-color),
    2122px 1353px var(--stars-color), 1328px 33px var(--stars-color), 1290px 1567px var(--stars-color), 2175px 1213px var(--stars-color),
    2227px 2507px var(--stars-color), 1527px 710px var(--stars-color), 715px 1913px var(--stars-color), 2013px 1510px var(--stars-color),
    2px 1390px var(--stars-color), 398px 1567px var(--stars-color), 418px 426px var(--stars-color), 136px 962px var(--stars-color),
    1225px 674px var(--stars-color), 1362px 95px var(--stars-color), 2353px 1191px var(--stars-color), 850px 815px var(--stars-color),
    1580px 1913px var(--stars-color), 1812px 2493px var(--stars-color), 227px 407px var(--stars-color), 270px 1499px var(--stars-color),
    1732px 1092px var(--stars-color), 2184px 481px var(--stars-color), 1491px 2326px var(--stars-color), 978px 2094px var(--stars-color),
    754px 1019px var(--stars-color), 1971px 1545px var(--stars-color), 981px 231px var(--stars-color), 9px 1717px var(--stars-color),
    731px 2077px var(--stars-color), 193px 1679px var(--stars-color), 2508px 2179px var(--stars-color), 1115px 426px var(--stars-color),
    407px 1572px var(--stars-color), 1610px 1619px var(--stars-color), 2373px 543px var(--stars-color), 1244px 460px var(--stars-color),
    492px 1542px var(--stars-color), 1268px 1392px var(--stars-color), 580px 1397px var(--stars-color), 2148px 8px var(--stars-color),
    1552px 1459px var(--stars-color), 493px 117px var(--stars-color), 37px 1131px var(--stars-color), 1576px 1532px var(--stars-color),
    1443px 433px var(--stars-color), 3px 343px var(--stars-color), 774px 901px var(--stars-color), 429px 995px var(--stars-color),
    1430px 2505px var(--stars-color), 1322px 106px var(--stars-color), 908px 192px var(--stars-color), 861px 661px var(--stars-color),
    282px 1432px var(--stars-color), 1623px 1753px var(--stars-color), 2123px 990px var(--stars-color), 44px 505px var(--stars-color),
    204px 1125px var(--stars-color), 1120px 2447px var(--stars-color), 422px 2508px var(--stars-color), 686px 276px var(--stars-color),
    1902px 1277px var(--stars-color), 2176px 1731px var(--stars-color), 1723px 585px var(--stars-color),
    2096px 1811px var(--stars-color), 1725px 115px var(--stars-color), 256px 779px var(--stars-color), 2415px 1315px var(--stars-color),
    1206px 890px var(--stars-color), 573px 1427px var(--stars-color), 432px 141px var(--stars-color), 280px 1037px var(--stars-color),
    1580px 1796px var(--stars-color), 411px 716px var(--stars-color), 1961px 1195px var(--stars-color), 2474px 584px var(--stars-color),
    1269px 775px var(--stars-color), 1646px 1715px var(--stars-color), 159px 1881px var(--stars-color), 1917px 1712px var(--stars-color),
    1651px 2358px var(--stars-color), 2039px 2506px var(--stars-color), 382px 869px var(--stars-color), 1542px 147px var(--stars-color),
    856px 1498px var(--stars-color), 1652px 1177px var(--stars-color), 1383px 2022px var(--stars-color), 1543px 312px var(--stars-color),
    1px 948px var(--stars-color), 231px 671px var(--stars-color), 1412px 29px var(--stars-color), 2271px 2010px var(--stars-color),
    1009px 1753px var(--stars-color), 2248px 351px var(--stars-color), 93px 875px var(--stars-color), 1010px 140px var(--stars-color),
    192px 1001px var(--stars-color), 2171px 1761px var(--stars-color), 1328px 1964px var(--stars-color),
    1558px 1213px var(--stars-color), 1314px 577px var(--stars-color), 2235px 2476px var(--stars-color), 990px 1814px var(--stars-color),
    402px 2554px var(--stars-color), 955px 1736px var(--stars-color), 169px 1774px var(--stars-color), 1372px 1007px var(--stars-color),
    2205px 240px var(--stars-color), 1935px 1407px var(--stars-color), 1424px 600px var(--stars-color), 2034px 2034px var(--stars-color),
    1112px 2291px var(--stars-color), 1489px 991px var(--stars-color), 1175px 1241px var(--stars-color), 2330px 188px var(--stars-color),
    2078px 1976px var(--stars-color), 2418px 480px var(--stars-color), 1460px 2144px var(--stars-color), 1981px 413px var(--stars-color),
    2307px 1188px var(--stars-color), 913px 437px var(--stars-color), 888px 2369px var(--stars-color), 237px 932px var(--stars-color),
    2280px 539px var(--stars-color), 1441px 854px var(--stars-color), 1640px 311px var(--stars-color), 1472px 687px var(--stars-color),
    1882px 692px var(--stars-color), 84px 1434px var(--stars-color), 978px 775px var(--stars-color), 326px 970px var(--stars-color),
    1117px 1289px var(--stars-color), 488px 1865px var(--stars-color), 330px 210px var(--stars-color), 1166px 1073px var(--stars-color),
    1783px 464px var(--stars-color), 2061px 1497px var(--stars-color), 1220px 409px var(--stars-color), 300px 1951px var(--stars-color),
    1803px 762px var(--stars-color), 2439px 71px var(--stars-color), 1672px 2113px var(--stars-color), 118px 2500px var(--stars-color),
    2334px 1696px var(--stars-color), 440px 343px var(--stars-color), 2521px 255px var(--stars-color), 1509px 2350px var(--stars-color),
    855px 1033px var(--stars-color), 1762px 2532px var(--stars-color), 1336px 2343px var(--stars-color),
    1951px 1028px var(--stars-color), 821px 2452px var(--stars-color), 60px 1062px var(--stars-color), 1577px 2047px var(--stars-color),
    2434px 822px var(--stars-color), 526px 2156px var(--stars-color), 1370px 843px var(--stars-color), 104px 1104px var(--stars-color),
    2020px 985px var(--stars-color), 2054px 761px var(--stars-color), 2530px 1423px var(--stars-color), 2337px 870px var(--stars-color),
    1003px 662px var(--stars-color), 1093px 1183px var(--stars-color), 39px 368px var(--stars-color), 1739px 569px var(--stars-color),
    1537px 1679px var(--stars-color), 1385px 922px var(--stars-color), 1926px 1767px var(--stars-color),
    2532px 2491px var(--stars-color), 417px 1425px var(--stars-color), 117px 291px var(--stars-color), 824px 2492px var(--stars-color),
    104px 1107px var(--stars-color), 2502px 1851px var(--stars-color), 1891px 2301px var(--stars-color), 544px 2460px var(--stars-color),
    351px 562px var(--stars-color), 731px 1748px var(--stars-color), 1086px 578px var(--stars-color), 1441px 85px var(--stars-color),
    2232px 2296px var(--stars-color), 967px 363px var(--stars-color), 2343px 1383px var(--stars-color), 921px 25px var(--stars-color),
    1237px 1846px var(--stars-color), 989px 1344px var(--stars-color), 2124px 1611px var(--stars-color),
    1407px 1101px var(--stars-color), 543px 849px var(--stars-color), 707px 2321px var(--stars-color), 1091px 2496px var(--stars-color),
    2528px 1831px var(--stars-color), 82px 722px var(--stars-color), 966px 15px var(--stars-color), 2387px 747px var(--stars-color),
    1547px 1988px var(--stars-color), 21px 1914px var(--stars-color), 23px 2270px var(--stars-color), 1376px 845px var(--stars-color),
    415px 46px var(--stars-color), 897px 47px var(--stars-color), 1650px 820px var(--stars-color), 977px 2073px var(--stars-color),
    2337px 1011px var(--stars-color), 322px 1525px var(--stars-color), 2169px 473px var(--stars-color), 1466px 1113px var(--stars-color),
    296px 333px var(--stars-color), 855px 1287px var(--stars-color), 2034px 2432px var(--stars-color), 1966px 1738px var(--stars-color),
    227px 944px var(--stars-color), 68px 583px var(--stars-color), 2058px 1753px var(--stars-color), 1441px 1797px var(--stars-color),
    1636px 2524px var(--stars-color), 415px 1442px var(--stars-color), 2137px 543px var(--stars-color), 683px 1707px var(--stars-color),
    171px 646px var(--stars-color), 695px 204px var(--stars-color), 819px 138px var(--stars-color), 1691px 1849px var(--stars-color),
    973px 1741px var(--stars-color), 2498px 1508px var(--stars-color), 755px 2244px var(--stars-color), 1171px 590px var(--stars-color),
    249px 622px var(--stars-color), 705px 1072px var(--stars-color), 487px 2089px var(--stars-color), 2070px 1180px var(--stars-color),
    1147px 31px var(--stars-color), 1849px 1346px var(--stars-color), 1786px 347px var(--stars-color), 1667px 9px var(--stars-color),
    1110px 2214px var(--stars-color), 1385px 2110px var(--stars-color), 955px 2305px var(--stars-color),
    1038px 2060px var(--stars-color), 1234px 1959px var(--stars-color), 1378px 560px var(--stars-color), 493px 1347px var(--stars-color),
    147px 1518px var(--stars-color), 976px 2321px var(--stars-color), 1437px 1533px var(--stars-color), 1679px 2105px var(--stars-color),
    1139px 1578px var(--stars-color), 2323px 2505px var(--stars-color), 476px 808px var(--stars-color), 292px 984px var(--stars-color),
    216px 745px var(--stars-color), 2260px 1936px var(--stars-color), 1820px 895px var(--stars-color), 1506px 122px var(--stars-color),
    612px 1848px var(--stars-color), 171px 747px var(--stars-color), 34px 1116px var(--stars-color), 48px 2496px var(--stars-color),
    1566px 1481px var(--stars-color), 1172px 2267px var(--stars-color), 1404px 2552px var(--stars-color),
    2542px 950px var(--stars-color), 1189px 2423px var(--stars-color), 2503px 1299px var(--stars-color),
    1758px 1868px var(--stars-color), 2236px 2242px var(--stars-color), 1085px 1554px var(--stars-color), 511px 401px var(--stars-color),
    2419px 1884px var(--stars-color), 1288px 461px var(--stars-color), 2532px 1364px var(--stars-color), 592px 1129px var(--stars-color),
    1112px 269px var(--stars-color), 845px 1841px var(--stars-color), 255px 1418px var(--stars-color), 715px 2438px var(--stars-color),
    1431px 2391px var(--stars-color), 1422px 1556px var(--stars-color), 540px 566px var(--stars-color), 1145px 2397px var(--stars-color),
    1374px 1036px var(--stars-color), 1105px 816px var(--stars-color), 2107px 1989px var(--stars-color),
    1115px 1634px var(--stars-color), 1815px 2492px var(--stars-color), 1078px 463px var(--stars-color),
    1428px 1944px var(--stars-color), 199px 89px var(--stars-color), 1638px 2004px var(--stars-color), 1374px 1299px var(--stars-color),
    1236px 1681px var(--stars-color), 1281px 2153px var(--stars-color), 1061px 1007px var(--stars-color),
    1894px 459px var(--stars-color), 2061px 1608px var(--stars-color), 1923px 1049px var(--stars-color), 336px 2134px var(--stars-color),
    1583px 477px var(--stars-color), 1782px 446px var(--stars-color), 1586px 423px var(--stars-color), 986px 1542px var(--stars-color),
    2551px 363px var(--stars-color), 630px 2269px var(--stars-color), 1724px 591px var(--stars-color), 1454px 1874px var(--stars-color),
    2133px 2028px var(--stars-color), 486px 1849px var(--stars-color), 1519px 2182px var(--stars-color),
    2047px 1462px var(--stars-color), 472px 2026px var(--stars-color), 608px 1524px var(--stars-color), 793px 488px var(--stars-color),
    430px 1817px var(--stars-color), 585px 1189px var(--stars-color), 2454px 1061px var(--stars-color), 708px 280px var(--stars-color),
    2192px 452px var(--stars-color), 1744px 1387px var(--stars-color), 340px 1041px var(--stars-color), 2321px 1285px var(--stars-color),
    1198px 609px var(--stars-color), 2459px 484px var(--stars-color), 1525px 1997px var(--stars-color), 2038px 1235px var(--stars-color),
    689px 388px var(--stars-color), 1806px 601px var(--stars-color), 1137px 887px var(--stars-color), 536px 2240px var(--stars-color),
    126px 1137px var(--stars-color), 1730px 2222px var(--stars-color), 1785px 789px var(--stars-color), 1802px 2553px var(--stars-color),
    1392px 1695px var(--stars-color), 2145px 1388px var(--stars-color), 1375px 102px var(--stars-color),
    2209px 1935px var(--stars-color), 2062px 2075px var(--stars-color), 1462px 1499px var(--stars-color),
    2061px 1112px var(--stars-color), 279px 2354px var(--stars-color), 1189px 268px var(--stars-color), 760px 2538px var(--stars-color),
    1865px 986px var(--stars-color), 774px 2446px var(--stars-color), 1962px 289px var(--stars-color), 1585px 2464px var(--stars-color),
    1482px 199px var(--stars-color), 2523px 1924px var(--stars-color), 2100px 1287px var(--stars-color), 620px 841px var(--stars-color),
    712px 1601px var(--stars-color), 1355px 566px var(--stars-color), 614px 486px var(--stars-color), 1535px 2259px var(--stars-color),
    2488px 1008px var(--stars-color), 1484px 958px var(--stars-color), 1360px 460px var(--stars-color), 2178px 2101px var(--stars-color),
    454px 1690px var(--stars-color), 2005px 1731px var(--stars-color), 1322px 294px var(--stars-color), 2355px 2131px var(--stars-color),
    251px 2455px var(--stars-color), 735px 1084px var(--stars-color), 229px 1490px var(--stars-color), 532px 130px var(--stars-color),
    567px 100px var(--stars-color), 655px 2239px var(--stars-color), 316px 1292px var(--stars-color), 2230px 766px var(--stars-color),
    1084px 854px var(--stars-color), 2051px 1073px var(--stars-color), 2467px 2243px var(--stars-color), 772px 1551px var(--stars-color),
    773px 1804px var(--stars-color), 326px 1103px var(--stars-color), 373px 1622px var(--stars-color), 1996px 2247px var(--stars-color),
    917px 565px var(--stars-color), 2078px 1038px var(--stars-color), 1503px 2100px var(--stars-color), 1319px 897px var(--stars-color),
    376px 555px var(--stars-color), 2075px 1740px var(--stars-color), 1793px 877px var(--stars-color), 354px 1290px var(--stars-color),
    2313px 2549px var(--stars-color), 2519px 2333px var(--stars-color), 601px 1006px var(--stars-color), 847px 809px var(--stars-color),
    1553px 39px var(--stars-color), 596px 342px var(--stars-color), 1388px 1060px var(--stars-color), 586px 1123px var(--stars-color),
    2533px 682px var(--stars-color), 1817px 959px var(--stars-color), 2507px 233px var(--stars-color), 2135px 341px var(--stars-color),
    425px 1747px var(--stars-color), 2416px 2248px var(--stars-color), 520px 940px var(--stars-color), 199px 1799px var(--stars-color),
    1585px 901px var(--stars-color), 1282px 1423px var(--stars-color), 2119px 2009px var(--stars-color),
    1617px 2161px var(--stars-color), 1345px 397px var(--stars-color), 948px 1869px var(--stars-color), 2036px 503px var(--stars-color),
    790px 1767px var(--stars-color), 1961px 1665px var(--stars-color), 1103px 1677px var(--stars-color), 374px 1622px var(--stars-color),
    204px 388px var(--stars-color), 2347px 351px var(--stars-color), 593px 2135px var(--stars-color), 1646px 444px var(--stars-color),
    2527px 1373px var(--stars-color), 694px 724px var(--stars-color), 2096px 2150px var(--stars-color), 867px 748px var(--stars-color),
    166px 1718px var(--stars-color), 1452px 250px var(--stars-color), 2542px 1713px var(--stars-color), 528px 505px var(--stars-color),
    466px 2217px var(--stars-color), 403px 883px var(--stars-color), 424px 684px var(--stars-color), 2074px 374px var(--stars-color),
    1970px 2098px var(--stars-color), 1767px 1931px var(--stars-color), 1819px 1034px var(--stars-color), 1966px 82px var(--stars-color),
    1087px 291px var(--stars-color), 529px 2290px var(--stars-color), 817px 603px var(--stars-color), 1640px 853px var(--stars-color),
    496px 27px var(--stars-color), 434px 1739px var(--stars-color), 24px 1582px var(--stars-color), 76px 1361px var(--stars-color),
    1079px 1967px var(--stars-color), 1478px 1394px var(--stars-color), 2258px 1831px var(--stars-color),
    747px 2555px var(--stars-color), 515px 2079px var(--stars-color), 324px 1398px var(--stars-color), 81px 41px var(--stars-color),
    519px 521px var(--stars-color), 1787px 246px var(--stars-color), 254px 2195px var(--stars-color), 417px 54px var(--stars-color),
    1417px 304px var(--stars-color), 1435px 1046px var(--stars-color), 92px 1862px var(--stars-color), 1526px 869px var(--stars-color),
    2436px 2019px var(--stars-color), 427px 1156px var(--stars-color), 1141px 1015px var(--stars-color),
    1744px 1991px var(--stars-color), 2448px 1082px var(--stars-color), 404px 1459px var(--stars-color),
    1204px 1057px var(--stars-color), 721px 1073px var(--stars-color), 832px 1125px var(--stars-color), 1340px 1363px var(--stars-color),
    1270px 2387px var(--stars-color), 1549px 483px var(--stars-color), 2358px 1100px var(--stars-color),
    2429px 1261px var(--stars-color), 1703px 749px var(--stars-color), 808px 1078px var(--stars-color), 1907px 5px var(--stars-color),
    1381px 2354px var(--stars-color), 1062px 1860px var(--stars-color), 736px 564px var(--stars-color), 2460px 1022px var(--stars-color),
    1135px 2533px var(--stars-color), 2421px 2081px var(--stars-color), 2302px 149px var(--stars-color), 1902px 751px var(--stars-color),
    2185px 1244px var(--stars-color), 1396px 2025px var(--stars-color), 1490px 2351px var(--stars-color),
    1198px 1149px var(--stars-color), 1165px 2050px var(--stars-color), 1866px 2290px var(--stars-color),
    498px 2187px var(--stars-color), 731px 2193px var(--stars-color), 973px 2539px var(--stars-color), 2259px 2447px var(--stars-color),
    213px 2249px var(--stars-color), 2084px 230px var(--stars-color), 2509px 2398px var(--stars-color), 1561px 1298px var(--stars-color),
    2084px 55px var(--stars-color), 614px 1106px var(--stars-color), 194px 2226px var(--stars-color), 1523px 1843px var(--stars-color),
    784px 824px var(--stars-color), 131px 1585px var(--stars-color), 1400px 29px var(--stars-color), 1857px 129px var(--stars-color),
    1339px 609px var(--stars-color), 245px 1084px var(--stars-color), 1638px 671px var(--stars-color), 2102px 1968px var(--stars-color),
    1491px 2545px var(--stars-color);
  animation: animStar 125s linear infinite;
}
.stars1:after {
  content: " ";
  top: -600px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow: 2022px 394px var(--stars-color), 27px 2008px var(--stars-color), 19px 139px var(--stars-color),
    197px 1464px var(--stars-color), 562px 2099px var(--stars-color), 821px 663px var(--stars-color), 51px 2163px var(--stars-color),
    2435px 1599px var(--stars-color), 1660px 2054px var(--stars-color), 1649px 211px var(--stars-color),
    2041px 1247px var(--stars-color), 235px 1429px var(--stars-color), 2485px 276px var(--stars-color), 70px 383px var(--stars-color),
    250px 1578px var(--stars-color), 1935px 1861px var(--stars-color), 2560px 2024px var(--stars-color), 451px 1522px var(--stars-color),
    1056px 2344px var(--stars-color), 2479px 1880px var(--stars-color), 426px 1726px var(--stars-color), 227px 2136px var(--stars-color),
    1221px 1497px var(--stars-color), 421px 1242px var(--stars-color), 2272px 499px var(--stars-color), 1272px 2334px var(--stars-color),
    889px 944px var(--stars-color), 2388px 633px var(--stars-color), 1341px 1755px var(--stars-color), 1252px 486px var(--stars-color),
    1413px 2447px var(--stars-color), 726px 669px var(--stars-color), 13px 1897px var(--stars-color), 1040px 2135px var(--stars-color),
    2542px 2520px var(--stars-color), 998px 1199px var(--stars-color), 1427px 1132px var(--stars-color),
    1135px 2132px var(--stars-color), 825px 2417px var(--stars-color), 1791px 24px var(--stars-color), 1251px 996px var(--stars-color),
    2212px 916px var(--stars-color), 2324px 1200px var(--stars-color), 1552px 1755px var(--stars-color), 1000px 906px var(--stars-color),
    1289px 494px var(--stars-color), 2546px 1874px var(--stars-color), 631px 2341px var(--stars-color), 1516px 1868px var(--stars-color),
    1278px 87px var(--stars-color), 960px 1526px var(--stars-color), 2223px 2136px var(--stars-color), 473px 968px var(--stars-color),
    892px 974px var(--stars-color), 1579px 539px var(--stars-color), 1506px 2210px var(--stars-color), 728px 1105px var(--stars-color),
    1601px 1779px var(--stars-color), 2166px 1773px var(--stars-color), 2350px 2099px var(--stars-color),
    465px 1642px var(--stars-color), 1660px 2113px var(--stars-color), 359px 230px var(--stars-color), 779px 1649px var(--stars-color),
    1596px 1303px var(--stars-color), 2491px 918px var(--stars-color), 488px 1043px var(--stars-color), 1483px 1162px var(--stars-color),
    865px 864px var(--stars-color), 1781px 1320px var(--stars-color), 563px 1533px var(--stars-color), 1748px 1529px var(--stars-color),
    1647px 1164px var(--stars-color), 1252px 24px var(--stars-color), 1742px 961px var(--stars-color), 618px 1354px var(--stars-color),
    1025px 837px var(--stars-color), 876px 2172px var(--stars-color), 1604px 750px var(--stars-color), 1974px 1818px var(--stars-color),
    179px 892px var(--stars-color), 1571px 1211px var(--stars-color), 1449px 1312px var(--stars-color), 2419px 1884px var(--stars-color),
    666px 2216px var(--stars-color), 1796px 248px var(--stars-color), 966px 2228px var(--stars-color), 546px 251px var(--stars-color),
    2527px 647px var(--stars-color), 1163px 744px var(--stars-color), 1596px 759px var(--stars-color), 815px 122px var(--stars-color),
    2381px 584px var(--stars-color), 2048px 928px var(--stars-color), 1453px 1934px var(--stars-color), 344px 2482px var(--stars-color),
    1397px 1350px var(--stars-color), 1388px 2164px var(--stars-color), 2541px 90px var(--stars-color), 193px 1818px var(--stars-color),
    188px 1768px var(--stars-color), 759px 426px var(--stars-color), 1461px 1268px var(--stars-color), 1298px 1564px var(--stars-color),
    2270px 104px var(--stars-color), 1747px 1823px var(--stars-color), 599px 2514px var(--stars-color), 1420px 89px var(--stars-color),
    1765px 1118px var(--stars-color), 794px 215px var(--stars-color), 1443px 1313px var(--stars-color), 1041px 662px var(--stars-color),
    2301px 2472px var(--stars-color), 552px 212px var(--stars-color), 218px 589px var(--stars-color), 1434px 787px var(--stars-color),
    305px 2199px var(--stars-color), 1916px 2519px var(--stars-color), 2227px 1361px var(--stars-color), 964px 993px var(--stars-color),
    2397px 1977px var(--stars-color), 2024px 1894px var(--stars-color), 154px 2233px var(--stars-color),
    2188px 2348px var(--stars-color), 1904px 1391px var(--stars-color), 2247px 961px var(--stars-color),
    1937px 2176px var(--stars-color), 1261px 873px var(--stars-color), 1605px 2265px var(--stars-color), 2551px 933px var(--stars-color),
    1488px 354px var(--stars-color), 1768px 809px var(--stars-color), 1952px 1522px var(--stars-color), 1626px 2219px var(--stars-color),
    1293px 4px var(--stars-color), 1605px 1714px var(--stars-color), 2487px 1111px var(--stars-color), 1658px 2499px var(--stars-color),
    1098px 1044px var(--stars-color), 1097px 2502px var(--stars-color), 2373px 2184px var(--stars-color), 2548px 90px var(--stars-color),
    953px 2105px var(--stars-color), 2000px 50px var(--stars-color), 2312px 91px var(--stars-color), 523px 248px var(--stars-color),
    794px 2116px var(--stars-color), 2507px 2241px var(--stars-color), 917px 1263px var(--stars-color), 1712px 1279px var(--stars-color),
    2376px 486px var(--stars-color), 2314px 598px var(--stars-color), 1954px 1692px var(--stars-color), 2438px 1867px var(--stars-color),
    1463px 122px var(--stars-color), 1693px 2448px var(--stars-color), 365px 2274px var(--stars-color), 2032px 510px var(--stars-color),
    1138px 1138px var(--stars-color), 1535px 2482px var(--stars-color), 2202px 1228px var(--stars-color),
    1111px 2189px var(--stars-color), 2480px 2540px var(--stars-color), 243px 1096px var(--stars-color), 144px 1856px var(--stars-color),
    2467px 319px var(--stars-color), 2358px 1323px var(--stars-color), 1274px 132px var(--stars-color), 1921px 1566px var(--stars-color),
    1726px 1628px var(--stars-color), 627px 2277px var(--stars-color), 407px 841px var(--stars-color), 1160px 1451px var(--stars-color),
    173px 1910px var(--stars-color), 1125px 1449px var(--stars-color), 2445px 2314px var(--stars-color),
    1364px 1315px var(--stars-color), 1032px 2110px var(--stars-color), 1309px 249px var(--stars-color), 139px 1477px var(--stars-color),
    326px 2301px var(--stars-color), 1408px 1212px var(--stars-color), 209px 285px var(--stars-color), 430px 552px var(--stars-color),
    389px 173px var(--stars-color), 998px 1916px var(--stars-color), 2135px 966px var(--stars-color), 269px 471px var(--stars-color),
    2452px 153px var(--stars-color), 340px 2445px var(--stars-color), 1889px 526px var(--stars-color), 1922px 2174px var(--stars-color),
    1109px 2490px var(--stars-color), 550px 41px var(--stars-color), 760px 2187px var(--stars-color), 2221px 971px var(--stars-color),
    913px 1135px var(--stars-color), 2344px 2388px var(--stars-color), 794px 660px var(--stars-color), 772px 553px var(--stars-color),
    1951px 1266px var(--stars-color), 2009px 52px var(--stars-color), 1794px 301px var(--stars-color), 2232px 2316px var(--stars-color),
    254px 290px var(--stars-color), 2450px 132px var(--stars-color), 1190px 2167px var(--stars-color), 1454px 1262px var(--stars-color),
    2337px 1144px var(--stars-color), 2139px 737px var(--stars-color), 2251px 3px var(--stars-color), 2537px 1200px var(--stars-color),
    957px 1497px var(--stars-color), 28px 226px var(--stars-color), 539px 2185px var(--stars-color), 202px 2391px var(--stars-color),
    1600px 1699px var(--stars-color), 2275px 877px var(--stars-color), 1146px 1590px var(--stars-color), 2234px 734px var(--stars-color),
    970px 1067px var(--stars-color), 2331px 2390px var(--stars-color), 63px 1889px var(--stars-color), 1305px 1784px var(--stars-color),
    1517px 2276px var(--stars-color), 1448px 1804px var(--stars-color), 2125px 1352px var(--stars-color),
    1720px 891px var(--stars-color), 1882px 1209px var(--stars-color), 654px 78px var(--stars-color), 1228px 1080px var(--stars-color),
    1138px 1309px var(--stars-color), 378px 2228px var(--stars-color), 120px 496px var(--stars-color), 2442px 444px var(--stars-color),
    1640px 1585px var(--stars-color), 413px 1502px var(--stars-color), 2245px 1655px var(--stars-color),
    2410px 1030px var(--stars-color), 2392px 1747px var(--stars-color), 1166px 881px var(--stars-color),
    1680px 2395px var(--stars-color), 835px 2356px var(--stars-color), 1415px 1433px var(--stars-color), 939px 800px var(--stars-color),
    632px 419px var(--stars-color), 1599px 1859px var(--stars-color), 33px 2436px var(--stars-color), 2303px 2281px var(--stars-color),
    1901px 2373px var(--stars-color), 2522px 713px var(--stars-color), 701px 1387px var(--stars-color), 187px 1731px var(--stars-color),
    2508px 2299px var(--stars-color), 1866px 1731px var(--stars-color), 1939px 2518px var(--stars-color),
    1099px 334px var(--stars-color), 256px 149px var(--stars-color), 2199px 527px var(--stars-color), 588px 776px var(--stars-color),
    2279px 604px var(--stars-color), 438px 1951px var(--stars-color), 1568px 131px var(--stars-color), 1181px 1492px var(--stars-color),
    1391px 1288px var(--stars-color), 1852px 2026px var(--stars-color), 227px 1261px var(--stars-color),
    2384px 1567px var(--stars-color), 510px 2130px var(--stars-color), 1940px 403px var(--stars-color), 1238px 1156px var(--stars-color),
    2246px 310px var(--stars-color), 1959px 44px var(--stars-color), 474px 449px var(--stars-color), 235px 1316px var(--stars-color),
    1761px 1197px var(--stars-color), 420px 2055px var(--stars-color), 89px 2066px var(--stars-color), 1100px 2148px var(--stars-color),
    2368px 1487px var(--stars-color), 199px 1948px var(--stars-color), 540px 1714px var(--stars-color), 1619px 2459px var(--stars-color),
    152px 87px var(--stars-color), 1448px 1445px var(--stars-color), 1656px 2404px var(--stars-color), 2469px 1870px var(--stars-color),
    278px 533px var(--stars-color), 890px 2194px var(--stars-color), 459px 1706px var(--stars-color), 675px 2048px var(--stars-color),
    1080px 1153px var(--stars-color), 160px 1625px var(--stars-color), 322px 772px var(--stars-color), 686px 1952px var(--stars-color),
    2519px 1761px var(--stars-color), 2040px 666px var(--stars-color), 1390px 1713px var(--stars-color), 1440px 838px var(--stars-color),
    797px 72px var(--stars-color), 2493px 2399px var(--stars-color), 1061px 1133px var(--stars-color), 954px 1781px var(--stars-color),
    222px 1134px var(--stars-color), 779px 1838px var(--stars-color), 1798px 2062px var(--stars-color), 190px 2238px var(--stars-color),
    66px 7px var(--stars-color), 177px 200px var(--stars-color), 2053px 765px var(--stars-color), 327px 2100px var(--stars-color),
    1591px 1033px var(--stars-color), 1535px 439px var(--stars-color), 2535px 866px var(--stars-color), 1997px 91px var(--stars-color),
    1003px 458px var(--stars-color), 1096px 559px var(--stars-color), 2169px 839px var(--stars-color), 204px 567px var(--stars-color),
    1064px 2020px var(--stars-color), 357px 2501px var(--stars-color), 2319px 1221px var(--stars-color), 1645px 812px var(--stars-color),
    1305px 2210px var(--stars-color), 1241px 330px var(--stars-color), 440px 1595px var(--stars-color), 2304px 662px var(--stars-color),
    1243px 2535px var(--stars-color), 242px 1473px var(--stars-color), 913px 2401px var(--stars-color), 164px 2160px var(--stars-color),
    2525px 1823px var(--stars-color), 1910px 884px var(--stars-color), 254px 1712px var(--stars-color), 370px 1569px var(--stars-color),
    2323px 1031px var(--stars-color), 882px 1071px var(--stars-color), 1674px 590px var(--stars-color), 1484px 1767px var(--stars-color),
    2196px 548px var(--stars-color), 550px 2055px var(--stars-color), 1737px 835px var(--stars-color), 1462px 2040px var(--stars-color),
    1772px 1810px var(--stars-color), 2321px 80px var(--stars-color), 2176px 1614px var(--stars-color), 1562px 2398px var(--stars-color),
    680px 2219px var(--stars-color), 1972px 53px var(--stars-color), 816px 737px var(--stars-color), 2335px 1959px var(--stars-color),
    436px 739px var(--stars-color), 642px 649px var(--stars-color), 561px 1482px var(--stars-color), 247px 2526px var(--stars-color),
    772px 396px var(--stars-color), 100px 1870px var(--stars-color), 1264px 660px var(--stars-color), 1978px 1389px var(--stars-color),
    1588px 1803px var(--stars-color), 47px 136px var(--stars-color), 2133px 1199px var(--stars-color), 2257px 1267px var(--stars-color),
    473px 1837px var(--stars-color), 2500px 1884px var(--stars-color), 1261px 2032px var(--stars-color), 1165px 867px var(--stars-color),
    929px 2451px var(--stars-color), 1355px 1530px var(--stars-color), 1733px 1656px var(--stars-color), 557px 2331px var(--stars-color),
    1436px 264px var(--stars-color), 278px 2146px var(--stars-color), 2150px 2402px var(--stars-color), 1652px 215px var(--stars-color),
    2532px 738px var(--stars-color), 404px 256px var(--stars-color), 1950px 7px var(--stars-color), 1239px 1041px var(--stars-color),
    789px 1960px var(--stars-color), 2px 1598px var(--stars-color), 1013px 907px var(--stars-color), 1816px 1443px var(--stars-color),
    1345px 1282px var(--stars-color), 736px 1095px var(--stars-color), 1928px 2080px var(--stars-color), 507px 678px var(--stars-color),
    1609px 233px var(--stars-color), 2371px 449px var(--stars-color), 125px 2146px var(--stars-color), 343px 656px var(--stars-color),
    260px 1050px var(--stars-color), 909px 1769px var(--stars-color), 446px 1154px var(--stars-color), 265px 181px var(--stars-color),
    1187px 1494px var(--stars-color), 77px 2100px var(--stars-color), 277px 1130px var(--stars-color), 2346px 1704px var(--stars-color),
    1336px 1581px var(--stars-color), 237px 2055px var(--stars-color), 2353px 353px var(--stars-color), 1609px 513px var(--stars-color),
    2409px 1408px var(--stars-color), 218px 575px var(--stars-color), 2028px 1529px var(--stars-color), 1510px 1680px var(--stars-color),
    1467px 237px var(--stars-color), 1474px 178px var(--stars-color), 456px 1944px var(--stars-color), 703px 1928px var(--stars-color),
    2396px 911px var(--stars-color), 1539px 962px var(--stars-color), 2309px 1582px var(--stars-color), 1964px 347px var(--stars-color),
    2328px 1294px var(--stars-color), 164px 1819px var(--stars-color), 296px 2191px var(--stars-color), 545px 1176px var(--stars-color),
    316px 836px var(--stars-color), 31px 1119px var(--stars-color), 10px 1037px var(--stars-color), 630px 2543px var(--stars-color),
    2303px 2138px var(--stars-color), 2489px 1562px var(--stars-color), 817px 186px var(--stars-color), 1622px 994px var(--stars-color),
    1752px 1916px var(--stars-color), 1759px 890px var(--stars-color), 2358px 1008px var(--stars-color), 1239px 217px var(--stars-color),
    235px 1985px var(--stars-color), 1065px 279px var(--stars-color), 2466px 860px var(--stars-color), 1673px 241px var(--stars-color),
    767px 197px var(--stars-color), 2531px 1168px var(--stars-color), 943px 2494px var(--stars-color), 948px 2427px var(--stars-color),
    2341px 2172px var(--stars-color), 1842px 211px var(--stars-color), 460px 788px var(--stars-color), 1556px 557px var(--stars-color),
    2273px 717px var(--stars-color), 731px 1789px var(--stars-color), 1523px 654px var(--stars-color), 368px 1826px var(--stars-color),
    2098px 483px var(--stars-color), 238px 1320px var(--stars-color), 2080px 1662px var(--stars-color), 421px 1258px var(--stars-color),
    1175px 465px var(--stars-color), 2290px 2064px var(--stars-color), 1109px 2174px var(--stars-color), 838px 2423px var(--stars-color),
    1649px 1274px var(--stars-color), 1266px 2312px var(--stars-color), 1379px 2270px var(--stars-color),
    2217px 201px var(--stars-color), 2186px 2332px var(--stars-color), 680px 887px var(--stars-color), 317px 1994px var(--stars-color),
    713px 1338px var(--stars-color), 1887px 846px var(--stars-color), 2160px 1524px var(--stars-color), 1655px 1131px var(--stars-color),
    649px 2200px var(--stars-color), 983px 591px var(--stars-color), 1796px 1739px var(--stars-color), 424px 444px var(--stars-color),
    615px 2019px var(--stars-color), 352px 766px var(--stars-color), 2381px 1090px var(--stars-color), 2210px 2451px var(--stars-color),
    2018px 775px var(--stars-color), 1420px 62px var(--stars-color), 2410px 1837px var(--stars-color), 2178px 28px var(--stars-color),
    1157px 1702px var(--stars-color), 375px 10px var(--stars-color), 1029px 1154px var(--stars-color), 383px 866px var(--stars-color),
    186px 2052px var(--stars-color), 746px 267px var(--stars-color), 1683px 1604px var(--stars-color), 734px 2038px var(--stars-color),
    2052px 2497px var(--stars-color), 1488px 1302px var(--stars-color), 1130px 2129px var(--stars-color), 749px 842px var(--stars-color),
    1889px 2174px var(--stars-color), 2058px 1353px var(--stars-color), 1931px 178px var(--stars-color), 297px 1049px var(--stars-color),
    2263px 714px var(--stars-color), 171px 1651px var(--stars-color), 1997px 405px var(--stars-color), 1132px 1970px var(--stars-color),
    2558px 230px var(--stars-color), 935px 1911px var(--stars-color), 180px 2245px var(--stars-color), 1765px 1105px var(--stars-color),
    1560px 1689px var(--stars-color), 2007px 1013px var(--stars-color), 840px 1237px var(--stars-color), 11px 949px var(--stars-color),
    1110px 677px var(--stars-color), 330px 100px var(--stars-color), 453px 773px var(--stars-color), 2111px 497px var(--stars-color),
    946px 1612px var(--stars-color), 531px 1183px var(--stars-color), 2103px 1820px var(--stars-color), 199px 632px var(--stars-color),
    2146px 1174px var(--stars-color), 736px 39px var(--stars-color), 2265px 2502px var(--stars-color), 92px 783px var(--stars-color),
    2533px 2049px var(--stars-color), 93px 2013px var(--stars-color), 1664px 2344px var(--stars-color), 2287px 1466px var(--stars-color),
    1913px 2367px var(--stars-color), 1626px 64px var(--stars-color), 1840px 791px var(--stars-color), 1541px 1924px var(--stars-color),
    1081px 1360px var(--stars-color), 582px 758px var(--stars-color), 1197px 2195px var(--stars-color), 2452px 729px var(--stars-color),
    2158px 1130px var(--stars-color), 567px 2543px var(--stars-color), 2061px 55px var(--stars-color), 1653px 2216px var(--stars-color),
    1364px 592px var(--stars-color), 1467px 2182px var(--stars-color), 907px 2076px var(--stars-color), 2026px 2226px var(--stars-color),
    1485px 181px var(--stars-color), 2354px 1381px var(--stars-color), 2444px 740px var(--stars-color), 704px 1112px var(--stars-color),
    2266px 235px var(--stars-color), 1482px 829px var(--stars-color), 1552px 937px var(--stars-color), 963px 617px var(--stars-color),
    263px 2011px var(--stars-color), 187px 1989px var(--stars-color), 1502px 1364px var(--stars-color), 2137px 1042px var(--stars-color),
    249px 654px var(--stars-color), 1723px 1112px var(--stars-color), 873px 1532px var(--stars-color), 1447px 1449px var(--stars-color),
    1786px 476px var(--stars-color), 804px 1737px var(--stars-color), 1404px 1626px var(--stars-color), 143px 2014px var(--stars-color),
    1606px 64px var(--stars-color), 1092px 1413px var(--stars-color), 2024px 1783px var(--stars-color), 1851px 451px var(--stars-color),
    1820px 219px var(--stars-color), 1218px 529px var(--stars-color), 1682px 2241px var(--stars-color), 1099px 286px var(--stars-color),
    1165px 796px var(--stars-color), 1445px 314px var(--stars-color), 1124px 2264px var(--stars-color), 2328px 540px var(--stars-color),
    255px 840px var(--stars-color), 2403px 2270px var(--stars-color), 880px 459px var(--stars-color), 2177px 2455px var(--stars-color),
    1487px 158px var(--stars-color), 2143px 722px var(--stars-color), 55px 1026px var(--stars-color), 2289px 178px var(--stars-color),
    1217px 78px var(--stars-color), 1892px 2039px var(--stars-color), 1259px 1512px var(--stars-color), 2392px 1733px var(--stars-color),
    1739px 1037px var(--stars-color), 503px 294px var(--stars-color), 1104px 1885px var(--stars-color), 631px 1381px var(--stars-color),
    1408px 968px var(--stars-color), 13px 924px var(--stars-color), 1910px 172px var(--stars-color), 954px 2366px var(--stars-color),
    1157px 2306px var(--stars-color), 859px 2168px var(--stars-color), 881px 670px var(--stars-color), 678px 2281px var(--stars-color),
    2535px 694px var(--stars-color), 472px 1361px var(--stars-color), 1749px 1676px var(--stars-color), 1503px 2455px var(--stars-color),
    1408px 2013px var(--stars-color), 1642px 1315px var(--stars-color), 1476px 821px var(--stars-color), 1527px 655px var(--stars-color),
    1154px 2556px var(--stars-color), 1478px 96px var(--stars-color), 895px 1118px var(--stars-color), 1966px 242px var(--stars-color),
    2033px 1314px var(--stars-color), 1666px 21px var(--stars-color), 458px 219px var(--stars-color), 1015px 904px var(--stars-color),
    126px 1140px var(--stars-color), 1322px 2074px var(--stars-color), 1213px 862px var(--stars-color), 200px 666px var(--stars-color),
    673px 401px var(--stars-color), 998px 292px var(--stars-color), 1130px 2275px var(--stars-color), 2284px 972px var(--stars-color),
    313px 1357px var(--stars-color), 18px 1088px var(--stars-color), 1493px 2023px var(--stars-color), 1095px 1462px var(--stars-color),
    282px 712px var(--stars-color), 108px 2132px var(--stars-color), 1262px 658px var(--stars-color), 943px 1322px var(--stars-color),
    475px 1376px var(--stars-color), 469px 174px var(--stars-color), 2441px 169px var(--stars-color), 2246px 918px var(--stars-color),
    955px 444px var(--stars-color), 456px 2355px var(--stars-color), 1710px 2523px var(--stars-color), 1727px 2311px var(--stars-color),
    1517px 431px var(--stars-color), 987px 1381px var(--stars-color), 593px 2099px var(--stars-color), 333px 1274px var(--stars-color),
    435px 1509px var(--stars-color), 517px 2102px var(--stars-color), 1408px 626px var(--stars-color), 2341px 913px var(--stars-color),
    1968px 2364px var(--stars-color), 1149px 2054px var(--stars-color), 2256px 1613px var(--stars-color),
    2215px 362px var(--stars-color), 237px 1979px var(--stars-color), 2047px 1996px var(--stars-color), 1002px 1197px var(--stars-color),
    2065px 2362px var(--stars-color), 2076px 867px var(--stars-color), 2004px 2454px var(--stars-color), 172px 1995px var(--stars-color),
    2502px 1163px var(--stars-color), 978px 1784px var(--stars-color), 1188px 455px var(--stars-color), 1367px 756px var(--stars-color),
    927px 1922px var(--stars-color), 271px 2533px var(--stars-color), 1452px 1719px var(--stars-color), 788px 2165px var(--stars-color),
    1132px 1073px var(--stars-color), 1294px 754px var(--stars-color), 1730px 2373px var(--stars-color), 56px 2032px var(--stars-color),
    958px 555px var(--stars-color), 1908px 1387px var(--stars-color), 926px 2246px var(--stars-color), 2257px 1245px var(--stars-color),
    2384px 840px var(--stars-color), 1960px 1347px var(--stars-color), 1336px 909px var(--stars-color), 878px 290px var(--stars-color),
    318px 188px var(--stars-color), 2429px 1943px var(--stars-color), 2220px 1120px var(--stars-color), 2249px 52px var(--stars-color),
    991px 1125px var(--stars-color), 528px 478px var(--stars-color), 1081px 793px var(--stars-color), 1564px 158px var(--stars-color),
    2009px 1248px var(--stars-color), 745px 558px var(--stars-color), 1344px 1994px var(--stars-color), 284px 2103px var(--stars-color),
    664px 2436px var(--stars-color), 231px 1136px var(--stars-color), 2293px 855px var(--stars-color), 1817px 2366px var(--stars-color),
    232px 837px var(--stars-color), 2533px 2088px var(--stars-color), 1475px 1604px var(--stars-color), 1546px 1286px var(--stars-color),
    430px 1462px var(--stars-color), 1104px 2226px var(--stars-color), 4px 1494px var(--stars-color), 1729px 1037px var(--stars-color),
    2331px 1144px var(--stars-color), 183px 618px var(--stars-color), 1654px 2337px var(--stars-color);
}

.stars2 {
  z-index: 10;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 2058px 468px var(--stars-color), 1694px 1131px var(--stars-color), 2104px 911px var(--stars-color),
    321px 1007px var(--stars-color), 1742px 133px var(--stars-color), 2280px 770px var(--stars-color), 1784px 1956px var(--stars-color),
    1145px 578px var(--stars-color), 922px 2279px var(--stars-color), 2143px 536px var(--stars-color), 2329px 405px var(--stars-color),
    1440px 73px var(--stars-color), 589px 209px var(--stars-color), 1736px 2176px var(--stars-color), 2065px 671px var(--stars-color),
    230px 2228px var(--stars-color), 511px 697px var(--stars-color), 873px 2444px var(--stars-color), 420px 1912px var(--stars-color),
    1921px 2526px var(--stars-color), 409px 1969px var(--stars-color), 1670px 1114px var(--stars-color), 90px 2428px var(--stars-color),
    2372px 858px var(--stars-color), 92px 2465px var(--stars-color), 95px 5px var(--stars-color), 581px 891px var(--stars-color),
    412px 2321px var(--stars-color), 1290px 100px var(--stars-color), 2340px 1839px var(--stars-color), 1428px 932px var(--stars-color),
    1985px 1144px var(--stars-color), 2276px 116px var(--stars-color), 2479px 961px var(--stars-color), 1552px 868px var(--stars-color),
    757px 1467px var(--stars-color), 1209px 1237px var(--stars-color), 1922px 1744px var(--stars-color), 1792px 533px var(--stars-color),
    1567px 57px var(--stars-color), 1210px 1437px var(--stars-color), 1646px 1352px var(--stars-color), 2431px 783px var(--stars-color),
    179px 710px var(--stars-color), 471px 128px var(--stars-color), 1575px 1375px var(--stars-color), 1495px 1618px var(--stars-color),
    2267px 825px var(--stars-color), 860px 2355px var(--stars-color), 1114px 1837px var(--stars-color), 2246px 2513px var(--stars-color),
    2222px 1183px var(--stars-color), 39px 1066px var(--stars-color), 1463px 2129px var(--stars-color), 1617px 1693px var(--stars-color),
    1725px 235px var(--stars-color), 1091px 1584px var(--stars-color), 1850px 1830px var(--stars-color), 497px 2429px var(--stars-color),
    57px 378px var(--stars-color), 345px 2348px var(--stars-color), 69px 71px var(--stars-color), 1973px 2487px var(--stars-color),
    1460px 1936px var(--stars-color), 1820px 149px var(--stars-color), 208px 1988px var(--stars-color), 1690px 528px var(--stars-color),
    1612px 367px var(--stars-color), 2197px 1772px var(--stars-color), 536px 1653px var(--stars-color), 1166px 806px var(--stars-color),
    587px 2345px var(--stars-color), 119px 1699px var(--stars-color), 911px 2334px var(--stars-color), 1606px 72px var(--stars-color),
    2078px 1490px var(--stars-color), 2196px 2244px var(--stars-color), 1127px 816px var(--stars-color), 915px 1063px var(--stars-color),
    1279px 2288px var(--stars-color), 2465px 1555px var(--stars-color), 1607px 1841px var(--stars-color), 77px 1680px var(--stars-color),
    1956px 1167px var(--stars-color), 1845px 102px var(--stars-color), 1817px 709px var(--stars-color), 1227px 1179px var(--stars-color),
    2431px 746px var(--stars-color), 2380px 2283px var(--stars-color), 776px 1694px var(--stars-color), 1429px 314px var(--stars-color),
    2095px 165px var(--stars-color), 2337px 324px var(--stars-color), 1320px 468px var(--stars-color), 1457px 2362px var(--stars-color),
    2101px 2527px var(--stars-color), 396px 979px var(--stars-color), 770px 1544px var(--stars-color), 1590px 1138px var(--stars-color),
    1295px 445px var(--stars-color), 2488px 740px var(--stars-color), 563px 2340px var(--stars-color), 1876px 228px var(--stars-color),
    1888px 2165px var(--stars-color), 1024px 17px var(--stars-color), 1148px 2421px var(--stars-color), 1978px 52px var(--stars-color),
    1179px 1594px var(--stars-color), 2247px 784px var(--stars-color), 1833px 177px var(--stars-color), 155px 140px var(--stars-color),
    483px 979px var(--stars-color), 530px 2048px var(--stars-color), 1323px 1992px var(--stars-color), 1700px 1046px var(--stars-color),
    578px 904px var(--stars-color), 198px 1404px var(--stars-color), 216px 2559px var(--stars-color), 1645px 320px var(--stars-color),
    1284px 1059px var(--stars-color), 1473px 965px var(--stars-color), 114px 1917px var(--stars-color), 2482px 1079px var(--stars-color),
    2071px 177px var(--stars-color), 659px 2028px var(--stars-color), 163px 1193px var(--stars-color), 1164px 1086px var(--stars-color),
    631px 894px var(--stars-color), 498px 2013px var(--stars-color), 337px 120px var(--stars-color), 926px 814px var(--stars-color),
    1481px 2297px var(--stars-color), 222px 751px var(--stars-color), 1563px 1638px var(--stars-color), 2170px 867px var(--stars-color),
    1973px 1131px var(--stars-color), 459px 1559px var(--stars-color), 1978px 2520px var(--stars-color), 882px 435px var(--stars-color),
    497px 1620px var(--stars-color), 1707px 1298px var(--stars-color), 1705px 1312px var(--stars-color),
    1625px 2224px var(--stars-color), 2204px 1040px var(--stars-color), 2237px 1941px var(--stars-color),
    1799px 1409px var(--stars-color), 713px 1955px var(--stars-color), 1864px 1971px var(--stars-color), 883px 707px var(--stars-color),
    970px 1877px var(--stars-color), 515px 830px var(--stars-color), 1126px 1795px var(--stars-color), 102px 2150px var(--stars-color),
    768px 2371px var(--stars-color), 1071px 2029px var(--stars-color), 1386px 1804px var(--stars-color), 412px 2472px var(--stars-color),
    2162px 371px var(--stars-color), 367px 2141px var(--stars-color), 508px 738px var(--stars-color), 2163px 396px var(--stars-color),
    1867px 1605px var(--stars-color), 423px 1569px var(--stars-color), 42px 1595px var(--stars-color), 37px 1624px var(--stars-color),
    745px 640px var(--stars-color), 2471px 369px var(--stars-color), 759px 498px var(--stars-color), 2545px 1388px var(--stars-color),
    220px 2226px var(--stars-color), 20px 368px var(--stars-color), 1723px 730px var(--stars-color), 478px 2478px var(--stars-color),
    2335px 1152px var(--stars-color), 2090px 2315px var(--stars-color), 2450px 205px var(--stars-color),
    1673px 1339px var(--stars-color), 2516px 577px var(--stars-color), 1554px 1441px var(--stars-color), 381px 1625px var(--stars-color),
    1591px 162px var(--stars-color), 1181px 1869px var(--stars-color), 2389px 1736px var(--stars-color), 970px 1435px var(--stars-color),
    171px 28px var(--stars-color), 516px 366px var(--stars-color), 1502px 1070px var(--stars-color), 1416px 237px var(--stars-color),
    12px 1886px var(--stars-color), 245px 195px var(--stars-color), 187px 360px var(--stars-color), 1881px 1396px var(--stars-color),
    2384px 1732px var(--stars-color), 1499px 2320px var(--stars-color), 1359px 1587px var(--stars-color),
    1026px 1008px var(--stars-color), 52px 67px var(--stars-color), 1588px 1188px var(--stars-color), 585px 2057px var(--stars-color),
    2441px 59px var(--stars-color);
  animation: animStar 175s linear infinite;
}
.stars2:after {
  content: " ";
  top: -600px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow: 286px 2187px var(--stars-color), 1332px 705px var(--stars-color), 2243px 1330px var(--stars-color),
    1683px 504px var(--stars-color), 1248px 427px var(--stars-color), 225px 1283px var(--stars-color), 2093px 2503px var(--stars-color),
    2194px 2063px var(--stars-color), 1130px 314px var(--stars-color), 2154px 2409px var(--stars-color), 649px 281px var(--stars-color),
    546px 2171px var(--stars-color), 586px 253px var(--stars-color), 1018px 242px var(--stars-color), 1042px 1578px var(--stars-color),
    1709px 1816px var(--stars-color), 439px 1681px var(--stars-color), 654px 1545px var(--stars-color), 2529px 818px var(--stars-color),
    874px 1097px var(--stars-color), 2446px 1018px var(--stars-color), 898px 1614px var(--stars-color), 501px 1840px var(--stars-color),
    1672px 814px var(--stars-color), 2453px 1418px var(--stars-color), 1635px 2523px var(--stars-color),
    1360px 1222px var(--stars-color), 1136px 289px var(--stars-color), 561px 634px var(--stars-color), 2200px 1634px var(--stars-color),
    1781px 1440px var(--stars-color), 850px 2492px var(--stars-color), 2205px 2476px var(--stars-color), 730px 1960px var(--stars-color),
    686px 2171px var(--stars-color), 1770px 2124px var(--stars-color), 129px 40px var(--stars-color), 322px 83px var(--stars-color),
    609px 976px var(--stars-color), 246px 1598px var(--stars-color), 1316px 2409px var(--stars-color), 1696px 1640px var(--stars-color),
    621px 1691px var(--stars-color), 1622px 1771px var(--stars-color), 1325px 808px var(--stars-color), 480px 62px var(--stars-color),
    30px 505px var(--stars-color), 833px 1232px var(--stars-color), 1958px 1252px var(--stars-color), 1058px 1575px var(--stars-color),
    1637px 2065px var(--stars-color), 2502px 1508px var(--stars-color), 2093px 2138px var(--stars-color),
    468px 1716px var(--stars-color), 1605px 1147px var(--stars-color), 2479px 822px var(--stars-color), 178px 1974px var(--stars-color),
    1997px 2463px var(--stars-color), 1669px 1035px var(--stars-color), 729px 243px var(--stars-color), 326px 2209px var(--stars-color),
    610px 2035px var(--stars-color), 2552px 2281px var(--stars-color), 1727px 2221px var(--stars-color),
    2135px 2471px var(--stars-color), 279px 647px var(--stars-color), 2500px 1785px var(--stars-color), 1288px 2495px var(--stars-color),
    171px 181px var(--stars-color), 2538px 1487px var(--stars-color), 1866px 1073px var(--stars-color), 1169px 193px var(--stars-color),
    786px 505px var(--stars-color), 1161px 1522px var(--stars-color), 1791px 1305px var(--stars-color), 1793px 731px var(--stars-color),
    450px 663px var(--stars-color), 215px 2305px var(--stars-color), 337px 881px var(--stars-color), 113px 1232px var(--stars-color),
    1564px 1955px var(--stars-color), 816px 447px var(--stars-color), 176px 1354px var(--stars-color), 1026px 2256px var(--stars-color),
    340px 1576px var(--stars-color), 641px 1852px var(--stars-color), 1006px 2170px var(--stars-color), 651px 1972px var(--stars-color),
    487px 1359px var(--stars-color), 1604px 2208px var(--stars-color), 1321px 1295px var(--stars-color), 1644px 625px var(--stars-color),
    491px 1229px var(--stars-color), 2534px 368px var(--stars-color), 1339px 372px var(--stars-color), 914px 126px var(--stars-color),
    1332px 2377px var(--stars-color), 1843px 2465px var(--stars-color), 944px 1291px var(--stars-color),
    1140px 2367px var(--stars-color), 2166px 1069px var(--stars-color), 1559px 1563px var(--stars-color), 770px 814px var(--stars-color),
    420px 470px var(--stars-color), 121px 1568px var(--stars-color), 2179px 1912px var(--stars-color), 1795px 626px var(--stars-color),
    347px 97px var(--stars-color), 506px 209px var(--stars-color), 1162px 947px var(--stars-color), 2360px 1085px var(--stars-color),
    2426px 2171px var(--stars-color), 1337px 1966px var(--stars-color), 491px 1144px var(--stars-color),
    1217px 2144px var(--stars-color), 2195px 367px var(--stars-color), 1028px 312px var(--stars-color), 2312px 2026px var(--stars-color),
    2218px 2290px var(--stars-color), 2408px 856px var(--stars-color), 253px 1197px var(--stars-color), 779px 1322px var(--stars-color),
    2098px 1442px var(--stars-color), 1929px 1475px var(--stars-color), 896px 474px var(--stars-color), 1768px 933px var(--stars-color),
    1773px 1348px var(--stars-color), 586px 1169px var(--stars-color), 1558px 2451px var(--stars-color), 428px 2499px var(--stars-color),
    1594px 803px var(--stars-color), 2503px 263px var(--stars-color), 1888px 1385px var(--stars-color), 411px 1801px var(--stars-color),
    296px 948px var(--stars-color), 1590px 2356px var(--stars-color), 1747px 2162px var(--stars-color), 1791px 537px var(--stars-color),
    1374px 1474px var(--stars-color), 1076px 370px var(--stars-color), 574px 1713px var(--stars-color), 1803px 687px var(--stars-color),
    1057px 2431px var(--stars-color), 447px 1092px var(--stars-color), 1409px 1939px var(--stars-color), 808px 666px var(--stars-color),
    568px 2431px var(--stars-color), 2132px 1237px var(--stars-color), 1962px 1982px var(--stars-color),
    2520px 1483px var(--stars-color), 1617px 1813px var(--stars-color), 231px 2328px var(--stars-color),
    2168px 1355px var(--stars-color), 1513px 2177px var(--stars-color), 965px 1745px var(--stars-color),
    1013px 1170px var(--stars-color), 354px 2001px var(--stars-color), 1289px 1033px var(--stars-color), 2441px 317px var(--stars-color),
    1252px 552px var(--stars-color), 2153px 2164px var(--stars-color), 662px 884px var(--stars-color), 676px 1115px var(--stars-color),
    2083px 815px var(--stars-color), 209px 974px var(--stars-color), 2479px 1491px var(--stars-color), 1850px 1397px var(--stars-color),
    1544px 2261px var(--stars-color), 2201px 2348px var(--stars-color), 2023px 1244px var(--stars-color),
    1332px 1410px var(--stars-color), 1961px 1239px var(--stars-color), 1440px 1641px var(--stars-color),
    2282px 2357px var(--stars-color), 876px 666px var(--stars-color), 976px 2130px var(--stars-color), 159px 1526px var(--stars-color),
    2444px 1184px var(--stars-color), 822px 307px var(--stars-color), 8px 2120px var(--stars-color), 1572px 453px var(--stars-color),
    1304px 2207px var(--stars-color), 388px 1874px var(--stars-color), 1551px 638px var(--stars-color), 2019px 354px var(--stars-color),
    2177px 2159px var(--stars-color), 1720px 125px var(--stars-color), 1609px 1519px var(--stars-color),
    1552px 2522px var(--stars-color), 1598px 1009px var(--stars-color), 2317px 1143px var(--stars-color),
    2212px 552px var(--stars-color), 208px 893px var(--stars-color), 1431px 2344px var(--stars-color), 2350px 729px var(--stars-color),
    807px 2392px var(--stars-color), 153px 620px var(--stars-color), 1858px 1397px var(--stars-color), 237px 632px var(--stars-color),
    887px 2058px var(--stars-color);
}

.shooting-stars {
  z-index: 10;
  width: 5px;
  height: 85px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), white);
  animation: animShootingStar 10s linear infinite;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2560px) translateX(-2560px);
  }
}

@keyframes animShootingStar {
  from {
    transform: translateY(0px) translateX(0px) rotate(-45deg);
    opacity: 1;
    height: 5px;
  }
  to {
    transform: translateY(-2560px) translateX(-2560px) rotate(-45deg);
    opacity: 1;
    height: 800px;
  }
}
