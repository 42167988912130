/* MediaModal.css */

.media-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.media-modal-content {
  background-color: var(--card-primary);
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  max-width: 50%;
  max-height: 50%;
}
.image-container img {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.video-container {
  /* position: relative; */
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: 100%;
  /* padding-top: 56.25%; */
}

iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 600px;
  height: 600px;
}

.close-button {
  /* Styles for the close button */
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  z-index: 2000;
}

.close-button i {
  color: var(--secondary);
}

.close-button i:hover {
  color: #ff0000;
}

/* Styling for the Carousel */
.carousel {
  width: 100%;
  height: 100%;
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel img {
  max-width: 100%;
  max-height: 100%;
}

.carousel video {
  max-width: 100%;
  max-height: 100%;
}
