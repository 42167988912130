.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: var(--background);
}

.App.fixed {
  /* position: fixed; */
  /* overflow: hidden;
  height: 100%; */
}

[data-theme="dark"] {
  --primary: #31ddac;
  --secondary: #cad6d9;
  --background: #0a131d;

  --default: #fff;

  --name: #cad6d9;
  --hi-iam: var(--primary);

  --white: #ffffff;

  --primary-text: #ffff;
  --primary-opaque: #31ddac2b;

  --input-color: #1c2937;
  --tab: #273038;

  --card-primary: #2e363e;
  --card-secondary: #555f64;

  --mobile-nav: #021114ee;
  --stars-color: rgb(132, 254, 232);
}

[data-theme="light"] {
  --primary: #00cc92;
  --secondary: #303030;
  --background: #c9ced2;
  --default: black;
  --name: var(--primary);
  --hi-iam: var(--secondary);

  --white: #d9d9d9;
  --primary-text: #000;
  --primary-opaque: #31ddac2b;

  --input-color: #d5dbe0;
  --tab: #dadee0;

  --card-primary: #dfe1e3;
  --card-secondary: #d1d6db;

  --mobile-nav: rgb(156, 151, 151);
  --stars-color: rgb(0, 21, 31);
}

.theme {
  background-color: var(--background);
  /* overflow: hidden; */
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.25rem;
  border-radius: 0.5rem;
  background-color: #1c2935;
}

::-webkit-scrollbar-thumb {
  background-color: #647578;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #779ea5;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12%;
}

.left-social-bar {
  height: 12vh;
  border-left: 2px solid white;
  position: fixed;
  left: 5%;
  bottom: 0;
}

.section_title {
  font-weight: 300;
  font-size: clamp(19px, 4vw, 27px);
  color: var(--primary);
  text-align: left;
  display: flex;
  align-items: center;
}

.section_title::after {
  position: relative;
  content: "";
  display: block;
  width: 200px;
  height: 1px;
  margin-left: 20px;
  background-color: #263341;
}

@media screen and (max-width: 900px) {
  .content {
    padding: 0 8%;
  }
}
