.about_section {
  padding: 100px;
}

.about_data p {
  color: var(--secondary);
  text-align: left;
  padding-left: 5px;
  line-height: 3ch;
  font-size: 19px;
}

.about_data p span {
  color: var(--primary);
}

.about_content {
  display: grid;
  grid-template-columns: 2fr 0.5fr;
  gap: 3rem;
}

.about_img_container img {
  max-width: 100%;
  width: 100%;
  height: auto;
  position: relative;
}

.about_img_container {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
}

.about_img_container::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 200ms;
  border: 1px solid rgb(96, 114, 119);
  top: 20px;
  left: 20px;
  /* z-index: -1; */
}

.about_img_container:hover::before {
  top: 10px;
  left: 10px;
  border: 1px solid var(--primary);
}

/* ============ MEDIA QUERIES ============ */

@media screen and (max-width: 1200px) {
  .about_section {
    padding: 100px 0px;
  }
}

@media screen and (max-width: 600px) {
  .about_content {
    display: flex;
    flex-direction: column;
  }

  .about_image {
    display: flex;
    justify-content: center;
  }
  .about_img_container {
    width: 200px;
  }

  .about_img_container::before {
    top: 15px;
    left: 15px;
  }

  .about_img_container:hover::before {
    top: 7px;
    left: 7px;
  }

  .about_data p {
    font-size: 16px;
  }
}
